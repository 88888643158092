import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS } from "prosto-api-client";
import { RadioCheck, TextLink, useFormContext } from "~components";
import { mobileMedia, thinText } from "~constants";
import { Palette } from "~utils/Palette";

type LockerProps = {
  settings?: ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS;
  disabled?: boolean;
  modalLockerAnchor?: string;
};
export const Locker = ({
  settings,
  disabled,
  modalLockerAnchor,
}: LockerProps) => {
  const { setFieldValue, values, initialValues } = useFormContext<{
    locker: boolean;
  }>();
  if (settings?.EXISTS === false) return null;
  return (
    <Container>
      <TitleContainer>
        <Typography variant="h2">Дополнительно</Typography>
      </TitleContainer>
      <RadioCheck
        disabled={disabled}
        defaultChecked={initialValues.locker}
        onChange={(c) => {
          setFieldValue("locker", c);
        }}
        label={<TextContainer variant="body2">Локер</TextContainer>}
        uncheckSupportingText={
          <Typography variant="body3" typography={thinText} component={"div"}>
            {settings?.RIGHTS && (
              <SupportingTextContainer
                dangerouslySetInnerHTML={{ __html: settings?.RIGHTS }}
              />
            )}
          </Typography>
        }
        checkSupportingText={
          <TextLink
            variant="body3"
            typography={thinText}
            href={modalLockerAnchor}
          >
            Что такое локер?
          </TextLink>
        }
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  border-radius: 12px;

  ${mobileMedia} {
    gap: 10px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  padding: 1px 0;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;

  ${mobileMedia} {
    padding: 7px 0px 6px 0px;
  }
`;

const TextContainer = styled(Typography)`
  display: flex;
  padding: 8px 0 8px 8px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;

  ${mobileMedia} {
    padding: 6px 0 6px 10px;
  }
`;

const SupportingTextContainer = styled.div`
  a {
    word-break: break-word;
    color: ${Palette["B&W/100"]};
    text-decoration: underline !important;
  }
`;
