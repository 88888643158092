export const Palette = {
  'B&W/00': '#000000',
  'B&W/05': '#131415',
  'B&W/10': '#1B1D1E',
  'B&W/20': '#2F3234',
  'B&W/30': '#484A4C',
  'B&W/40': '#5E6061',
  'B&W/50': '#8D8D8D',
  'B&W/60': '#A1A1A1',
  'B&W/90': '#E0E0E0',
  'B&W/95': '#EAEAEA',
  'B&W/100': '#FFFFFF',

  'Error/05': '#1C0100',
  'Error/10': '#370300',
  'Error/30': '#931008',
  'Error/40': '#AF130A',
  'Error/50': '#C81B11',
  'Error/60': '#EB382E',

  darkGrey: '#222',
}