import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { MouseEvent, useState } from "react";
import { OutlinedInputProps } from "@mui/material/OutlinedInput/OutlinedInput";

import { ReactComponent as Visibility } from "~assets/svg/show_password.svg";
import { ReactComponent as VisibilityOff } from "~assets/svg/hide_password.svg";
import { FieldContainer, FieldContainerProps } from "./FieldContainer";

export type PasswordFieldProps = OutlinedInputProps &
  Pick<FieldContainerProps, "label" | "helperText" | "supportingText">;

export const PasswordField = ({
  label,
  helperText,
  supportingText,
  ...rest
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FieldContainer
      label={label}
      helperText={helperText}
      supportingText={supportingText}
    >
      <OutlinedInput
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        {...rest}
      />
    </FieldContainer>
  );
};
