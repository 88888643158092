/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseUserRecord } from './ResponseUserRecord';
import {
    ResponseUserRecordFromJSON,
    ResponseUserRecordFromJSONTyped,
    ResponseUserRecordToJSON,
} from './ResponseUserRecord';

/**
 * Данный объект представляет собой запись в коворкинг.
 * @export
 * @interface ResponseReservationCovorking
 */
export interface ResponseReservationCovorking {
    /**
     * 
     * @type {ResponseUserRecord}
     * @memberof ResponseReservationCovorking
     */
    DATA?: ResponseUserRecord;
    /**
     * Сообщение от API системы (при наличии)
     * @type {string}
     * @memberof ResponseReservationCovorking
     */
    MESSAGE?: string;
    /**
     * Уровень ошибки
     * @type {string}
     * @memberof ResponseReservationCovorking
     */
    TYPE?: string;
    /**
     * Тип ошибки
     * @type {string}
     * @memberof ResponseReservationCovorking
     */
    ERROR_TYPE?: string;
    /**
     * Список ошибок
     * @type {Array<string>}
     * @memberof ResponseReservationCovorking
     */
    ERRORS?: Array<string>;
}

/**
 * Check if a given object implements the ResponseReservationCovorking interface.
 */
export function instanceOfResponseReservationCovorking(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseReservationCovorkingFromJSON(json: any): ResponseReservationCovorking {
    return ResponseReservationCovorkingFromJSONTyped(json, false);
}

export function ResponseReservationCovorkingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseReservationCovorking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DATA': !exists(json, 'DATA') ? undefined : ResponseUserRecordFromJSON(json['DATA']),
        'MESSAGE': !exists(json, 'MESSAGE') ? undefined : json['MESSAGE'],
        'TYPE': !exists(json, 'TYPE') ? undefined : json['TYPE'],
        'ERROR_TYPE': !exists(json, 'ERROR_TYPE') ? undefined : json['ERROR_TYPE'],
        'ERRORS': !exists(json, 'ERRORS') ? undefined : json['ERRORS'],
    };
}

export function ResponseReservationCovorkingToJSON(value?: ResponseReservationCovorking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DATA': ResponseUserRecordToJSON(value.DATA),
        'MESSAGE': value.MESSAGE,
        'TYPE': value.TYPE,
        'ERROR_TYPE': value.ERROR_TYPE,
        'ERRORS': value.ERRORS,
    };
}

