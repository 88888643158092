/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Модель для смены пароля
 * @export
 * @interface ChangePasswordModel
 */
export interface ChangePasswordModel {
    /**
     * Старый пароль
     * @type {string}
     * @memberof ChangePasswordModel
     */
    PASSWORD?: string;
    /**
     * Пароль
     * @type {string}
     * @memberof ChangePasswordModel
     */
    NEW_PASSWORD?: string;
    /**
     * Подтверждение пароля
     * @type {string}
     * @memberof ChangePasswordModel
     */
    NEW_PASSWORD_CONFIRM?: string;
    /**
     * Идентификатор сессии пользователя. Требуется для изменения данных пользователя
     * @type {string}
     * @memberof ChangePasswordModel
     */
    SESSID?: string;
}

/**
 * Check if a given object implements the ChangePasswordModel interface.
 */
export function instanceOfChangePasswordModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangePasswordModelFromJSON(json: any): ChangePasswordModel {
    return ChangePasswordModelFromJSONTyped(json, false);
}

export function ChangePasswordModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePasswordModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PASSWORD': !exists(json, 'PASSWORD') ? undefined : json['PASSWORD'],
        'NEW_PASSWORD': !exists(json, 'NEW_PASSWORD') ? undefined : json['NEW_PASSWORD'],
        'NEW_PASSWORD_CONFIRM': !exists(json, 'NEW_PASSWORD_CONFIRM') ? undefined : json['NEW_PASSWORD_CONFIRM'],
        'SESSID': !exists(json, 'SESSID') ? undefined : json['SESSID'],
    };
}

export function ChangePasswordModelToJSON(value?: ChangePasswordModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PASSWORD': value.PASSWORD,
        'NEW_PASSWORD': value.NEW_PASSWORD,
        'NEW_PASSWORD_CONFIRM': value.NEW_PASSWORD_CONFIRM,
        'SESSID': value.SESSID,
    };
}

