/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Картинка для данного коворкинга
 * @export
 * @interface ResponseCoworkingCalendarDetailPICTURE
 */
export interface ResponseCoworkingCalendarDetailPICTURE {
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCoworkingCalendarDetailPICTURE
     */
    DISPLAY?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseCoworkingCalendarDetailPICTURE
     */
    SRC?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseCoworkingCalendarDetailPICTURE
     */
    WIDTH?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCoworkingCalendarDetailPICTURE
     */
    HEIGHT?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseCoworkingCalendarDetailPICTURE
     */
    ALT?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCoworkingCalendarDetailPICTURE
     */
    TITLE?: string;
}

/**
 * Check if a given object implements the ResponseCoworkingCalendarDetailPICTURE interface.
 */
export function instanceOfResponseCoworkingCalendarDetailPICTURE(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseCoworkingCalendarDetailPICTUREFromJSON(json: any): ResponseCoworkingCalendarDetailPICTURE {
    return ResponseCoworkingCalendarDetailPICTUREFromJSONTyped(json, false);
}

export function ResponseCoworkingCalendarDetailPICTUREFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCoworkingCalendarDetailPICTURE {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DISPLAY': !exists(json, 'DISPLAY') ? undefined : json['DISPLAY'],
        'SRC': !exists(json, 'SRC') ? undefined : json['SRC'],
        'WIDTH': !exists(json, 'WIDTH') ? undefined : json['WIDTH'],
        'HEIGHT': !exists(json, 'HEIGHT') ? undefined : json['HEIGHT'],
        'ALT': !exists(json, 'ALT') ? undefined : json['ALT'],
        'TITLE': !exists(json, 'TITLE') ? undefined : json['TITLE'],
    };
}

export function ResponseCoworkingCalendarDetailPICTUREToJSON(value?: ResponseCoworkingCalendarDetailPICTURE | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DISPLAY': value.DISPLAY,
        'SRC': value.SRC,
        'WIDTH': value.WIDTH,
        'HEIGHT': value.HEIGHT,
        'ALT': value.ALT,
        'TITLE': value.TITLE,
    };
}

