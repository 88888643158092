import styled from "@emotion/styled";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ResponseCoworkingCalendarDetail } from "prosto-api-client";
import { useEffect, useRef, useState } from "react";

import { ReactComponent as ArrowOpen } from "~assets/svg/arrow_down.svg";
import { devices, mobileMedia } from "~constants";
import { Palette } from "~utils/Palette";
import {
  CapacityChart,
  ChartData,
  Time,
  TimePicker,
} from "../../components/datepicker";
import { useFormContext } from "~components";
import { DateService } from "~services/Date";

const HOURS = Array(24)
  .fill(1)
  .map((_, i) => i);

type CoworkingTimePickerProps = {
  name: string;
  selectedDate?: Date;
  regHourLimit?: number;
  disabled?: boolean;
  schedule?: ResponseCoworkingCalendarDetail["SCHEDULE"];
  records?: ResponseCoworkingCalendarDetail["RECORDS"];
};
export const CoworkingTimePicker = ({
  name,
  schedule,
  records,
  selectedDate,
  disabled,
  regHourLimit = 23,
}: CoworkingTimePickerProps) => {
  const [arriveTime, setArriveTime] = useState<number | undefined>();
  const [leaveTime, setLeaveTime] = useState<number | undefined>();
  const containerRef = useRef<HTMLDivElement>(null);

  const { setFieldValue, initialValues } = useFormContext<{
    time: number[];
    date: Date;
  }>();

  useEffect(() => {
    if (disabled) return;
    if (arriveTime && leaveTime) {
      const fRange = getFullRange([arriveTime, leaveTime], schedule);
      setFieldValue(name, fRange);
    }
  }, [arriveTime, leaveTime, disabled]);

  useEffect(() => {
    const startTime = initialValues.time?.[0];
    const endTime =
      initialValues.time?.length === 1
        ? initialValues.time?.[0]
        : initialValues.time?.[initialValues.time?.length - 1];
    setArriveTime(startTime);
    setLeaveTime(endTime);
  }, [initialValues.time]);

  // Сброс при изменении расписания
  useEffect(() => {
    setFieldValue(name, undefined);
  }, [schedule]);

  const { arriveTimes, leaveTimes } = useTimes({
    arriveTime,
    leaveTime,
    schedule,
    regHourLimit,
    selectedDate,
    disabled,
  });

  const capacity =
    schedule &&
    Object.values(schedule).map<ChartData>(({ ID, MEMBER_COUNT }) => ({
      value: records?.[ID!] || 0,
      intervalId: ID!,
      memberCount: MEMBER_COUNT!,
    }));
  const capacityLabels = arriveTimes?.map((t) => t.label.slice(0, 2));
  const lastTime = leaveTimes?.[leaveTimes?.length - 1]?.label.slice(0, 2);
  lastTime && capacityLabels?.push(lastTime);

  const isMobileView = useMediaQuery(devices.mobile);
  const padding = isMobileView ? 12 : 16;
  const containerClientWidth = containerRef.current?.clientWidth;
  return (
    <Container ref={containerRef}>
      <TimePicker
        leftLabel="Приду в"
        rightLabel="Уйду в"
        leftOptions={arriveTimes}
        rightOptions={leaveTimes}
        onLeftSelect={disabled ? undefined : setArriveTime}
        onRightSelect={disabled ? undefined : setLeaveTime}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ArrowOpen />}>
          <Typography variant="label2">Загруженность</Typography>
        </AccordionSummary>
        <StyledAccordionDetails>
          <CapacityChart
            parentWidth={
              containerClientWidth ? containerClientWidth - padding : undefined
            }
            data={capacity}
            label={capacityLabels}
            selectedFrom={arriveTime}
            selectedTo={leaveTime}
            // disabledFrom={104929}
            // disabledTo={104931}
          />
        </StyledAccordionDetails>
      </Accordion>
    </Container>
  );
};

const useTimes = ({
  schedule,
  leaveTime,
  arriveTime,
  regHourLimit,
  selectedDate,
  disabled,
}: {
  disabled?: boolean;
  schedule?: ResponseCoworkingCalendarDetail["SCHEDULE"];
  leaveTime?: number;
  arriveTime?: number;
  regHourLimit: number;
  selectedDate?: Date;
}) => {
  const [arriveTimes, setArriveTimes] = useState<Time[]>();
  const [leaveTimes, setLeaveTimes] = useState<Time[]>();

  useEffect(() => {
    setArriveTimes(fillTimeOptions("arrive", schedule, selectedDate));
    setLeaveTimes(fillTimeOptions("leave", schedule, selectedDate));
  }, [schedule]);

  useEffect(() => {
    if (!arriveTime && !leaveTime) {
      setArriveTimes(fillTimeOptions("arrive", schedule, selectedDate));
      setLeaveTimes(fillTimeOptions("leave", schedule, selectedDate));
    }

    if (arriveTime && !leaveTime) {
      const intrvlStart = schedule?.[arriveTime];
      setArriveTimes((tms) =>
        tms?.map((tm) => ({
          ...tm,
          selected: tm.value === arriveTime,
        }))
      );
      setLeaveTimes((tms) =>
        tms?.map((tm) => ({
          ...tm,
          disabled:
            tm.hour < intrvlStart?.HOUR! + 1 ||
            tm.hour > intrvlStart?.HOUR! + regHourLimit,
          selected: tm.value === leaveTime,
        }))
      );
    }
    if (!arriveTime && leaveTime) {
      const intrvlEnd = schedule?.[leaveTime];

      setArriveTimes((tms) =>
        tms?.map((tm) => ({
          ...tm,
          hidden: DateService.isNowAndEarly(tm.hour, selectedDate),
          disabled:
            tm.hour > intrvlEnd?.HOUR! ||
            tm.hour < intrvlEnd?.HOUR! - regHourLimit + 1,
          selected: tm.value === arriveTime,
        }))
      );
      setLeaveTimes((tms) =>
        tms?.map((tm) => ({
          ...tm,
          selected: tm.value === leaveTime,
        }))
      );
    }

    if (arriveTime && leaveTime) {
      const intrvlStart = schedule?.[arriveTime];
      const intrvlEnd = schedule?.[leaveTime];

      if (disabled) {
        setArriveTimes((tms) =>
          tms?.map((tm) => ({
            ...tm,
            hidden: DateService.isNowAndEarly(tm.hour, selectedDate),
            disabled: tm.value !== arriveTime,
            selected: tm.value === arriveTime,
          }))
        );
        setLeaveTimes((tms) =>
          tms?.map((tm) => ({
            ...tm,
            disabled: tm.value !== leaveTime,
            selected: tm.value === leaveTime,
          }))
        );
      } else {
        setArriveTimes((tms) =>
          tms?.map((tm) => ({
            ...tm,
            hidden: DateService.isNowAndEarly(tm.hour, selectedDate),
            disabled: !DateService.isToday(selectedDate)
              ? tm.hour > intrvlEnd?.HOUR!
              : false || tm.hour < intrvlEnd?.HOUR! - regHourLimit + 1,
            selected: tm.value === arriveTime,
          }))
        );
        setLeaveTimes((tms) =>
          tms?.map((tm) => ({
            ...tm,
            disabled:
              tm.hour < intrvlStart?.HOUR! + 1 ||
              tm.hour > intrvlStart?.HOUR! + regHourLimit,
            selected: tm.value === leaveTime,
          }))
        );
      }
    }
  }, [arriveTime, leaveTime]);

  return { arriveTimes, leaveTimes };
};

const fillTimeOptions = (
  destination: "arrive" | "leave",
  schedule?: ResponseCoworkingCalendarDetail["SCHEDULE"],
  selectedDate?: Date
) => {
  if (!schedule) return;
  const tms = Object.values(schedule).sort((s1, s2) => s1.HOUR! - s2.HOUR!);
  const start = HOURS.findIndex((e) => tms[0].HOUR === e);
  const end = HOURS.findIndex((e) => tms[tms.length - 1].HOUR === e);

  return HOURS.slice(start, end + 1).map<Time>((h) => {
    const intrvl = tms.find((i) => i.HOUR === h);

    if (intrvl) {
      return {
        label: padTime({
          h: destination === "arrive" ? intrvl?.HOUR! : intrvl?.HOUR! + 1,
          m: intrvl?.MINUTE!,
        }),
        hour: destination === "arrive" ? h : h + 1,
        minute: 0,
        value: intrvl?.ID!,
        hidden: intrvl.HOUR
          ? DateService.isNowAndEarly(intrvl.HOUR, selectedDate)
          : false,
        disabled: false,
      };
    }
    return {
      hour: destination === "arrive" ? h : h + 1,
      minute: 0,
      label: padTime({ h, m: 0 }),
      value: -1,
      disabled: true,
    };
  });
};

const padTime = ({ h, m = 0 }: { h: number; m: number }) =>
  `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}`;

const getFullRange = (
  range: number[],
  schedule: ResponseCoworkingCalendarDetail["SCHEDULE"]
) => {
  const scheduleArr = schedule && Object.values(schedule);
  const startIdx = scheduleArr?.findIndex((s) => s.ID === range[0]);
  const endIdx = scheduleArr?.findIndex((s) => s.ID === range[1]);
  return scheduleArr?.slice(startIdx, endIdx! + 1).map((i) => i.ID);
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 25px;
  background: ${Palette["B&W/05"]};

  ${mobileMedia} {
    border-radius: 20px;
  }
`;

const Accordion = styled(MuiAccordion)`
  width: 100%;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  margin-right: -39px;
  margin-bottom: -7px;
  margin-left: -2px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mobileMedia} {
    margin-right: -30px;
    margin-bottom: -16px;
    margin-left: -13px;
    padding: 0 0 9px 9px;
  }
`;
