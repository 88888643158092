import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Duration } from "luxon";
import { useEffect } from "react";
import useCountDown from "react-countdown-hook";

import { Button, FieldInput, Form, Modal, SubmitButton } from "~components";
import { Palette } from "~utils/Palette";
import { InferType, object, string } from "~utils/validation";

export type PhoneChangeModalProps = {
  open: boolean;
  phone?: string;
  onSubmit: (d: FormCodeData) => Promise<void>;
  onResendCode: () => Promise<void>;
};

const waitTime = 60 * 1000;

type FormCodeData = InferType<typeof CodeSchema>;

const CodeSchema = object({
  code: string().required(),
});

export const PhoneConfirmationModal = ({
  open,
  phone,
  onSubmit,
  onResendCode,
}: PhoneChangeModalProps) => {
  const [timeLeft, { start }] = useCountDown(waitTime);
  const formattedTimeLeft = Duration.fromMillis(timeLeft).toFormat("mm:ss");

  const showResetButton = timeLeft === 0;

  const restart = () => start(waitTime);
  useEffect(start, []);

  return (
    <Modal onClose={() => {}} open={open}>
      <StyledForm
        initialValues={{}}
        validationSchema={CodeSchema}
        // @ts-ignore
        onSubmit={onSubmit}
      >
        <Container>
          <Content>
            <Typography variant="h3">Подтверди номер</Typography>
            <Typography variant="body3">
              Мы отправили SMS с кодом, который тебе нужно ввести, на номер{" "}
              {/* @ts-ignore */}
              <PhoneNumberText variant="body3" component="span">
                {phone}
              </PhoneNumberText>
            </Typography>
            <FieldInput name="code" placeholder="000 000" />
          </Content>
          <ButtonsContainer>
            <ContinueButton variant="first">Продолжить</ContinueButton>
            {showResetButton && (
              <ResendCodeButton
                variant="second"
                onClick={async () => {
                  restart();
                  await onResendCode();
                }}
              >
                Отправить код повторно
              </ResendCodeButton>
            )}
          </ButtonsContainer>
          {!showResetButton && (
            <TimeLeftText variant="label3">
              SMS придёт в течение {formattedTimeLeft}
            </TimeLeftText>
          )}
        </Container>
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled(Form)`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const ContinueButton = styled(SubmitButton)`
  width: 100%;
`;

const ResendCodeButton = styled(Button)`
  width: 100%;
`;

const PhoneNumberText = styled(Typography)`
  font-weight: 400;
`;

const TimeLeftText = styled(Typography)`
  color: ${Palette["B&W/40"]};
`;
