/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PlaceMenu } from './PlaceMenu';
import {
    PlaceMenuFromJSON,
    PlaceMenuFromJSONTyped,
    PlaceMenuToJSON,
} from './PlaceMenu';
import type { ResponseUserRecord } from './ResponseUserRecord';
import {
    ResponseUserRecordFromJSON,
    ResponseUserRecordFromJSONTyped,
    ResponseUserRecordToJSON,
} from './ResponseUserRecord';
import type { UserInfoBase } from './UserInfoBase';
import {
    UserInfoBaseFromJSON,
    UserInfoBaseFromJSONTyped,
    UserInfoBaseToJSON,
} from './UserInfoBase';

/**
 * Данный объект представляет данные для личного кабинета посетителя
 * @export
 * @interface ResponseAccount
 */
export interface ResponseAccount {
    /**
     * 
     * @type {UserInfoBase}
     * @memberof ResponseAccount
     */
    USER_INFO?: UserInfoBase;
    /**
     * Масив записей в новоркинг
     * @type {{ [key: string]: ResponseUserRecord; }}
     * @memberof ResponseAccount
     */
    RECORDS_COWORKING?: { [key: string]: ResponseUserRecord; };
    /**
     * Масив записей на мероприятия
     * @type {{ [key: string]: ResponseUserRecord; }}
     * @memberof ResponseAccount
     */
    RECORDS_EVENTS?: { [key: string]: ResponseUserRecord; };
    /**
     * 
     * @type {PlaceMenu}
     * @memberof ResponseAccount
     */
    PLACE_MENU?: PlaceMenu;
    /**
     * Сообщение от API системы (при наличии)
     * @type {string}
     * @memberof ResponseAccount
     */
    MESSAGE?: string;
    /**
     * Уровень ошибки
     * @type {string}
     * @memberof ResponseAccount
     */
    TYPE?: string;
    /**
     * Тип ошибки
     * @type {string}
     * @memberof ResponseAccount
     */
    ERROR_TYPE?: string;
    /**
     * Список ошибок
     * @type {Array<string>}
     * @memberof ResponseAccount
     */
    ERRORS?: Array<string>;
}

/**
 * Check if a given object implements the ResponseAccount interface.
 */
export function instanceOfResponseAccount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseAccountFromJSON(json: any): ResponseAccount {
    return ResponseAccountFromJSONTyped(json, false);
}

export function ResponseAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'USER_INFO': !exists(json, 'USER_INFO') ? undefined : UserInfoBaseFromJSON(json['USER_INFO']),
        'RECORDS_COWORKING': !exists(json, 'RECORDS_COWORKING') ? undefined : (mapValues(json['RECORDS_COWORKING'], ResponseUserRecordFromJSON)),
        'RECORDS_EVENTS': !exists(json, 'RECORDS_EVENTS') ? undefined : (mapValues(json['RECORDS_EVENTS'], ResponseUserRecordFromJSON)),
        'PLACE_MENU': !exists(json, 'PLACE_MENU') ? undefined : PlaceMenuFromJSON(json['PLACE_MENU']),
        'MESSAGE': !exists(json, 'MESSAGE') ? undefined : json['MESSAGE'],
        'TYPE': !exists(json, 'TYPE') ? undefined : json['TYPE'],
        'ERROR_TYPE': !exists(json, 'ERROR_TYPE') ? undefined : json['ERROR_TYPE'],
        'ERRORS': !exists(json, 'ERRORS') ? undefined : json['ERRORS'],
    };
}

export function ResponseAccountToJSON(value?: ResponseAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'USER_INFO': UserInfoBaseToJSON(value.USER_INFO),
        'RECORDS_COWORKING': value.RECORDS_COWORKING === undefined ? undefined : (mapValues(value.RECORDS_COWORKING, ResponseUserRecordToJSON)),
        'RECORDS_EVENTS': value.RECORDS_EVENTS === undefined ? undefined : (mapValues(value.RECORDS_EVENTS, ResponseUserRecordToJSON)),
        'PLACE_MENU': PlaceMenuToJSON(value.PLACE_MENU),
        'MESSAGE': value.MESSAGE,
        'TYPE': value.TYPE,
        'ERROR_TYPE': value.ERROR_TYPE,
        'ERRORS': value.ERRORS,
    };
}

