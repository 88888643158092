/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Модель для регистрации пользователя в системе (заведение нового пользователя)
 * @export
 * @interface UserInfoCreate
 */
export interface UserInfoCreate {
    /**
     * Пароль
     * @type {string}
     * @memberof UserInfoCreate
     */
    PASSWORD?: string;
    /**
     * Подтверждение пароля
     * @type {string}
     * @memberof UserInfoCreate
     */
    CONFIRM_PASSWORD?: string;
    /**
     * Подтверждение согласия с правилами коворкинга
     * @type {boolean}
     * @memberof UserInfoCreate
     */
    CONSENT_RULES?: boolean;
    /**
     * Подтверждение согласия с обработкой персональных данных
     * @type {boolean}
     * @memberof UserInfoCreate
     */
    CONSENT_PERSONALDATA?: boolean;
    /**
     * Ответ по captha
     * @type {string}
     * @memberof UserInfoCreate
     */
    captcha_word?: string;
    /**
     * Идентификатор captha
     * @type {string}
     * @memberof UserInfoCreate
     */
    captcha_sid?: string;
    /**
     * Имя
     * @type {string}
     * @memberof UserInfoCreate
     */
    NAME?: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof UserInfoCreate
     */
    LAST_NAME?: string;
    /**
     * Отчество
     * @type {string}
     * @memberof UserInfoCreate
     */
    SECOND_NAME?: string;
    /**
     * Ссылка на аватарку
     * @type {string}
     * @memberof UserInfoCreate
     */
    URL_PHOTO?: string;
    /**
     * Дата рождения
     * @type {string}
     * @memberof UserInfoCreate
     */
    BIRTHDAY?: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof UserInfoCreate
     */
    PHONE?: string;
    /**
     * Логин
     * @type {string}
     * @memberof UserInfoCreate
     */
    LOGIN?: string;
    /**
     * Почта
     * @type {string}
     * @memberof UserInfoCreate
     */
    EMAIL?: string;
    /**
     * Пол. Возможны два значения F или M
     * @type {string}
     * @memberof UserInfoCreate
     */
    GENDER?: string;
    /**
     * Псевдоним для telegram
     * @type {string}
     * @memberof UserInfoCreate
     */
    TELEGRAM?: string;
    /**
     * Псевдоним для VK
     * @type {string}
     * @memberof UserInfoCreate
     */
    VK?: string;
    /**
     * Указывает, верифицирован ли пользователь. Если пользователь не верифицирован, то необходимо отобразить QRкод из поля QR_VERIFICATION
     * @type {boolean}
     * @memberof UserInfoCreate
     */
    VERIFIED?: boolean;
    /**
     * идентификатор сессии пользователя. Требуется для изменения данных пользователя
     * @type {string}
     * @memberof UserInfoCreate
     */
    SESSID?: string;
}

/**
 * Check if a given object implements the UserInfoCreate interface.
 */
export function instanceOfUserInfoCreate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserInfoCreateFromJSON(json: any): UserInfoCreate {
    return UserInfoCreateFromJSONTyped(json, false);
}

export function UserInfoCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfoCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PASSWORD': !exists(json, 'PASSWORD') ? undefined : json['PASSWORD'],
        'CONFIRM_PASSWORD': !exists(json, 'CONFIRM_PASSWORD') ? undefined : json['CONFIRM_PASSWORD'],
        'CONSENT_RULES': !exists(json, 'CONSENT_RULES') ? undefined : json['CONSENT_RULES'],
        'CONSENT_PERSONALDATA': !exists(json, 'CONSENT_PERSONALDATA') ? undefined : json['CONSENT_PERSONALDATA'],
        'captcha_word': !exists(json, 'captcha_word') ? undefined : json['captcha_word'],
        'captcha_sid': !exists(json, 'captcha_sid') ? undefined : json['captcha_sid'],
        'NAME': !exists(json, 'NAME') ? undefined : json['NAME'],
        'LAST_NAME': !exists(json, 'LAST_NAME') ? undefined : json['LAST_NAME'],
        'SECOND_NAME': !exists(json, 'SECOND_NAME') ? undefined : json['SECOND_NAME'],
        'URL_PHOTO': !exists(json, 'URL_PHOTO') ? undefined : json['URL_PHOTO'],
        'BIRTHDAY': !exists(json, 'BIRTHDAY') ? undefined : json['BIRTHDAY'],
        'PHONE': !exists(json, 'PHONE') ? undefined : json['PHONE'],
        'LOGIN': !exists(json, 'LOGIN') ? undefined : json['LOGIN'],
        'EMAIL': !exists(json, 'EMAIL') ? undefined : json['EMAIL'],
        'GENDER': !exists(json, 'GENDER') ? undefined : json['GENDER'],
        'TELEGRAM': !exists(json, 'TELEGRAM') ? undefined : json['TELEGRAM'],
        'VK': !exists(json, 'VK') ? undefined : json['VK'],
        'VERIFIED': !exists(json, 'VERIFIED') ? undefined : json['VERIFIED'],
        'SESSID': !exists(json, 'SESSID') ? undefined : json['SESSID'],
    };
}

export function UserInfoCreateToJSON(value?: UserInfoCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PASSWORD': value.PASSWORD,
        'CONFIRM_PASSWORD': value.CONFIRM_PASSWORD,
        'CONSENT_RULES': value.CONSENT_RULES,
        'CONSENT_PERSONALDATA': value.CONSENT_PERSONALDATA,
        'captcha_word': value.captcha_word,
        'captcha_sid': value.captcha_sid,
        'NAME': value.NAME,
        'LAST_NAME': value.LAST_NAME,
        'SECOND_NAME': value.SECOND_NAME,
        'URL_PHOTO': value.URL_PHOTO,
        'BIRTHDAY': value.BIRTHDAY,
        'PHONE': value.PHONE,
        'LOGIN': value.LOGIN,
        'EMAIL': value.EMAIL,
        'GENDER': value.GENDER,
        'TELEGRAM': value.TELEGRAM,
        'VK': value.VK,
        'VERIFIED': value.VERIFIED,
        'SESSID': value.SESSID,
    };
}

