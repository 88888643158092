/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { KeyValueItem } from './KeyValueItem';
import {
    KeyValueItemFromJSON,
    KeyValueItemFromJSONTyped,
    KeyValueItemToJSON,
} from './KeyValueItem';

/**
 * Данный объект предоставляет данные для отображения опросника цели посещения коворкинга
 * @export
 * @interface ResponseCoworkingCalendarDetailSURVEYTARGET
 */
export interface ResponseCoworkingCalendarDetailSURVEYTARGET {
    /**
     * Данная переменная указывает, необходимо ли отображать данный опросник
     * @type {boolean}
     * @memberof ResponseCoworkingCalendarDetailSURVEYTARGET
     */
    DISPLAY?: boolean;
    /**
     * Список вопросов опросника
     * @type {{ [key: string]: KeyValueItem; }}
     * @memberof ResponseCoworkingCalendarDetailSURVEYTARGET
     */
    ITEMS?: { [key: string]: KeyValueItem; };
}

/**
 * Check if a given object implements the ResponseCoworkingCalendarDetailSURVEYTARGET interface.
 */
export function instanceOfResponseCoworkingCalendarDetailSURVEYTARGET(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseCoworkingCalendarDetailSURVEYTARGETFromJSON(json: any): ResponseCoworkingCalendarDetailSURVEYTARGET {
    return ResponseCoworkingCalendarDetailSURVEYTARGETFromJSONTyped(json, false);
}

export function ResponseCoworkingCalendarDetailSURVEYTARGETFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCoworkingCalendarDetailSURVEYTARGET {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DISPLAY': !exists(json, 'DISPLAY') ? undefined : json['DISPLAY'],
        'ITEMS': !exists(json, 'ITEMS') ? undefined : (mapValues(json['ITEMS'], KeyValueItemFromJSON)),
    };
}

export function ResponseCoworkingCalendarDetailSURVEYTARGETToJSON(value?: ResponseCoworkingCalendarDetailSURVEYTARGET | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DISPLAY': value.DISPLAY,
        'ITEMS': value.ITEMS === undefined ? undefined : (mapValues(value.ITEMS, KeyValueItemToJSON)),
    };
}

