import { MenuItem, TextFieldProps, css } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { TextField } from "./TextField";
import { ReactComponent as ArrowDropDownIcon } from "~assets/svg/arrow_down.svg";
import { PaperDropdown } from "./PaperDropdown";
import styled from "@emotion/styled";
import { Icon } from "../images/Icon";
import { isUndefined, isArray } from "lodash";
import { SerializedStyles } from "@emotion/react";
import { mobileMedia } from "~constants";

export type SelectInputProps = Omit<TextFieldProps, "select"> & {
  onBlur?: () => void;
  onChange?: (v: Option["value"] | Option["value"][]) => void;
  options: Option[];
  multiple?: boolean;
  defaultValue?: Option["value"] | Option["value"][];
  value?: Option["value"] | Option["value"][];
  customMenuStyles?: SerializedStyles;
};

const getArrayedValue = (v?: Option["value"] | Option["value"][]) =>
  isUndefined(v) ? [] : isArray(v) ? v : [v];

export const SelectInput = ({
  options,
  size = "small",
  value,
  onChange,
  onBlur,
  multiple,
  defaultValue,
  customMenuStyles,
  ...inputProps
}: SelectInputProps) => {
  const [width, setWidth] = useState(300);
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const [innerValue, setInnerValue] = useState<
    Option["value"] | Option["value"][] | undefined
  >("");
  useEffect(() => {
    if (defaultValue) {
      setInnerValue(multiple ? getArrayedValue(defaultValue) : defaultValue);
    }
  }, [defaultValue]);

  const ref = useRef<{ node: HTMLInputElement }>(null);
  useEffect(() => {
    if (ref.current?.node) {
      setWidth(ref.current.node.offsetWidth);
    }
  }, [ref.current]);

  const getCalculatedValue = () =>
    isUndefined(value) ? innerValue : multiple ? getArrayedValue(value) : value;

  return (
    <TextStyled
      inputRef={ref}
      size={size}
      {...inputProps}
      select
      focused={open}
      value={getCalculatedValue()}
      SelectProps={{
        multiple,
        IconComponent: () => (
          <RightIcon
            Component={ArrowDropDownIcon}
            onClick={() => setOpen(!open)}
          />
        ),
        MenuProps: {
          slots: { paper: PaperDropdown },
          slotProps: {
            paper: {
              // @ts-ignore Кастомный проп https://github.com/mui/mui-x/issues/9775
              options,
              value: getCalculatedValue(),
              onValueChange: (v: Option["value"]) => {
                if (multiple && isArray(innerValue)) {
                  if (innerValue?.includes(v)) {
                    setInnerValue(innerValue?.filter((iv) => iv !== v));
                    onChange?.(innerValue?.filter((iv) => iv !== v));
                  } else {
                    setInnerValue([...innerValue, v]);
                    onChange?.([...innerValue, v]);
                  }
                } else {
                  v && setInnerValue(v);
                  v && onChange?.(v);
                }
                close();
              },
              customStyles: getMenuStyles(width, customMenuStyles),
            },
          },
        },
        open: open,
        onClose: close,
        onOpen: () => setOpen(true),
      }}
    >
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextStyled>
  );
};

const TextStyled = styled(TextField)`
  .MuiSelect-select {
    display: flex;
    align-items: center;
  }
`;

const getMenuStyles = (width: number, customStyles?: SerializedStyles) => css`
  min-width: ${width}px;
  height: auto;
  display: flex;
  flex: 1 0 0;
  align-self: stretch;

  & ul {
    width: 100%;
  }

  ${customStyles}
`;

const RightIcon = styled(Icon)`
  padding-right: 10px;
  ${mobileMedia} {
    padding-right: 5px;
  }
`;
