/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Список оборудования для бронирования в коворкинге
 * @export
 * @interface EquipmentItem
 */
export interface EquipmentItem {
    /**
     * Идентификатор оборудования
     * @type {number}
     * @memberof EquipmentItem
     */
    ID?: number;
    /**
     * Название оборудования
     * @type {string}
     * @memberof EquipmentItem
     */
    NAME?: string;
    /**
     * Идентификатор адреса размещения оборудования
     * @type {number}
     * @memberof EquipmentItem
     */
    EQUIPMENT_ADDRESS?: number;
    /**
     * Множественное бронирование если true и штучное если false
     * @type {boolean}
     * @memberof EquipmentItem
     */
    IS_MULTIPLE?: boolean;
    /**
     * Номер оборудования
     * @type {string}
     * @memberof EquipmentItem
     */
    EQUIPMENT_NUMBER?: string;
    /**
     * Инвентаризационный номер
     * @type {string}
     * @memberof EquipmentItem
     */
    INVENTORY_NUMBER?: string;
}

/**
 * Check if a given object implements the EquipmentItem interface.
 */
export function instanceOfEquipmentItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EquipmentItemFromJSON(json: any): EquipmentItem {
    return EquipmentItemFromJSONTyped(json, false);
}

export function EquipmentItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ID': !exists(json, 'ID') ? undefined : json['ID'],
        'NAME': !exists(json, 'NAME') ? undefined : json['NAME'],
        'EQUIPMENT_ADDRESS': !exists(json, 'EQUIPMENT_ADDRESS') ? undefined : json['EQUIPMENT_ADDRESS'],
        'IS_MULTIPLE': !exists(json, 'IS_MULTIPLE') ? undefined : json['IS_MULTIPLE'],
        'EQUIPMENT_NUMBER': !exists(json, 'EQUIPMENT_NUMBER') ? undefined : json['EQUIPMENT_NUMBER'],
        'INVENTORY_NUMBER': !exists(json, 'INVENTORY_NUMBER') ? undefined : json['INVENTORY_NUMBER'],
    };
}

export function EquipmentItemToJSON(value?: EquipmentItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ID': value.ID,
        'NAME': value.NAME,
        'EQUIPMENT_ADDRESS': value.EQUIPMENT_ADDRESS,
        'IS_MULTIPLE': value.IS_MULTIPLE,
        'EQUIPMENT_NUMBER': value.EQUIPMENT_NUMBER,
        'INVENTORY_NUMBER': value.INVENTORY_NUMBER,
    };
}

