/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResponseCoworkingCalendarDetailSCHEDULEValue
 */
export interface ResponseCoworkingCalendarDetailSCHEDULEValue {
    /**
     * Идентификатор записи (раскисание на конкретный час)
     * @type {number}
     * @memberof ResponseCoworkingCalendarDetailSCHEDULEValue
     */
    ID?: number;
    /**
     * Идентификатор записи (дня и места проведения) коворкинга.
     * @type {number}
     * @memberof ResponseCoworkingCalendarDetailSCHEDULEValue
     */
    EVENT_ID?: number;
    /**
     * На какой час данное расписание
     * @type {number}
     * @memberof ResponseCoworkingCalendarDetailSCHEDULEValue
     */
    HOUR?: number;
    /**
     * На какую минуту данное расписание
     * @type {number}
     * @memberof ResponseCoworkingCalendarDetailSCHEDULEValue
     */
    MINUTE?: number;
    /**
     * Максимальное количество мест на данный час
     * @type {number}
     * @memberof ResponseCoworkingCalendarDetailSCHEDULEValue
     */
    MEMBER_COUNT?: number;
}

/**
 * Check if a given object implements the ResponseCoworkingCalendarDetailSCHEDULEValue interface.
 */
export function instanceOfResponseCoworkingCalendarDetailSCHEDULEValue(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseCoworkingCalendarDetailSCHEDULEValueFromJSON(json: any): ResponseCoworkingCalendarDetailSCHEDULEValue {
    return ResponseCoworkingCalendarDetailSCHEDULEValueFromJSONTyped(json, false);
}

export function ResponseCoworkingCalendarDetailSCHEDULEValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCoworkingCalendarDetailSCHEDULEValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ID': !exists(json, 'ID') ? undefined : json['ID'],
        'EVENT_ID': !exists(json, 'EVENT_ID') ? undefined : json['EVENT_ID'],
        'HOUR': !exists(json, 'HOUR') ? undefined : json['HOUR'],
        'MINUTE': !exists(json, 'MINUTE') ? undefined : json['MINUTE'],
        'MEMBER_COUNT': !exists(json, 'MEMBER_COUNT') ? undefined : json['MEMBER_COUNT'],
    };
}

export function ResponseCoworkingCalendarDetailSCHEDULEValueToJSON(value?: ResponseCoworkingCalendarDetailSCHEDULEValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ID': value.ID,
        'EVENT_ID': value.EVENT_ID,
        'HOUR': value.HOUR,
        'MINUTE': value.MINUTE,
        'MEMBER_COUNT': value.MEMBER_COUNT,
    };
}

