export const months = [
  "Янв",
  "Фев",
  "Мар",
  "Апр",
  "Май",
  "Июн",
  "Июл",
  "Авг",
  "Сен",
  "Окт",
  "Ноя",
  "Дек",
];

export const weekdays = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];

export const monthsItems = months.map((monthLabel, i) => ({
  label: monthLabel,
  value: i,
}));

const today = new Date();
export const getYearOptions = (minYear: number, maxYear: number) => {
  const countElements = (maxYear || today.getFullYear()) - minYear;
  return Array(countElements + 1)
    .fill(0)
    .map((el, index) => ({
      label: (minYear + index).toString(),
      value: (minYear + index).toString(),
    }))
    .reverse();
};
