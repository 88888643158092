import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import * as React from "react";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import { ButtonIcon, Modal, QRCode } from "~components";

type QRLockerModalProps = {
  open: boolean;
  qrContent?: string;
  onClose: () => void;
};

export const QRLockerModal = ({
  qrContent,
  open,
  onClose,
}: QRLockerModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <QRCodeAndButtonsBlock>
          <QRCode content={qrContent} />
          <ButtonsBlock>
            <ButtonIcon Icon={Cross} onClick={onClose} />
          </ButtonsBlock>
        </QRCodeAndButtonsBlock>
        <Typography variant="h2">
          Отсканируй этот QR код, чтобы открыть локер
        </Typography>
      </Container>
    </Modal>
  );
};

const QRCodeAndButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
`;
