import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { FC, ReactNode } from "react";

import { ReactComponent as OutlineError } from "~assets/svg/outline_error.svg";
import { Palette } from "~utils/Palette";
import { ButtonIcon } from "./images";

export const InfoField: FC<{ children?: ReactNode }> = ({ children }) => (
  <Container>
    <ButtonIcon Icon={OutlineError} />
    <TextContainer>
      <Text variant="body3">{children}</Text>
    </TextContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  background: ${Palette["B&W/100"]};
`;

const TextContainer = styled.div`
  display: flex;
  padding: 8px 10px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
`;

const Text = styled(Typography)`
  color: ${Palette["B&W/00"]};
`;
