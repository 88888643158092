import styled from "@emotion/styled";
import { Link } from "@mui/material";
import { LinkProps } from "@mui/material/Link/Link";
import { Palette } from "~utils/Palette";

type TextLinkProps = LinkProps & {
  children: string;
  href?: string;
};

export const TextLink = ({
  children,
  href,
  underline = "always",
  ...rest
}: TextLinkProps) => {
  return (
    <StyledLink {...rest} href={href} underline={underline}>
      {children}
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration-color: ${Palette["B&W/100"]};
  text-decoration: underline!important;
`;
