import React from "react";
import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from "@mui/material";

import { ReactComponent as ArrowOpen } from "~assets/svg/arrow_down.svg";
import { mobileMedia } from "~constants";
import { EquipmentItem } from "prosto-api-client";

type EquipmentInfoProps = {
  equipment: EquipmentItem[];
  onClickEquipment: (item: EquipmentItem) => void;
};

export const EquipmentInfo = ({
  equipment,
  onClickEquipment,
}: EquipmentInfoProps) => {
  return (
    <AccordionContainer>
      <AccordionSummary expandIcon={<ArrowOpen />}>
        <Typography variant="label2">Оборудование на точке</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <EquipmentChipsContainer>
          {equipment.map((itm) => (
            <Chip
              label={itm.NAME}
              variant="cloud"
              key={itm.ID}
              onClick={() => {
                onClickEquipment(itm);
              }}
            />
          ))}
        </EquipmentChipsContainer>
        <Typography variant="label5">
          Наличие оборудования не гарантируется
        </Typography>
      </AccordionDetails>
    </AccordionContainer>
  );
};

const AccordionContainer = styled(Accordion)`
  padding: 7px;

  ${mobileMedia} {
    padding: 3px;
  }
`;

const EquipmentChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;
