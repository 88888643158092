/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Объект представления спикера
 * @export
 * @interface SpeakerModel
 */
export interface SpeakerModel {
    /**
     * Идентификатор спикера
     * @type {number}
     * @memberof SpeakerModel
     */
    ID?: number;
    /**
     * Имя спикера
     * @type {string}
     * @memberof SpeakerModel
     */
    NAME?: string;
    /**
     * Регалии спикера
     * @type {string}
     * @memberof SpeakerModel
     */
    REGALIA?: string;
    /**
     * URL фото спикера
     * @type {string}
     * @memberof SpeakerModel
     */
    FILE?: string;
}

/**
 * Check if a given object implements the SpeakerModel interface.
 */
export function instanceOfSpeakerModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SpeakerModelFromJSON(json: any): SpeakerModel {
    return SpeakerModelFromJSONTyped(json, false);
}

export function SpeakerModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpeakerModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ID': !exists(json, 'ID') ? undefined : json['ID'],
        'NAME': !exists(json, 'NAME') ? undefined : json['NAME'],
        'REGALIA': !exists(json, 'REGALIA') ? undefined : json['REGALIA'],
        'FILE': !exists(json, 'FILE') ? undefined : json['FILE'],
    };
}

export function SpeakerModelToJSON(value?: SpeakerModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ID': value.ID,
        'NAME': value.NAME,
        'REGALIA': value.REGALIA,
        'FILE': value.FILE,
    };
}

