/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ESkudSyncStatus } from './ESkudSyncStatus';
import {
    ESkudSyncStatusFromJSON,
    ESkudSyncStatusFromJSONTyped,
    ESkudSyncStatusToJSON,
} from './ESkudSyncStatus';

/**
 * При регистрации пользователь может забронировать локер (шкафчик временного хранения). В данном объекте отражена информация по локеру (шкафчику временного хранения). Если по данному адресу используется метод авторизации QR_locker, то требуется отобразить отдельный QR кода для локера (шкафчика временного хранения) из поля QR_DATA. Если объект равен null, локер (шкафчику временного хранения) не используется
 * @export
 * @interface ResponseUserRecordQRLOCKER
 */
export interface ResponseUserRecordQRLOCKER {
    /**
     * Текстовое представление изображения QR кода (base64) для локера (шкафчика временного хранения)
     * @type {string}
     * @memberof ResponseUserRecordQRLOCKER
     */
    QR_DATA?: string;
    /**
     * Название (номер) забронированного локера (шкафчика временного хранения).
     * @type {string}
     * @memberof ResponseUserRecordQRLOCKER
     */
    LOCKER_NUMBER?: string;
    /**
     * 
     * @type {ESkudSyncStatus}
     * @memberof ResponseUserRecordQRLOCKER
     */
    STATUS?: ESkudSyncStatus;
    /**
     * Дата и время начала бронирования шкафчика. Формат: DD.MM.YYYY hh:mm:ss
     * @type {string}
     * @memberof ResponseUserRecordQRLOCKER
     */
    DATETIME_FROM?: string;
    /**
     * Дата и время окончания бронирования шкафчика. Формат: DD.MM.YYYY hh:mm:ss
     * @type {string}
     * @memberof ResponseUserRecordQRLOCKER
     */
    DATETIME_TILL?: string;
}

/**
 * Check if a given object implements the ResponseUserRecordQRLOCKER interface.
 */
export function instanceOfResponseUserRecordQRLOCKER(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseUserRecordQRLOCKERFromJSON(json: any): ResponseUserRecordQRLOCKER {
    return ResponseUserRecordQRLOCKERFromJSONTyped(json, false);
}

export function ResponseUserRecordQRLOCKERFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseUserRecordQRLOCKER {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'QR_DATA': !exists(json, 'QR_DATA') ? undefined : json['QR_DATA'],
        'LOCKER_NUMBER': !exists(json, 'LOCKER_NUMBER') ? undefined : json['LOCKER_NUMBER'],
        'STATUS': !exists(json, 'STATUS') ? undefined : ESkudSyncStatusFromJSON(json['STATUS']),
        'DATETIME_FROM': !exists(json, 'DATETIME_FROM') ? undefined : json['DATETIME_FROM'],
        'DATETIME_TILL': !exists(json, 'DATETIME_TILL') ? undefined : json['DATETIME_TILL'],
    };
}

export function ResponseUserRecordQRLOCKERToJSON(value?: ResponseUserRecordQRLOCKER | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'QR_DATA': value.QR_DATA,
        'LOCKER_NUMBER': value.LOCKER_NUMBER,
        'STATUS': ESkudSyncStatusToJSON(value.STATUS),
        'DATETIME_FROM': value.DATETIME_FROM,
        'DATETIME_TILL': value.DATETIME_TILL,
    };
}

