/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlaceMenuItemMetroStationsValue
 */
export interface PlaceMenuItemMetroStationsValue {
    /**
     * Идентификатор станции
     * @type {string}
     * @memberof PlaceMenuItemMetroStationsValue
     */
    ID?: string;
    /**
     * Название станции метро
     * @type {string}
     * @memberof PlaceMenuItemMetroStationsValue
     */
    NAME?: string;
    /**
     * Название линии метро
     * @type {string}
     * @memberof PlaceMenuItemMetroStationsValue
     */
    STATION_LINE?: string;
}

/**
 * Check if a given object implements the PlaceMenuItemMetroStationsValue interface.
 */
export function instanceOfPlaceMenuItemMetroStationsValue(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlaceMenuItemMetroStationsValueFromJSON(json: any): PlaceMenuItemMetroStationsValue {
    return PlaceMenuItemMetroStationsValueFromJSONTyped(json, false);
}

export function PlaceMenuItemMetroStationsValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceMenuItemMetroStationsValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ID': !exists(json, 'ID') ? undefined : json['ID'],
        'NAME': !exists(json, 'NAME') ? undefined : json['NAME'],
        'STATION_LINE': !exists(json, 'STATION_LINE') ? undefined : json['STATION_LINE'],
    };
}

export function PlaceMenuItemMetroStationsValueToJSON(value?: PlaceMenuItemMetroStationsValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ID': value.ID,
        'NAME': value.NAME,
        'STATION_LINE': value.STATION_LINE,
    };
}

