import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { isEqual } from "lodash";
import { ResponseUserInfo } from "prosto-api-client";

import Boy30 from "~assets/images/B30.png";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import {
  AvatarIcon,
  ButtonIcon,
  FieldInput,
  Form,
  Modal,
  SubmitButton,
  SupportingText,
} from "~components";
import { mobileMedia } from "~constants";
import { LeaveConfirmationModal } from "~modules/user-account/LeaveConfirmationModal";
import { InferType, object, ref, string } from "~utils/validation";

export type PasswordChangeModalProps = {
  open?: boolean;
  onSubmit: (d: FormData) => Promise<ResponseUserInfo | void>;
  onClose: () => void;
};

type FormData = InferType<typeof PasswordSchema>;

const PasswordSchema = object({
  oldPassword: string().required(),
  password: string().required().min(8, "Пароль должен быть больше 8 символов"),
  confirmPassword: string()
    .required("Повтори пароль")
    .oneOf([ref("password")], "Пароли не совпадают"),
});

export const PasswordChangeModal = ({
  open,
  onSubmit,
  onClose,
}: PasswordChangeModalProps) => {
  const { closeModal, isOpenedModal, openModal } = Modal.useModal();
  return (
    <Modal fullPage open={open} onClose={onClose}>
      <Form
        initialValues={{ confirmPassword: "", oldPassword: "", password: "" }}
        onSubmit={onSubmit}
        validationSchema={PasswordSchema}
      >
        {({ values, initialValues }) => (
          <Container>
            <Header>
              <ImageTopBarContainer>
                <AvatarIcon icon={Boy30} />
                <Typography variant="h1">Изменение пароля</Typography>
              </ImageTopBarContainer>
              <ButtonIcon
                Icon={Cross}
                onClick={() => {
                  const wasTouched = !isEqual(values, initialValues);
                  if (wasTouched) {
                    openModal();
                  } else {
                    onClose();
                  }
                }}
              />
            </Header>
            <ContentContainer>
              <FieldInput.Password
                label="Введи старый пароль"
                name="oldPassword"
                placeholder="••••••••••"
                autoComplete="current-password"
              />
              <div>
                <FieldInput.Password
                  label="Введи новый пароль"
                  name="password"
                  placeholder="••••••••••"
                  autoComplete="new-password"
                  supportingText={
                    <SupportingText>
                      <Typography variant="body3">
                        Пароль должен быть больше 8 символов
                      </Typography>
                    </SupportingText>
                  }
                />
              </div>
              <FieldInput.Password
                label="Повтори новый пароль"
                name="confirmPassword"
                placeholder="••••••••••"
              />
            </ContentContainer>
            <SubmitButton fullWidth variant="first">
              Изменить пароль
            </SubmitButton>
          </Container>
        )}
      </Form>
      <LeaveConfirmationModal
        open={isOpenedModal}
        onClose={closeModal}
        onLeave={() => {
          closeModal();
          onClose();
        }}
      />
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const ImageTopBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1 0 0;

  ${mobileMedia} {
    gap: 20px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${mobileMedia} {
    gap: 30px;
  }
`;
