import styled from "@emotion/styled";
import { FC, SVGProps } from "react";

type ButtonIconProps = {
  Icon: FC<SVGProps<SVGSVGElement>>;
  fill?: string;
  onClick?: () => void;
  className?: string;
};

export const ButtonIcon = ({
  Icon,
  onClick,
  fill = "white",
  className,
}: ButtonIconProps) => {
  return (
    <Container className={className}>
      <IconContainer onClick={onClick}>
        <Icon fill={fill} />
      </IconContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
`;
