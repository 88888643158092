import { TextFieldProps } from "@mui/material";
import { ReactNode } from "react";
import { PasswordField, PasswordFieldProps, TextField } from "../../input";
import { FormItem } from "../FormItem";
import { MaskedInput, MaskedInputProps } from "../../input/MaskedInput";

type FieldInputProps = TextFieldProps & {
  name: string;
  maskProps?: MaskedInputProps;
  supportingText?: ReactNode;
};
export const FieldInput = ({
  name,
  maskProps,
  ...inputProps
}: FieldInputProps) => {
  const Input = maskProps ? MaskedInput : TextField;
  return (
    <FormItem name={name}>
      {({ field: { value, onBlur, onChange }, meta: { touched, error } }) => {
        return (
          <Input
            name={name}
            {...inputProps}
            // @ts-ignore
            config={maskProps?.config}
            value={value}
            onChange={(e) => {
              onChange(e);
              inputProps?.onChange?.(e);
            }}
            onBlur={(e) => {
              onBlur(e);
              inputProps?.onBlur?.(e);
            }}
            error={touched && Boolean(error)}
            helperText={touched && error}
          />
        );
      }}
    </FormItem>
  );
};

FieldInput.Password = ({
  name,
  ...inputProps
}: PasswordFieldProps & {
  name: string;
}) => {
  return (
    <FormItem name={name}>
      {({ field: { value, onBlur, onChange }, meta: { touched, error } }) => {
        return (
          <PasswordField
            name={name}
            {...inputProps}
            value={value}
            onChange={(e) => {
              onChange(e);
              inputProps?.onChange?.(e);
            }}
            onBlur={(e) => {
              onBlur(e);
              inputProps?.onBlur?.(e);
            }}
            error={touched && Boolean(error)}
            helperText={touched && error}
          />
        );
      }}
    </FormItem>
  );
};
