/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPlaceAuthenticationMode } from './EPlaceAuthenticationMode';
import {
    EPlaceAuthenticationModeFromJSON,
    EPlaceAuthenticationModeFromJSONTyped,
    EPlaceAuthenticationModeToJSON,
} from './EPlaceAuthenticationMode';
import type { ESkudSyncStatus } from './ESkudSyncStatus';
import {
    ESkudSyncStatusFromJSON,
    ESkudSyncStatusFromJSONTyped,
    ESkudSyncStatusToJSON,
} from './ESkudSyncStatus';
import type { EWhenEventOccurs } from './EWhenEventOccurs';
import {
    EWhenEventOccursFromJSON,
    EWhenEventOccursFromJSONTyped,
    EWhenEventOccursToJSON,
} from './EWhenEventOccurs';
import type { ResponseUserRecordQRLOCKER } from './ResponseUserRecordQRLOCKER';
import {
    ResponseUserRecordQRLOCKERFromJSON,
    ResponseUserRecordQRLOCKERFromJSONTyped,
    ResponseUserRecordQRLOCKERToJSON,
} from './ResponseUserRecordQRLOCKER';

/**
 * Данный объект представляет собой запись в коворкинг.
 * @export
 * @interface ResponseUserRecord
 */
export interface ResponseUserRecord {
    /**
     * Идентификатор коворкинга
     * @type {number}
     * @memberof ResponseUserRecord
     */
    PLACE_ID?: number;
    /**
     * Идентификатор конкретного события
     * @type {number}
     * @memberof ResponseUserRecord
     */
    EVENT_ID?: number;
    /**
     * Название мероприятия
     * @type {string}
     * @memberof ResponseUserRecord
     */
    EVENT_NAME?: string;
    /**
     * Дата проведения мероприятия в формате DD.MM.YYYY HH:mm:ss Часть с временем отсутствует у мероприятий типа коворкинг
     * @type {string}
     * @memberof ResponseUserRecord
     */
    EVENT_DATE?: string;
    /**
     * Указывает зарегистрирован ли данный пользователь на это мероприятие
     * @type {boolean}
     * @memberof ResponseUserRecord
     */
    REGISTERED?: boolean;
    /**
     * Указывает бронировал ли пользователь локер (шкафчик временного хранения)
     * @type {boolean}
     * @memberof ResponseUserRecord
     */
    LOCKER?: boolean;
    /**
     * Указывает, верифицирован ли пользователь. Если пользователь не верифицирован, то необходимо отобразить QRкод из поля QR_VERIFICATION
     * @type {boolean}
     * @memberof ResponseUserRecord
     */
    VERIFIED?: boolean;
    /**
     * 
     * @type {EPlaceAuthenticationMode}
     * @memberof ResponseUserRecord
     */
    PLACE_MODE?: EPlaceAuthenticationMode;
    /**
     * 
     * @type {EWhenEventOccurs}
     * @memberof ResponseUserRecord
     */
    WHEN_OCCURS?: EWhenEventOccurs;
    /**
     * Текстовое представление изображения QR кода (base64) верификации пользователя
     * @type {string}
     * @memberof ResponseUserRecord
     */
    QR_VERIFICATION?: string;
    /**
     * Текстовое представление изображения QR кода (base64) для прохода в коворкинг
     * @type {string}
     * @memberof ResponseUserRecord
     */
    QR_MAIN?: string;
    /**
     * Асоциативный масив забронированных временных интервалов. Ключом является идентификатор расписания, значение статус синхронизации со СКУД. Значение, отличное от SUCCESS говорит о неготовности и QR код не отображаем. Данный принцип действует только для способа аутентификации QR_skud. Для остальных способов статус игнорируется
     * @type {{ [key: string]: ESkudSyncStatus; }}
     * @memberof ResponseUserRecord
     */
    RECORDS?: { [key: string]: ESkudSyncStatus; };
    /**
     * 
     * @type {ResponseUserRecordQRLOCKER}
     * @memberof ResponseUserRecord
     */
    QR_LOCKER?: ResponseUserRecordQRLOCKER;
}

/**
 * Check if a given object implements the ResponseUserRecord interface.
 */
export function instanceOfResponseUserRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseUserRecordFromJSON(json: any): ResponseUserRecord {
    return ResponseUserRecordFromJSONTyped(json, false);
}

export function ResponseUserRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseUserRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PLACE_ID': !exists(json, 'PLACE_ID') ? undefined : json['PLACE_ID'],
        'EVENT_ID': !exists(json, 'EVENT_ID') ? undefined : json['EVENT_ID'],
        'EVENT_NAME': !exists(json, 'EVENT_NAME') ? undefined : json['EVENT_NAME'],
        'EVENT_DATE': !exists(json, 'EVENT_DATE') ? undefined : json['EVENT_DATE'],
        'REGISTERED': !exists(json, 'REGISTERED') ? undefined : json['REGISTERED'],
        'LOCKER': !exists(json, 'LOCKER') ? undefined : json['LOCKER'],
        'VERIFIED': !exists(json, 'VERIFIED') ? undefined : json['VERIFIED'],
        'PLACE_MODE': !exists(json, 'PLACE_MODE') ? undefined : EPlaceAuthenticationModeFromJSON(json['PLACE_MODE']),
        'WHEN_OCCURS': !exists(json, 'WHEN_OCCURS') ? undefined : EWhenEventOccursFromJSON(json['WHEN_OCCURS']),
        'QR_VERIFICATION': !exists(json, 'QR_VERIFICATION') ? undefined : json['QR_VERIFICATION'],
        'QR_MAIN': !exists(json, 'QR_MAIN') ? undefined : json['QR_MAIN'],
        'RECORDS': !exists(json, 'RECORDS') ? undefined : (mapValues(json['RECORDS'], ESkudSyncStatusFromJSON)),
        'QR_LOCKER': !exists(json, 'QR_LOCKER') ? undefined : ResponseUserRecordQRLOCKERFromJSON(json['QR_LOCKER']),
    };
}

export function ResponseUserRecordToJSON(value?: ResponseUserRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PLACE_ID': value.PLACE_ID,
        'EVENT_ID': value.EVENT_ID,
        'EVENT_NAME': value.EVENT_NAME,
        'EVENT_DATE': value.EVENT_DATE,
        'REGISTERED': value.REGISTERED,
        'LOCKER': value.LOCKER,
        'VERIFIED': value.VERIFIED,
        'PLACE_MODE': EPlaceAuthenticationModeToJSON(value.PLACE_MODE),
        'WHEN_OCCURS': EWhenEventOccursToJSON(value.WHEN_OCCURS),
        'QR_VERIFICATION': value.QR_VERIFICATION,
        'QR_MAIN': value.QR_MAIN,
        'RECORDS': value.RECORDS === undefined ? undefined : (mapValues(value.RECORDS, ESkudSyncStatusToJSON)),
        'QR_LOCKER': ResponseUserRecordQRLOCKERToJSON(value.QR_LOCKER),
    };
}

