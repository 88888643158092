import { EventsELEMENTIDGetRequest } from "prosto-api-client";
import { useCallback, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { eventApi } from "~services/api/prosto-api";
const GET_EVENTS = "GET_EVENTS";

const getEventsList = () => eventApi.eventsGet();
const getEventById = (eLEMENTID: EventsELEMENTIDGetRequest["eLEMENTID"]) =>
  eventApi.eventsELEMENTIDGet({ eLEMENTID });
const registerOnEvent = (eLEMENTID: EventsELEMENTIDGetRequest["eLEMENTID"]) =>
  eventApi.eventsELEMENTIDPost({ eLEMENTID });
const cancelRegistrationOnEvent = (
  eLEMENTID: EventsELEMENTIDGetRequest["eLEMENTID"]
) => eventApi.eventsELEMENTIDDelete({ eLEMENTID });

export const useEventById = (id: EventsELEMENTIDGetRequest["eLEMENTID"]) =>
  useQuery([GET_EVENTS, id], () => getEventById(id));

export const useEvents = () => {
  const queryClient = useQueryClient();
  const { data } = useQuery(GET_EVENTS, getEventsList);
  const { mutateAsync: registerOn, isLoading } = useMutation(registerOnEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_EVENTS);
    },
  });
  const { mutateAsync: cancelRegistration } = useMutation(
    cancelRegistrationOnEvent,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_EVENTS);
      },
    }
  );

  return {
    isLoadingRegister: isLoading,
    cancelRegistration,
    registerOn,
    eventsInfo: data,
  };
};

const init = {
  event: false,
  confirm: false,
  cancel: false,
  success: false,
};
export const useEventModals = () => {
  const [modalState, setState] = useState<typeof init>(init);

  const close = useCallback(() => setState(init), []);
  const open = useCallback((name: keyof typeof init) => {
    setState((s) => ({ ...s, [name]: true }));
  }, []);
  return { open, close, modalState };
};
