/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PlaceMenu } from './PlaceMenu';
import {
    PlaceMenuFromJSON,
    PlaceMenuFromJSONTyped,
    PlaceMenuToJSON,
} from './PlaceMenu';
import type { ResponseEventModel } from './ResponseEventModel';
import {
    ResponseEventModelFromJSON,
    ResponseEventModelFromJSONTyped,
    ResponseEventModelToJSON,
} from './ResponseEventModel';
import type { SpeakerModel } from './SpeakerModel';
import {
    SpeakerModelFromJSON,
    SpeakerModelFromJSONTyped,
    SpeakerModelToJSON,
} from './SpeakerModel';

/**
 * Объект представляющий список мероприятий
 * @export
 * @interface ResponseEventCollection
 */
export interface ResponseEventCollection {
    /**
     * 
     * @type {PlaceMenu}
     * @memberof ResponseEventCollection
     */
    place_menu?: PlaceMenu;
    /**
     * Ассоциативный массив. Ключом является идентификатор коворкинга для которого представленно расписание
     * @type {{ [key: string]: Array<ResponseEventModel>; }}
     * @memberof ResponseEventCollection
     */
    items?: { [key: string]: Array<ResponseEventModel>; };
    /**
     * Список доступных тематик в данном ответе
     * @type {{ [key: string]: string; }}
     * @memberof ResponseEventCollection
     */
    THEMATICS?: { [key: string]: string; };
    /**
     * Спикеры мероприятия
     * @type {{ [key: string]: SpeakerModel; }}
     * @memberof ResponseEventCollection
     */
    SPEAKERMANS?: { [key: string]: SpeakerModel; };
}

/**
 * Check if a given object implements the ResponseEventCollection interface.
 */
export function instanceOfResponseEventCollection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseEventCollectionFromJSON(json: any): ResponseEventCollection {
    return ResponseEventCollectionFromJSONTyped(json, false);
}

export function ResponseEventCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseEventCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'place_menu': !exists(json, 'place_menu') ? undefined : PlaceMenuFromJSON(json['place_menu']),
        'items': !exists(json, 'items') ? undefined : json['items'],
        'THEMATICS': !exists(json, 'THEMATICS') ? undefined : json['THEMATICS'],
        'SPEAKERMANS': !exists(json, 'SPEAKERMANS') ? undefined : (mapValues(json['SPEAKERMANS'], SpeakerModelFromJSON)),
    };
}

export function ResponseEventCollectionToJSON(value?: ResponseEventCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'place_menu': PlaceMenuToJSON(value.place_menu),
        'items': value.items,
        'THEMATICS': value.THEMATICS,
        'SPEAKERMANS': value.SPEAKERMANS === undefined ? undefined : (mapValues(value.SPEAKERMANS, SpeakerModelToJSON)),
    };
}

