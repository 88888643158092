import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import * as React from "react";

import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import { ReactComponent as Map } from "~assets/svg/map.svg";
import { ButtonIcon, Modal } from "~components";
import { mobileMedia } from "~constants";
import { DateService } from "~services/Date";
import { Palette } from "~utils/Palette";

type ArchivedRecordModalProps = {
  open: boolean;
  onClose: () => void;
  name?: string;
  placeName?: string;
  date?: Date;
  startTime?: number | string;
  endTime?: number;
  lockerNumber?: number;
  geoLink?: string;
};

export const ArchivedRecordModal = ({
  open,
  onClose,
  name = "Запись в коворкинг",
  startTime,
  endTime,
  placeName,
  date,
  lockerNumber,
  geoLink,
}: ArchivedRecordModalProps) => {
  const dateString = date
    ? DateService.fromJSDate(date).toFormat("dd MMM yyyy", {
        locale: "ru",
      })
    : "";
  const formattedDate =
    dateString.slice(0, 6) + dateString.slice(dateString.length - 5);

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <Header>
          <TitleAndButtonsBlock>
            <Typography variant="h2">
              <span dangerouslySetInnerHTML={{ __html: name }} />
            </Typography>
            <ButtonsBlock>
              <ButtonIcon Icon={Cross} onClick={onClose} />
            </ButtonsBlock>
          </TitleAndButtonsBlock>
        </Header>
        <DatePlaceInformation>
          <Place>
            <PlaceName>
              <Typography variant="label3Thin">Точка</Typography>
              <Typography variant="h3">{placeName}</Typography>
            </PlaceName>
            {geoLink && (
              <ButtonIcon
                Icon={Map}
                onClick={() => {
                  window.open(geoLink);
                }}
              />
            )}
          </Place>
          <DateTime>
            <CellInfo>
              <Typography variant="label3Thin">Дата</Typography>
              <Typography variant="h3">{formattedDate}</Typography>
            </CellInfo>
            <CellInfo>
              <Typography variant="label3Thin">Время</Typography>
              <TimeView>
                <Typography variant="h3">{startTime}</Typography>
                {endTime && (
                  <>
                    <Hyphen />
                    <Typography variant="h3">{endTime}</Typography>
                  </>
                )}
              </TimeView>
            </CellInfo>
          </DateTime>
        </DatePlaceInformation>
        {lockerNumber && (
          <LockerInfo>
            <LockerInfoInnerContainer>
              <CellInfo>
                <Typography variant="label3Thin">Локер</Typography>
                <Typography variant="h3">{lockerNumber}</Typography>
              </CellInfo>
            </LockerInfoInnerContainer>
          </LockerInfo>
        )}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-self: stretch;
  gap: 50px;
  flex: 1 0 0;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;

  ${mobileMedia} {
    gap: 20px;
  }
`;

const TitleAndButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const DatePlaceInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
`;

const Place = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const PlaceName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  flex: 1 0 0;
`;

const DateTime = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  flex: 1 0 0;
`;

const CellInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
`;

const TimeView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const Hyphen = styled.div`
  width: 15px;
  height: 3px;
  margin: 1px 0 5px;
  background: ${Palette["B&W/100"]};
`;

const LockerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const LockerInfoInnerContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;
