import styled from "@emotion/styled";
import { RefObject, useEffect, useRef, useState } from "react";
import { mobileMedia } from "~constants";
import { Palette } from "~utils/Palette";
import { Time } from "../types";
import { Scroll } from "./Scroll";

type TimePickerProps = {
  leftLabel: string;
  rightLabel: string;
  leftOptions?: Time[];
  rightOptions?: Time[];
  onLeftSelect?: (date?: number) => void;
  onRightSelect?: (date?: number) => void;
};
export const TimePicker = ({
  leftLabel,
  rightLabel,
  leftOptions,
  rightOptions,
  onLeftSelect,
  onRightSelect,
}: TimePickerProps) => {
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);
  const [lastSelected, setLastSelected] = useState<"left" | "right">();

  const findElement = (container: HTMLDivElement, time: Time) => {
    return Array.from(container.children).find(
      (child) =>
        child.querySelector("button")?.firstChild?.textContent === time?.label
    );
  };

  const scrollParent = (timeElement: Element) => {
    const parent = timeElement.parentNode as HTMLDivElement;
    const top = (timeElement as HTMLDivElement).offsetTop - parent.offsetTop;
    parent.scrollTo({ top: top, behavior: "smooth" });
  };

  const scrollToFirstAvailableTime = (
    ref: RefObject<HTMLDivElement>,
    options?: Time[]
  ) => {
    const container = ref.current;
    if (container === null) return;

    const activeTime = options?.find((o) => o.selected);
    if (activeTime) {
      const activeElement = findElement(container, activeTime);
      activeElement && scrollParent(activeElement);
      return;
    }

    const firstAvailableTime = options?.find((o) => !o.disabled);
    if (firstAvailableTime) {
      const firstAvailableElement = findElement(container, firstAvailableTime);
      firstAvailableElement && scrollParent(firstAvailableElement);
    }
  };

  useEffect(() => {
    if (lastSelected !== "right") {
      scrollToFirstAvailableTime(rightRef, rightOptions);
    }
  }, [leftOptions]);

  useEffect(() => {
    if (lastSelected !== "left") {
      scrollToFirstAvailableTime(leftRef, leftOptions);
    }
  }, [rightOptions]);

  const handleLeftSelect = (date?: number) => {
    onLeftSelect?.(date);
    setLastSelected("left");
  };

  const handleRightSelect = (date?: number) => {
    onRightSelect?.(date);
    setLastSelected("right");
  };

  return (
    <PickersContainer>
      <Scroll
        ref={leftRef}
        label={leftLabel}
        options={leftOptions}
        onSelect={handleLeftSelect}
      />
      <Scroll
        ref={rightRef}
        label={rightLabel}
        options={rightOptions}
        onSelect={handleRightSelect}
      />
    </PickersContainer>
  );
};

const PickersContainer = styled.div`
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border-radius: 25px;
  background: ${Palette["B&W/10"]};

  ${mobileMedia} {
    padding: 7px;
    gap: 7px;
    border-radius: 20px;
  }
`;
