/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResponseEventCollection,
  ResponseEventModel,
} from '../models';
import {
    ResponseEventCollectionFromJSON,
    ResponseEventCollectionToJSON,
    ResponseEventModelFromJSON,
    ResponseEventModelToJSON,
} from '../models';

export interface EventsELEMENTIDDeleteRequest {
    eLEMENTID: number;
}

export interface EventsELEMENTIDGetRequest {
    eLEMENTID: number;
}

export interface EventsELEMENTIDPostRequest {
    eLEMENTID: number;
}

/**
 * 
 */
export class EventApi extends runtime.BaseAPI {

    /**
     * Данный метод позволяет отменить записать на конкретное мероприятие
     * Отмена записи на конкретное мероприятие
     */
    async eventsELEMENTIDDeleteRaw(requestParameters: EventsELEMENTIDDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseEventModel>> {
        if (requestParameters.eLEMENTID === null || requestParameters.eLEMENTID === undefined) {
            throw new runtime.RequiredError('eLEMENTID','Required parameter requestParameters.eLEMENTID was null or undefined when calling eventsELEMENTIDDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/events/{ELEMENT_ID}/`.replace(`{${"ELEMENT_ID"}}`, encodeURIComponent(String(requestParameters.eLEMENTID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseEventModelFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет отменить записать на конкретное мероприятие
     * Отмена записи на конкретное мероприятие
     */
    async eventsELEMENTIDDelete(requestParameters: EventsELEMENTIDDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseEventModel> {
        const response = await this.eventsELEMENTIDDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет получить данные конкретного мероприятия
     * Получение данных конкретного мероприятия
     */
    async eventsELEMENTIDGetRaw(requestParameters: EventsELEMENTIDGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseEventModel>> {
        if (requestParameters.eLEMENTID === null || requestParameters.eLEMENTID === undefined) {
            throw new runtime.RequiredError('eLEMENTID','Required parameter requestParameters.eLEMENTID was null or undefined when calling eventsELEMENTIDGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/events/{ELEMENT_ID}/`.replace(`{${"ELEMENT_ID"}}`, encodeURIComponent(String(requestParameters.eLEMENTID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseEventModelFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет получить данные конкретного мероприятия
     * Получение данных конкретного мероприятия
     */
    async eventsELEMENTIDGet(requestParameters: EventsELEMENTIDGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseEventModel> {
        const response = await this.eventsELEMENTIDGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет записаться на конкретное мероприятие
     * Записаться на данное мероприятие ELEMENT_ID
     */
    async eventsELEMENTIDPostRaw(requestParameters: EventsELEMENTIDPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseEventModel>> {
        if (requestParameters.eLEMENTID === null || requestParameters.eLEMENTID === undefined) {
            throw new runtime.RequiredError('eLEMENTID','Required parameter requestParameters.eLEMENTID was null or undefined when calling eventsELEMENTIDPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/events/{ELEMENT_ID}/`.replace(`{${"ELEMENT_ID"}}`, encodeURIComponent(String(requestParameters.eLEMENTID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseEventModelFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет записаться на конкретное мероприятие
     * Записаться на данное мероприятие ELEMENT_ID
     */
    async eventsELEMENTIDPost(requestParameters: EventsELEMENTIDPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseEventModel> {
        const response = await this.eventsELEMENTIDPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет получить данные для заполнения календаря мероприятий
     * Получение данных для заполнения календаря мероприятий.
     */
    async eventsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseEventCollection>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/events/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseEventCollectionFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет получить данные для заполнения календаря мероприятий
     * Получение данных для заполнения календаря мероприятий.
     */
    async eventsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseEventCollection> {
        const response = await this.eventsGetRaw(initOverrides);
        return await response.value();
    }

}
