import r2wc from "@r2wc/react-to-web-component";
import { Registration } from "~modules/registration/Registration";
import { UserAccount } from "~modules/user-account/UserAccount";
import { Login } from "./modules/login/Login";
import { CoWorkingRegistration } from "./modules/co-working/CoWorkingRegistration";
import { App } from "./App";
import { Events } from "~modules/events/Events";
import { PasswordReset } from "~modules/passwords/PasswordReset";
import { PasswordChange } from "~modules/passwords/PasswordChange";
import { removeBrowserWarning } from "~utils/removeBrowserWarning";

removeBrowserWarning();

customElements.define(
  "prosto-login-form",
  // @ts-ignore
  r2wc(App(Login), {
    props: {
      resetPasswordPageUrl: "string",
      registerPageUrl: "string",
    },
  })
);
customElements.define(
  "prosto-coworking-registration",
  // @ts-ignore
  r2wc(App(CoWorkingRegistration), {
    props: {
      activePlaceId: "string",
      modalLockerAnchor: "string",
    },
  })
);
customElements.define("prosto-events", r2wc(App(Events)));
customElements.define(
  "prosto-registration",
  // @ts-ignore
  r2wc(App(Registration), {
    props: {
      rulesPageUrl: "string",
      personalDataRulesPageUrl: "string",
    },
  })
);
customElements.define(
  "prosto-user-account",
  // @ts-ignore
  r2wc(App(UserAccount), {
    props: {
      coworkingPageUrl: "string",
      eventsPageUrl: "string",
      modalAccountVerificationAnchor: "string",
    },
  })
);
customElements.define(
  "prosto-reset-password",
  // @ts-ignore
  r2wc(App(PasswordReset), {
    props: {
      successPageUrl: "string",
    },
  })
);
customElements.define(
  "prosto-change-password",
  r2wc(App(PasswordChange), {
    props: {
      successPageUrl: "string",
    },
  })
);
