import styled from "@emotion/styled";
import { FC, SVGProps } from "react";

type IconProps = {
  Component: FC<SVGProps<SVGSVGElement>>;
  fill?: string;
  onClick?: () => void;
};

export const Icon = ({
  Component,
  fill = "white",
  onClick,
  ...other
}: IconProps) => {
  return (
    <Container onClick={onClick} {...other}>
      <Component fill={fill} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  cursor: pointer;
  height: 40px;
  width: 40px;
`;
