/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PlaceMenu } from './PlaceMenu';
import {
    PlaceMenuFromJSON,
    PlaceMenuFromJSONTyped,
    PlaceMenuToJSON,
} from './PlaceMenu';

/**
 * Объект, представляющий данные для календаря записи в коворкинг
 * @export
 * @interface ResponseCoworkingCalendarList
 */
export interface ResponseCoworkingCalendarList {
    /**
     * 
     * @type {PlaceMenu}
     * @memberof ResponseCoworkingCalendarList
     */
    place_menu?: PlaceMenu;
    /**
     * Ассоциативный массив. Ключом является идентификатор коворкинга для которого представленно расписание
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof ResponseCoworkingCalendarList
     */
    items?: { [key: string]: { [key: string]: string; }; };
    /**
     * Идентификатор часто посещаемого коворкинга
     * @type {string}
     * @memberof ResponseCoworkingCalendarList
     */
    frequent_visit_place?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseCoworkingCalendarList
     */
    US_RESERVATED_EVENTS?: Array<string>;
}

/**
 * Check if a given object implements the ResponseCoworkingCalendarList interface.
 */
export function instanceOfResponseCoworkingCalendarList(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseCoworkingCalendarListFromJSON(json: any): ResponseCoworkingCalendarList {
    return ResponseCoworkingCalendarListFromJSONTyped(json, false);
}

export function ResponseCoworkingCalendarListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCoworkingCalendarList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'place_menu': !exists(json, 'place_menu') ? undefined : PlaceMenuFromJSON(json['place_menu']),
        'items': !exists(json, 'items') ? undefined : json['items'],
        'frequent_visit_place': !exists(json, 'frequent_visit_place') ? undefined : json['frequent_visit_place'],
        'US_RESERVATED_EVENTS': !exists(json, 'US_RESERVATED_EVENTS') ? undefined : json['US_RESERVATED_EVENTS'],
    };
}

export function ResponseCoworkingCalendarListToJSON(value?: ResponseCoworkingCalendarList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'place_menu': PlaceMenuToJSON(value.place_menu),
        'items': value.items,
        'frequent_visit_place': value.frequent_visit_place,
        'US_RESERVATED_EVENTS': value.US_RESERVATED_EVENTS,
    };
}

