import styled from "@emotion/styled";
import { ClickAwayListener } from "@mui/material";
import { ReactNode, useState } from "react";

import { ReactComponent as CalendarOutlinedView } from "~assets/svg/calendar_outlined_view.svg";
import { FormItem, MaskedInput } from "~components";
import { DatePicker, DatePickerProps } from "../../datepicker";
import { Icon } from "../../images/Icon";
import { DateService } from "~services/Date";
import { mobileMedia, DATE_FORMAT } from "~constants";

type FieldDatePickerProps = {
  name: string;
  label: string;
  supportingText?: ReactNode;
  placeholder?: string;
} & DatePickerProps;
export const FieldDatePicker = ({
  name,
  label,
  supportingText,
  disabled,
  placeholder,
  ...datePickerProps
}: FieldDatePickerProps) => {
  const [open, setOpen] = useState(false);
  return (
    <FormItem name={name}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
        meta: { error, touched },
      }) => (
        <Container>
          <DateField
            disabled={disabled}
            supportingText={supportingText}
            focused={open}
            config={{
              mask: Date,
              pattern: "d.m.Y",
              format: (date: any) =>
                DateService.toFormattedString(date as Date, DATE_FORMAT),
              parse: (str) => DateService.parse(str, DATE_FORMAT).toJSDate(),
              autofix: true,
              lazy: true,
              overwrite: true,
            }}
            label={label}
            value={
              value
                ? DateService.toFormattedString(value as Date, DATE_FORMAT)
                : undefined
            }
            error={touched && Boolean(error)}
            helperText={touched && error}
            onClick={() => setOpen(true)}
            onComplete={(str) => {
              setFieldTouched(name);
              const d = DateService.parse(str, DATE_FORMAT).toJSDate();
              setFieldValue(name, d);
            }}
            InputProps={{
              placeholder,
              endAdornment: !disabled && (
                <Icon
                  Component={CalendarOutlinedView}
                  onClick={() => setOpen(true)}
                />
              ),
            }}
          />
          {open && (
            <ClickAwayListener
              onClickAway={(e) => {
                // @ts-ignore
                const isSelectClicked = e.target?.closest?.(
                  "[data-paper-container]"
                );
                const isBody = e.target === document.body;
                if (!isBody && !isSelectClicked) {
                  setOpen(false);
                }
              }}
            >
              <DatePickerStyled
                {...datePickerProps}
                date={
                  value
                    ? (value as Date)
                    : placeholder
                    ? DateService.parse(placeholder, DATE_FORMAT).toJSDate()
                    : new Date()
                }
                onChange={(d) => {
                  setFieldTouched(name);
                  setFieldValue(name, d);
                  setOpen(false);
                }}
                showYearPicker
              />
            </ClickAwayListener>
          )}
        </Container>
      )}
    </FormItem>
  );
};
const DatePickerStyled = styled(DatePicker)`
  position: absolute;
  z-index: 1200;
  right: 0;
  max-width: 360px;
  ${mobileMedia} {
    max-width: 100%;
  }
`;
const Container = styled.div`
  position: relative;
  width: 100%;
`;
const DateField = styled(MaskedInput)`
  & .MuiInputBase-root {
    padding-right: 10px;
    ${mobileMedia} {
      padding-right: 5px;
    }
  }
`;
