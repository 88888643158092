import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { forwardRef } from "react";
import { mobileMedia } from "~constants";
import { Palette } from "~utils/Palette";
import { Time } from "../types";
import { Item } from "./Item";

type ScrollProps = {
  options?: Time[];
  onSelect?: (value?: number) => void;
  label?: string;
  error?: boolean;
};

export const Scroll = forwardRef<HTMLDivElement, ScrollProps>(
  ({ label, options = [], error = false, onSelect }, ref) => {
    return (
      <Container>
        {label && <Typography variant="label2">{label}</Typography>}
        <ScrollContainer error={error}>
          <ScrollInnerContainer ref={ref}>
            {options
              .filter((i) => !i.hidden)
              .map(({ value, label, disabled, selected }) => {
                return (
                  <Item
                    label={label}
                    value={value}
                    key={value}
                    active={selected}
                    onClick={onSelect}
                    disabled={disabled}
                  />
                );
              })}
          </ScrollInnerContainer>
        </ScrollContainer>
      </Container>
    );
  }
);

const Container = styled.div`
  height: 180px;
  padding-top: 4px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  ${mobileMedia} {
    height: 160px;
  }
`;

const ScrollContainer = styled.div<{ error: boolean }>`
  height: 147px;
  box-sizing: border-box;
  padding: 0 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 17px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ error }) =>
    error ? Palette["Error/50"] : Palette["B&W/20"]};
  background: ${Palette["B&W/00"]};

  ${mobileMedia} {
    height: 129px;
    padding: 0 3px;
    border-radius: 13px;
  }
`;

const ScrollInnerContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2px;
  flex: 1 0 0;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & div:first-of-type {
    padding-top: 8px;
  }

  & div:last-of-type {
    padding-bottom: 8px;
  }

  ${mobileMedia} {
    gap: 1px;

    & div:first-of-type {
      padding-top: 7px;
    }

    & div:last-of-type {
      padding-bottom: 7px;
    }
  }
`;
