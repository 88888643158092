import styled from "@emotion/styled";
// import { EventCardProps } from "./SpecialEventCard";
import {
  ConfirmModal,
  CanceledModal,
  SelectInput,
  SuccessfulRequestModal,
} from "~components";
import { EventElementCard } from "./ElementCard";
import { EventInfo } from "./EventInfo";
import { mobileMedia, DATE_TIME_FORMAT } from "~constants";
import { useEffect, useState } from "react";
import { useEventModals, useEvents } from "./hooks";
import { ResponseEventCollection } from "prosto-api-client";
import { DateService } from "~services/Date";
import { useQueryParams } from "~utils/useQueryParams";
import { Typography } from "@mui/material";

const eventQueryParamName = "eventId";
export const Events = () => {
  const { cancelRegistration, eventsInfo, isLoadingRegister, registerOn } =
    useEvents();
  const { close, modalState, open } = useEventModals();
  const { getValue: getParamValue, setQueryParam } = useQueryParams();
  const eventId = getParamValue(eventQueryParamName);
  const defaultPlace = getParamValue("place") || "all";
  const defaultTheme = getParamValue("theme") || "all";

  const thematicsOpts =
    eventsInfo?.THEMATICS &&
    Object.values(eventsInfo?.THEMATICS).map((t) => ({
      label: t,
      value: t,
    }));
  thematicsOpts?.unshift({ label: "Все", value: "all" });

  const [filter, setFilter] = useState<{
    place?: string;
    theme?: string;
  }>({
    place: defaultPlace,
    theme: defaultTheme,
  });

  const [event, setEvent] = useState<Event>();
  useEffect(() => {
    if (event && modalState.event) {
      setQueryParam({
        name: eventQueryParamName,
        value: event.id.toString(),
      });
    }
  }, [event?.id, modalState.event]);

  const coworkingOpts = getCoworkingOptions(eventsInfo?.place_menu) || [];
  const events = getEvents(eventsInfo, filter?.place);

  useEffect(() => {
    if (eventId) {
      const defaultEvent = events?.find((e) => e.id.toString() === eventId);
      if (defaultEvent) {
        setEvent(defaultEvent);
        open("event");
      }
    }
  }, [events?.length]);

  const filteredEvents = events?.filter(
    (e) => e.theme === filter?.theme || isAll(filter?.theme)
  );
  return (
    <Container>
      <Selectors>
        <StyledSelect
          label="Выбери точки"
          value={filter?.place}
          onChange={(place) => {
            // @ts-ignore
            setFilter((fv) => ({ ...fv, place }));
            place &&
              setQueryParam({
                name: "place",
                value: isAll(place as string) ? "" : place.toString(),
              });
          }}
          // @ts-ignore
          options={coworkingOpts}
        />
        <StyledSelect
          value={filter?.theme}
          label="Выбери тематику"
          onChange={(theme) => {
            // @ts-ignore
            setFilter((fv) => ({ ...fv, theme }));
            theme &&
              setQueryParam({
                name: "theme",
                value: isAll(theme as string) ? "" : theme.toString(),
              });
          }}
          // @ts-ignore
          options={thematicsOpts}
        />
      </Selectors>
      <EventsBlock>
        {filteredEvents?.length > 0 ? (
          <>
            {filteredEvents?.map((e) => (
              <EventElementCard
                key={e.id}
                date={e.date}
                title={e.name}
                onClick={() => {
                  setEvent(e);
                  open("event");
                }}
              />
            ))}
          </>
        ) : (
          <>
            <Typography variant="h4" margin={"auto"}>
              Мероприятий не найдено
            </Typography>
          </>
        )}
      </EventsBlock>
      {/* <TopBar>
        <Typography variant="h2">Ближайший спецпроект</Typography>
        <Button variant="second">Все спецпроекты</Button>
      </TopBar> */}
      {/* <EventsBlock>
        {specEvents
          .filter((e) => e.variant === "L")
          .map((e) => (
            <SpecialEventCard key={e.title} {...e} />
          ))}
      </EventsBlock> */}

      {event && (
        <EventInfo
          eventId={event.id}
          open={modalState.event}
          onClose={() => {
            setQueryParam({
              name: eventQueryParamName,
              value: "",
            });
            close();
          }}
          onCancel={() => open("confirm")}
          place={event?.id ? getEventPlace(eventsInfo, event?.id) : undefined}
          isLoadingRegister={isLoadingRegister}
          onRegister={async () => {
            try {
              await registerOn(event.id);
              close();
              open("success");
            } catch (error) {}
          }}
        />
      )}
      <ConfirmModal
        muiModalProps={{
          sx: {
            // z-index у EventInfo  100 000
            zIndex: 100001,
          },
        }}
        open={modalState.confirm}
        onClose={close}
        onCancel={() => {
          close();
          event && cancelRegistration(event.id);
          open("cancel");
        }}
      />
      <CanceledModal open={modalState.cancel} onClose={close} />
      <SuccessfulRequestModal
        date={event?.date!}
        open={modalState.success}
        startTime={
          event?.date ? DateService.toFormattedString(event?.date, "HH:mm") : ""
        }
        geoLink={
          event?.id ? getEventPlace(eventsInfo, event?.id)?.address_ya_map! : ""
        }
        placeName={event?.id ? getEventPlace(eventsInfo, event?.id)?.name! : ""}
        eventTitle={event?.name}
        onClose={() => {
          setQueryParam();
          close();
        }}
        disclaimer="Возьми документ, подтверждающий личность, и не опаздывай. В случае опоздания более 15 минут попасть на мероприятие невозможно"
        userVerified={true}
        onCancelRegister={() => {
          event && cancelRegistration(event.id);
          open("cancel");
        }}
      />
    </Container>
  );
};
type Event = {
  id: number;
  name?: string;
  date?: Date;
  placeName?: string;
  placeId?: string;
  theme?: string;
};
const getAllEvents = (items: ResponseEventCollection["items"] = {}) =>
  Object.values(items).reduce((acc, val) => acc.concat(val), []);

const isAll = (o?: string) => o === "all";

const getEventPlace = (
  einfo: ResponseEventCollection = {},
  eventId: Event["id"]
) => {
  const selectedPlaceId = getAllEvents(einfo.items).find(
    (e) => e.EVENT_ID === eventId
  )?.PLACE_ID!;
  return einfo?.place_menu?.places?.[selectedPlaceId];
};
const getEvents = (einfo: ResponseEventCollection = {}, placeId?: string) => {
  if (!placeId || !einfo?.items) return [];
  const events = isAll(placeId)
    ? getAllEvents(einfo.items)
    : einfo?.items[placeId];
  return events
    ?.map((e) => ({
      id: e.EVENT_ID!,
      name: e.EVENT_NAME,
      placeId: e.PLACE_ID?.toString(),
      theme: e.THEMATICS,
      // "19.12.2023 19:00:00"
      date: DateService.parse(e.EVENT_DATE!, DATE_TIME_FORMAT).toJSDate(),
    }))
    .sort((a, b) => DateService.compare(a.date, b.date));
};

const getCoworkingOptions = (
  placeMenu: ResponseEventCollection["place_menu"]
) => {
  const places = placeMenu?.places && Object.entries(placeMenu.places);
  const placeOpts = places?.map(([id, p]) => ({
    label: p.name,
    value: id,
  }));
  placeOpts?.unshift({
    label: "Все",
    value: "all",
  });
  return placeOpts;
};

const StyledSelect = styled(SelectInput)`
  max-width: calc(50% - 10px);
  ${mobileMedia} {
    max-width: 100%;
  }
`;

const Selectors = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  max-width: 100%;
  ${mobileMedia} {
    gap: 40px;
    flex-direction: column;
  }
`;

const EventsBlock = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 90px;
  flex-wrap: wrap;
  justify-content: space-between;
  ${mobileMedia} {
    flex-direction: column;
  }
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const Container = styled.div`
  background-color: black;
`;
