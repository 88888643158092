import { useFormikContext as useFormContext } from "formik";
import React, { ComponentPropsWithRef, FC } from "react";

import { Button } from "../button";

type ButtonProps = ComponentPropsWithRef<typeof Button>;
type SubmitButtonProps = { disabledOnInvalid?: boolean } & ButtonProps;

export const SubmitButton: FC<SubmitButtonProps> = ({
  children,
  disabledOnInvalid = true,
  className,
  ...rest
}) => {
  const { isSubmitting, isValid, dirty: isDirty } = useFormContext();

  const isValidCountingFormDirtiness = isDirty ? isValid : false;
  const isDisabled = disabledOnInvalid ? !isValidCountingFormDirtiness : false;
  return (
    <Button
      {...rest}
      disabled={isDisabled}
      type={"submit"}
      className={className}
      loading={isSubmitting}
    >
      {children}
    </Button>
  );
};
