import { createTheme } from "@mui/material";

import { Palette } from "~utils/Palette";
import { adaptive, mobileMedia } from "./adaptive";

export const theme = createTheme({
  breakpoints: {
    values: adaptive,
  },
  typography: {
    fontFamily: '"CodecPro", Arial, sans-serif',
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: Palette["B&W/100"],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: "50px",
          fontSize: "16px",
          fontWeight: 400,
          textTransform: "none",
          lineHeight: "18px",
          color: Palette["B&W/00"],
          borderRadius: "25px",
          width: "170px",
        },
      },
      variants: [
        {
          props: { variant: "first" },
          style: {
            backgroundColor: Palette["B&W/100"],
            ":hover": {
              backgroundColor: Palette["B&W/95"],
            },
            ":active": {
              backgroundColor: Palette["B&W/90"],
            },
            ":disabled": {
              backgroundColor: Palette["B&W/10"],
              color: Palette["B&W/20"],
            },
            "& div": {
              backgroundColor: Palette["B&W/95"],
            },
          },
        },
        {
          props: { variant: "second" },
          style: {
            backgroundColor: Palette["B&W/20"],
            color: Palette["B&W/100"],
            ":hover": {
              backgroundColor: Palette["B&W/30"],
            },
            ":active": {
              backgroundColor: Palette["B&W/40"],
            },
            ":disabled": {
              backgroundColor: Palette["B&W/05"],
              color: Palette["B&W/20"],
            },
            "& div": {
              backgroundColor: Palette["B&W/30"],
            },
          },
        },
        {
          props: { variant: "third" },
          style: {
            backgroundColor: Palette["B&W/00"],
            color: Palette["B&W/100"],
            ":hover": {
              backgroundColor: Palette["B&W/10"],
            },
            ":active": {
              backgroundColor: Palette["B&W/20"],
            },
            ":disabled": {
              backgroundColor: Palette["B&W/00"],
              color: Palette["B&W/20"],
            },
          },
        },
        {
          props: { variant: "danger" },
          style: {
            backgroundColor: Palette["Error/50"],
            color: Palette["B&W/100"],
            ":hover": {
              backgroundColor: Palette["Error/40"],
            },
            ":active": {
              backgroundColor: Palette["Error/30"],
            },
            ":disabled": {
              backgroundColor: Palette["Error/10"],
              color: Palette["Error/05"],
            },
            "& div": {
              backgroundColor: Palette["B&W/50"],
              opacity: 0.4000000059604645,
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&": {
            color: Palette["B&W/100"],
            height: "60px",
            alignSelf: "stretch",
            borderRadius: "14px",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "22px",
          },

          "& fieldset": {
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: Palette["B&W/20"],
          },

          ".MuiInputBase-input::placeholder": {
            WebkitTextFillColor: Palette["B&W/30"],
            opacity: 1,
          },

          "&.MuiOutlinedInput-root:active, &.MuiOutlinedInput-root:hover": {
            backgroundColor: Palette["B&W/10"],
            fieldset: {
              borderColor: Palette["B&W/20"],
            },
          },

          "&.MuiOutlinedInput-root.Mui-focused": {
            backgroundColor: Palette["B&W/10"],
            fieldset: {
              borderColor: Palette["B&W/100"],
            },
          },

          [mobileMedia]: {
            "&": {
              height: "50px",
              fontSize: "16px",
              lineHeight: "20px",
              borderRadius: "12px",
            },

            ".MuiInputAdornment-positionEnd .MuiIconButton-edgeEnd": {
              paddingRight: "5px",
            },
          },
        },
        input: {
          "&:-webkit-autofill": {
            backgroundClip: "text",
            WebkitTextFillColor: Palette["B&W/100"],
          },
          "&": {
            paddingLeft: "19px",
            paddingRight: "19px",
            height: "auto",
          },

          [mobileMedia]: {
            "&": {
              paddingLeft: "15px",
              paddingRight: "15px",
            },
          },
        },
      },
      variants: [
        {
          props: { disabled: true },
          style: {
            ".MuiInputBase-input.Mui-disabled, .MuiInputBase-input::placeholder":
              {
                WebkitTextFillColor: Palette["B&W/10"],
              },
            "&.MuiOutlinedInput-root.Mui-disabled fieldset": {
              borderColor: Palette["B&W/10"],
            },
            "&.MuiOutlinedInput-root:active, &.MuiOutlinedInput-root:hover": {
              backgroundColor: Palette["B&W/00"],
              fieldset: {
                borderColor: Palette["B&W/10"],
              },
            },
          },
        },
        {
          props: { error: true },
          style: {
            "&.MuiOutlinedInput-root.Mui-error fieldset, &.MuiOutlinedInput-root.Mui-error:hover fieldset":
              {
                borderColor: Palette["Error/50"],
              },
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&": {
            alignSelf: "stretch",
          },
          "& .MuiInputBase-adornedStart": {
            paddingRight: "8px",
          },
          "& .MuiInputBase-adornedStart input.MuiInputBase-inputAdornedEnd": {
            paddingLeft: "9px",
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: Palette["B&W/100"],
        fontStyle: "normal",

        variantMapping: {
          label1: "label",
          label2: "label",
          label3: "label",
          label3Thin: "label",
          label4: "label",
          label5: "label",
          body3: "p",
          body4: "p",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: Palette["B&W/100"],
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "18px",
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "cloud" },
          style: {
            "&": {
              padding: "6px",
              borderRadius: "10px",
              border: `1px solid ${Palette["B&W/40"]}`,
            },

            "& span": {
              color: Palette["B&W/60"],
              padding: "1px 5px 0 5px",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "17px",
            },

            [mobileMedia]: {
              "&": {
                padding: "4px",
                height: "30px",
              },

              "& span": {
                padding: "0px 5px",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "15px",
              },
            },
          },
        },
      ],
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          "& .MuiToggleButtonGroup-grouped, & .MuiToggleButtonGroup-grouped .Mui-disabled":
            {
              border: 0,
              height: "40px",
              width: "40px",
              padding: 0,
            },

          "& .MuiToggleButtonGroup-grouped:not(:first-of-type), & .MuiToggleButtonGroup-grouped:first-of-type":
            {
              borderRadius: "7px",
            },

          "& .MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.Mui-selected":
            {
              backgroundColor: Palette["B&W/100"],
            },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxSizing: "border-box",
          backgroundColor: Palette["B&W/05"],
          "&:last-of-type": {
            borderRadius: "25px",
          },

          padding: "7px",
          "&.Mui-expanded": {
            marginTop: "0",
          },

          [mobileMedia]: {
            padding: "3px",

            "&:last-of-type": {
              borderRadius: "20px",
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: "13px",
          paddingRight: "11px",

          minHeight: "40px",
          maxHeight: "40px",
          "&.Mui-expanded": {
            paddingLeft: "13px",
            paddingRight: "11px",

            minHeight: "40px",
            maxHeight: "40px",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "8px 13px 13px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",

          [mobileMedia]: {
            marginRight: "-9px",
            padding: "9px 0 9px 9px",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          alignSelf: "stretch",

          cursor: "pointer",
          padding: "8px",
          boxSizing: "border-box",

          borderRadius: "14px",
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: Palette["B&W/20"],

          "&:hover": {
            backgroundColor: Palette["B&W/05"],
          },

          "&:active": {
            borderColor: Palette["B&W/100"],
          },

          "&.Mui-disabled": {
            borderColor: Palette["B&W/10"],
            color: Palette["B&W/10"],
            backgroundColor: Palette["B&W/00"],

            "& .MuiTypography-root.Mui-disabled .MuiTypography-root": {
              color: Palette["B&W/10"],
            },
          },

          "&.Mui-error": {
            borderColor: Palette["Error/50"],
          },

          [mobileMedia]: {
            borderRadius: "12px",
            padding: "3px",
          },
        },
        labelPlacementStart: {
          justifyContent: "space-between",
          margin: 0,
        },
      },
    },
  },
});

theme.typography.h1 = {
  fontSize: "50px",
  fontWeight: 500,
  lineHeight: "45px",
  letterSpacing: "-1.25px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "35px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "-0.8px",
  },
};
theme.typography.h2 = {
  fontSize: "38px",
  fontWeight: 500,
  lineHeight: "38px",
  letterSpacing: "-0.95px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "29px",
    lineHeight: "29px",
    letterSpacing: "-0.7px",
  },
};
theme.typography.h3 = {
  fontSize: "28px",
  fontWeight: 500,
  lineHeight: "28px",
  letterSpacing: "-0.7px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "-0.4px",
  },
};
theme.typography.h4 = {
  fontSize: "24px",
  fontWeight: 500,
  lineHeight: "24px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "20px",
    lineHeight: "22px",
  },
};
theme.typography.h5 = {
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "20px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "16px",
    lineHeight: "17px",
  },
};
theme.typography.h6 = {
  fontSize: "15px",
  fontWeight: 500,
  lineHeight: "16px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "12px",
    lineHeight: "13px",
  },
};

theme.typography.label1 = {
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "20px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "19px",
    lineHeight: "19px",
  },
};
theme.typography.label2 = {
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "19px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "15px",
    lineHeight: "17px",
  },
};
theme.typography.label3 = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "17px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "13px",
    lineHeight: "15px",
  },
};
theme.typography.label3Thin = {
  fontSize: "16px",
  fontWeight: 200,
  lineHeight: "17px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "13px",
    lineHeight: "15px",
  },
};
theme.typography.label4 = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "17px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "12px",
    lineHeight: "14px",
  },
};
theme.typography.label5 = {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "10px",
    lineHeight: "11px",
  },
};

theme.typography.body1 = {
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "28px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "17px",
    lineHeight: "23px",
  },
};
theme.typography.body2 = {
  fontSize: "18px",
  fontWeight: 300,
  lineHeight: "22px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "16px",
    lineHeight: "20px",
  },
};
theme.typography.body3 = {
  fontSize: "16px",
  fontWeight: 300,
  lineHeight: "20px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "15px",
    lineHeight: "19px",
  },
};
theme.typography.body4 = {
  fontSize: "8px",
  fontWeight: 400,
  lineHeight: "12px",
  letterSpacing: "0.1px",

  [theme.breakpoints.between("xs", "sm")]: {
    fontSize: "7px",
    lineHeight: "11px",
  },
};
