import { BroadcastChannel } from "broadcast-channel";
export class BroadCast {
  channel: BroadcastChannel;
  constructor(name: string) {
    this.channel = new BroadcastChannel(name);
  }
  post(msg: string) {
    this.channel.postMessage(msg);
  }
  subscribe(handler: (msg: Event) => void) {
    this.channel.onmessage = handler;
  }
  close() {
    this.channel.close();
  }
}
