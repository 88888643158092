import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import { AvatarIcon, Modal } from "~components";
import Boy24 from "~assets/images/B24.png";

type CanceledModalProps = {
  open: boolean;
  onClose: () => void;
};

export const CanceledModal = ({
  open,
  onClose,
}: CanceledModalProps) => {
  return (
    <Modal open={open} onClose={onClose} autoclose>
      <Container>
        <AvatarIcon icon={Boy24} />
        <TitleContainer>
          <Typography variant="h1">Запись отменена</Typography>
        </TitleContainer>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-self: stretch;
  gap: 20px;
  flex: 1 0 0;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;
