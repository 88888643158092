import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import Boy35 from "~assets/images/B35.png";

import {
  AvatarIcon,
  Captcha,
  FieldDatePicker,
  FieldInput,
  FieldRadioCheckbox,
  FieldSelect,
  Form,
  Message,
  Modal,
  SubmitButton,
  SupportingText,
  TextLink,
  UnknownFormErrors,
} from "~components";
import { DATE_FORMAT, boldText, mobileMedia } from "~constants";
import { PhoneConfirmationModal } from "~modules/registration/PhoneConfirmationModal";
import {
  InferType,
  boolean,
  date,
  object,
  phoneValidator,
  ref,
  string,
} from "~utils/validation";
import { useState } from "react";
import { DateService } from "~services/Date";
import { useRegistration } from "./hooks";

type RegistrationFormData = InferType<typeof RegistrationSchema>;

const minDate = DateService.DateTime.now().minus({ years: 36 }).toJSDate();
const maxDate = DateService.DateTime.now().minus({ years: 14 }).toJSDate();

const RegistrationSchema = object({
  firstname: string().required(),
  lastname: string().required(),
  middlename: string(),
  email: string().email("Неверный формат почты").required(),
  gender: string().required(),
  birthday: date()
    .required()
    .min(
      minDate,
      "Ты старше 35 лет. Наши пространства могут посещать люди от 14 до 35 лет."
    )
    .max(
      maxDate,
      "Ты младше 14 лет. Наши пространства могут посещать люди от 14 до 35 лет."
    ),
  telegram: string().test(
    "telegram",
    "Неверный формат, введи свой ник без t.me/ или @",
    (v) => {
      if (!v) return true;
      return !v.includes("t.me/") && !v?.includes("@");
    }
  ),
  vkontakte: string().test(
    "vkontakte",
    "Неверный формат, введи свой ник без vk.com/",
    (v) => {
      if (!v) return true;
      return !v.includes("vk.com/");
    }
  ),
  phone: phoneValidator,
  password: string().required().min(8, "Пароль должен быть больше 8 символов"),
  confirmPassword: string()
    .required("Повтори пароль")
    .oneOf([ref("password")], "Пароли не совпадают"),
  acceptRules: boolean().test("acceptRules", "Необходимо согласие", (v) => {
    if (v !== true) return v;
    return v;
  }),
  acceptEmail: boolean(),
  captcha: string().required(),
});

export const Registration = ({
  rulesPageUrl,
  personalDataRulesPageUrl,
}: {
  rulesPageUrl: string;
  personalDataRulesPageUrl: string;
}) => {
  const { isOpenedModal, openModal } = Modal.useModal();
  const [phone, setPhone] = useState<string>();
  const { data, onRegister } = useRegistration();

  return data?.AUTHORIZED ? (
    <Message>Ты уже авторизовался</Message>
  ) : (
    <>
      <Form<RegistrationFormData>
        initialValues={{
          firstname: "",
          lastname: "",
          gender: "unknown",
          // @ts-ignore
          birthday: undefined,
          phone: "",
          email: "",
          password: "",
          confirmPassword: "",
          acceptRules: true,
          acceptEmail: true,
          captcha: "",
        }}
        onSubmit={async (userInfo) => {
          await onRegister({
            BIRTHDAY: DateService.toFormattedString(
              userInfo.birthday,
              DATE_FORMAT
            ),
            captcha_sid: data?.CAPTCHA_CODE?.captcha_sid,
            captcha_word: userInfo.captcha,
            CONFIRM_PASSWORD: userInfo.confirmPassword,
            PASSWORD: userInfo.password,
            CONSENT_PERSONALDATA: userInfo.acceptEmail,
            CONSENT_RULES: userInfo.acceptRules,
            EMAIL: userInfo.email,
            GENDER: userInfo.gender,
            LAST_NAME: userInfo.lastname,
            LOGIN: userInfo.email,
            NAME: userInfo.firstname,
            PHONE: userInfo.phone,
            SECOND_NAME: userInfo.middlename,
            TELEGRAM: userInfo.telegram,
            VK: userInfo.vkontakte,
          });
          window.location.href = location.origin;
          // Включить после добавления сервиса подтверждения телефона
          // setPhone(fd.phone);
          // openModal();
        }}
        validationSchema={RegistrationSchema}
      >
        <Container>
          <Header>
            <AvatarIcon icon={Boy35} />
            <Typography variant="h1">Регистрация</Typography>
          </Header>
          <Content>
            <FieldInput
              name="firstname"
              label="Имя*"
              placeholder="Иван"
              supportingText={
                <SupportingText>
                  <Typography variant="body3">
                    <Typography
                      variant="body3"
                      typography={boldText}
                      component="span"
                    >
                      Укажи имя, как написано в паспорте.
                    </Typography>{" "}
                    Если имя не будет совпадать, ты не сможешь посещать наши
                    пространства.
                  </Typography>
                </SupportingText>
              }
            />
            <FieldInput
              name="lastname"
              label="Фамилия*"
              placeholder="Иванов"
              supportingText={
                <SupportingText>
                  <LastNameText variant="body3">
                    <LastNameText
                      variant="body3"
                      typography={boldText}
                      // @ts-ignore
                      component="span"
                    >
                      Укажи фамилию, как написано в паспорте.
                    </LastNameText>{" "}
                    Если фамилия не будет совпадать, ты не сможешь посещать наши
                    пространства.
                  </LastNameText>
                </SupportingText>
              }
            />
            <FieldInput
              name="middlename"
              label="Отчество"
              placeholder="Иванович"
            />
            <FieldSelect
              name="gender"
              label="Пол*"
              placeholder="Женский"
              options={[
                { label: "Женский", value: "F" },
                { label: "Мужской", value: "M" },
                { label: "Не указывать", value: "unknown" },
              ]}
            />
            <FieldDatePicker
              name="birthday"
              label="Дата рождения*"
              disableFuture
              placeholder="01.01.2000"
              minDate={minDate}
              maxDate={maxDate}
            />
            <FieldInput
              label="Телефон*"
              inputMode="tel"
              name="phone"
              placeholder="+7 999 999 99 99"
              maskProps={{
                config: { mask: "+{7} 000 000 00 00" },
              }}
              // Включить после реализации сервиса подтвержения номера телефона
              // supportingText={
              //   <SupportingText>
              //     <Typography variant="body3">
              //       Номер телефона необходимо будет подтвердить
              //     </Typography>
              //   </SupportingText>
              // }
            />
            <FieldInput
              name="email"
              autoComplete="email"
              inputMode="email"
              label="Эл.почта*"
              placeholder="ivanovanov@mail.ru"
            />
            <FieldInput.Password
              name="password"
              autoComplete="new-password"
              label="Придумай пароль*"
              placeholder="••••••••••"
              supportingText={
                <SupportingText>
                  <Typography variant="body3">
                    Пароль должен содержать не менее 8 знаков, включать буквы,
                    цифры и специальные символы
                  </Typography>
                </SupportingText>
              }
            />
            <FieldInput.Password
              name="confirmPassword"
              label="Повтори пароль*"
              autoComplete="new-password"
              placeholder="••••••••••"
              required
              supportingText={
                <SupportingText>
                  <Typography variant="body3">
                    Пароль должен содержать не менее 8 знаков, включать буквы,
                    цифры и специальные символы
                  </Typography>
                </SupportingText>
              }
            />
            <FieldInput
              name="telegram"
              label="Telegram"
              placeholder="ivanivanov"
            />
            <FieldInput
              name="vkontakte"
              label="Вконтакте"
              placeholder="ivanivanov"
            />
            <FieldRadioCheckbox
              name="acceptRules"
              label={
                <TextContainer variant="body2">
                  <div>
                    Я ознакомился/лась с{" "}
                    <StyledLink href={rulesPageUrl} target="_blank">
                      Правилами молодёжного пространства «ПРОСТО»*
                    </StyledLink>{" "}
                    и согласен/на на{" "}
                    <StyledLink href={personalDataRulesPageUrl} target="_blank">
                      обработку персональных данных*
                    </StyledLink>
                  </div>
                </TextContainer>
              }
            />
            <FieldRadioCheckbox
              name="acceptEmail"
              label={
                <TextContainer variant="body2">
                  Я согласен/на на получение уведомлений на эл. почту
                </TextContainer>
              }
            />
            <CaptchaContainer>
              <Captcha src={data?.CAPTCHA_CODE?.img_src} />
              <FieldInput
                label="Введи слово с картинки"
                name="captcha"
                autoComplete="off"
              />
            </CaptchaContainer>
          </Content>
          <UnknownFormErrors />
          <SubmitButton fullWidth variant="first">
            Зарегистрироваться
          </SubmitButton>
        </Container>
      </Form>
      <PhoneConfirmationModal
        open={isOpenedModal}
        phone={phone}
        onSubmit={async (code) => {
          console.log(code);
        }}
        onResendCode={async () => {
          console.log("Reset code");
        }}
      />
    </>
  );
};

const StyledLink = styled(TextLink)`
  font-size: 18px;
  line-height: 22px;
  ${mobileMedia} {
    font-size: 16px;
    line-height: 20px;
  }
`;

const CaptchaContainer = styled.div`
  display: flex;
  align-items: flex-end;
  align-self: stretch;
  gap: 30px;

  ${mobileMedia} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Container = styled.div`
  background-color: black;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 40px;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;

  ${mobileMedia} {
    gap: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 40px;
  flex: 1 0 0;

  ${mobileMedia} {
    gap: 20px;
  }
`;

const TextContainer = styled(Typography)`
  display: flex;
  padding: 8px 0 8px 8px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  ${mobileMedia} {
    padding-left: 10px;
  }
`;

const LastNameText = styled(Typography)`
  line-height: 19px;
`;
