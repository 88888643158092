import { UserInfoBase } from "prosto-api-client";
import { useCallback, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { authApi, getAuthSettingsApi } from "~services/api/prosto-api";

const getUserInfo = () => authApi.authPersonalGet();
const updateUser = async (user: UserInfoBase) => {
  const info = await authApi.authUseraccountGet();
  return authApi.authUseraccountPost({
    userInfoBase: { ...user, SESSID: info.USER_INFO?.SESSID },
  });
};
const logoutUser = () => getAuthSettingsApi.authGet({ logout: "yes" });
const verifyCode = async (code: any) => {
  console.log("code", code);
};
type PASS = {
  confirmPassword: string;
  oldPassword: string;
  password: string;
};

const updatePassword = async (pass: PASS) => {
  const info = await authApi.authUseraccountGet();

  return authApi.authUseraccountPut({
    changePasswordModel: {
      PASSWORD: pass.oldPassword,
      NEW_PASSWORD: pass.password,
      NEW_PASSWORD_CONFIRM: pass.confirmPassword,
      SESSID: info.USER_INFO?.SESSID,
    },
  });
};

const GET_USER_INFO = "GET_USER_INFO";
export const useUserInfo = () => {
  const qc = useQueryClient();

  const { data, refetch } = useQuery(GET_USER_INFO, getUserInfo);
  const { mutateAsync: updateUserInfo } = useMutation(updateUser, {
    onSuccess: () => qc.invalidateQueries(GET_USER_INFO),
  });
  const { mutateAsync: verifyCodeInfo } = useMutation(verifyCode);
  const { mutateAsync: updatePasswordInfo } = useMutation(updatePassword);
  const { mutateAsync: logout, isLoading: isLoadingLogout } = useMutation(
    logoutUser,
    {
      onSettled: () => {
        window.location.href = location.origin;
      },
    }
  );

  return {
    info: data,
    refetch,
    isLoadingLogout,
    logout,
    updateUser: updateUserInfo,
    updatePassword: updatePasswordInfo,
    verifyCode: verifyCodeInfo,
  };
};

const init = {
  dataChanged: false,
  coworkingArchive: false,
  eventArchive: false,
  userInfo: false,
};
export const useAccountModals = () => {
  const [modalState, setState] = useState<typeof init>();

  const close = useCallback(() => setState(init), []);
  const open = useCallback((name: keyof typeof init) => {
    setState({ ...init, [name]: true });
  }, []);
  return { open, close, modalState };
};
