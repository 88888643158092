/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResponseAuthModel,
} from '../models';
import {
    ResponseAuthModelFromJSON,
    ResponseAuthModelToJSON,
} from '../models';

export interface AuthGetRequest {
    logout?: string;
}

/**
 * 
 */
export class GetAuthorizeSettingsApi extends runtime.BaseAPI {

    /**
     * Данный метод позволяет получить настройки авторизации и данные для регистрации или восстановлению пароля. Если пользователь авторизован
     * Получение данных для авторизации, регистрации и восстановлению пароля
     */
    async authGetRaw(requestParameters: AuthGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseAuthModel>> {
        const queryParameters: any = {};

        if (requestParameters.logout !== undefined) {
            queryParameters['logout'] = requestParameters.logout;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseAuthModelFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет получить настройки авторизации и данные для регистрации или восстановлению пароля. Если пользователь авторизован
     * Получение данных для авторизации, регистрации и восстановлению пароля
     */
    async authGet(requestParameters: AuthGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseAuthModel> {
        const response = await this.authGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
