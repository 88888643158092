import React, { FC, ReactNode } from "react";
import { useFormikContext } from "formik";

type DependentProps = {
  when: (values: unknown) => boolean;
  children?: ReactNode;
};

export const DependentField: FC<DependentProps> = ({ when, children }) => {
  const { values } = useFormikContext();

  const isRelevant = when(values);

  return isRelevant && children ? <>{children}</> : null;
};
