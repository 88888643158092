import styled from "@emotion/styled";

import { mobileMedia } from "~constants";
import { DateService } from "~services/Date";
import { getIsDisabledDate } from "../utils";
import { DayCell } from "./DayCell";
import {
  addWeeks,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfWeek,
  isMonday,
  previousMonday,
  startOfToday,
} from "date-fns";

type WeekCalendarProps = {
  date: Date;
  setDate: (date: Date) => void;
  defaultSelected: Date;
  appointmentDates?: Date[];
  enabledDates?: Date[];
};

export const WeekCalendar = ({
  date,
  setDate,
  defaultSelected,
  appointmentDates,
  enabledDates,
}: WeekCalendarProps) => {
  let defaultMonth = defaultSelected;

  const weekNumber = DateService.getWeekNumber(defaultSelected);
  const lastMonthWeekNumber =
    DateService.DateTime.fromJSDate(defaultSelected).endOf("month").weekNumber;

  if (weekNumber === lastMonthWeekNumber) {
    defaultMonth = new Date(
      defaultSelected.getFullYear(),
      defaultSelected.getMonth() + 1
    );
  }

  const hasAppointment = (date: Date) =>
    !!appointmentDates?.find((d) => DateService.isEqualDates(date, d));
  const weeks = calculateWeeks();
  return (
    <DayInWeekPiker>
      <tbody>
        {weeks.map((w, i) => (
          <tr key={i}>
            {w.map((propsDate, i) => (
              <td key={i}>
                <DayCell
                  date={propsDate}
                  disabled={getIsDisabledDate(propsDate, enabledDates)}
                  selected={date}
                  onSelect={setDate}
                  hasAppointment={hasAppointment(propsDate)}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </DayInWeekPiker>
  );
};

const DayInWeekPiker = styled.table`
  width: 100%;
  border-spacing: 10px 0;
  border-collapse: collapse;

  td {
    border: 0 solid transparent;
    background-clip: padding-box;
  }

  tr > td + td {
    border-left-width: 10px;
  }

  tr + tr > td {
    border-top-width: 10px;
  }

  ${mobileMedia} {
    border-spacing: 1px 0;

    tr > td + td {
      border-left-width: 1px;
    }

    tr + tr > td {
      border-top-width: 1px;
    }
  }
`;

const calculateWeeks = () => {
  const start = isMonday(startOfToday())
    ? startOfToday()
    : previousMonday(startOfToday());
  const mondays = eachWeekOfInterval(
    {
      start,
      end: addWeeks(start, 1),
    },
    {
      locale: { code: "ru" },
      weekStartsOn: 1,
    }
  );
  const days = eachDayOfInterval({
    start: mondays[0],
    end: endOfWeek(mondays[1], {
      locale: { code: "ru" },
      weekStartsOn: 1,
    }),
  });

  const firstWeek = days.slice(0, 7);
  const secondWeek = days.slice(7);
  return [firstWeek, secondWeek];
};
