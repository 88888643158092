/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Данный объект представляет данные пользователя для ЛК
 * @export
 * @interface UserInfoBase
 */
export interface UserInfoBase {
    /**
     * Имя
     * @type {string}
     * @memberof UserInfoBase
     */
    NAME?: string;
    /**
     * Фамилия
     * @type {string}
     * @memberof UserInfoBase
     */
    LAST_NAME?: string;
    /**
     * Отчество
     * @type {string}
     * @memberof UserInfoBase
     */
    SECOND_NAME?: string;
    /**
     * Ссылка на аватарку
     * @type {string}
     * @memberof UserInfoBase
     */
    URL_PHOTO?: string;
    /**
     * Дата рождения
     * @type {string}
     * @memberof UserInfoBase
     */
    BIRTHDAY?: string;
    /**
     * Номер телефона
     * @type {string}
     * @memberof UserInfoBase
     */
    PHONE?: string;
    /**
     * Логин
     * @type {string}
     * @memberof UserInfoBase
     */
    LOGIN?: string;
    /**
     * Почта
     * @type {string}
     * @memberof UserInfoBase
     */
    EMAIL?: string;
    /**
     * Пол. Возможны два значения F или M
     * @type {string}
     * @memberof UserInfoBase
     */
    GENDER?: string;
    /**
     * Псевдоним для telegram
     * @type {string}
     * @memberof UserInfoBase
     */
    TELEGRAM?: string;
    /**
     * Псевдоним для VK
     * @type {string}
     * @memberof UserInfoBase
     */
    VK?: string;
    /**
     * Указывает, верифицирован ли пользователь. Если пользователь не верифицирован, то необходимо отобразить QRкод из поля QR_VERIFICATION
     * @type {boolean}
     * @memberof UserInfoBase
     */
    VERIFIED?: boolean;
    /**
     * идентификатор сессии пользователя. Требуется для изменения данных пользователя
     * @type {string}
     * @memberof UserInfoBase
     */
    SESSID?: string;
}

/**
 * Check if a given object implements the UserInfoBase interface.
 */
export function instanceOfUserInfoBase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserInfoBaseFromJSON(json: any): UserInfoBase {
    return UserInfoBaseFromJSONTyped(json, false);
}

export function UserInfoBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfoBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'NAME': !exists(json, 'NAME') ? undefined : json['NAME'],
        'LAST_NAME': !exists(json, 'LAST_NAME') ? undefined : json['LAST_NAME'],
        'SECOND_NAME': !exists(json, 'SECOND_NAME') ? undefined : json['SECOND_NAME'],
        'URL_PHOTO': !exists(json, 'URL_PHOTO') ? undefined : json['URL_PHOTO'],
        'BIRTHDAY': !exists(json, 'BIRTHDAY') ? undefined : json['BIRTHDAY'],
        'PHONE': !exists(json, 'PHONE') ? undefined : json['PHONE'],
        'LOGIN': !exists(json, 'LOGIN') ? undefined : json['LOGIN'],
        'EMAIL': !exists(json, 'EMAIL') ? undefined : json['EMAIL'],
        'GENDER': !exists(json, 'GENDER') ? undefined : json['GENDER'],
        'TELEGRAM': !exists(json, 'TELEGRAM') ? undefined : json['TELEGRAM'],
        'VK': !exists(json, 'VK') ? undefined : json['VK'],
        'VERIFIED': !exists(json, 'VERIFIED') ? undefined : json['VERIFIED'],
        'SESSID': !exists(json, 'SESSID') ? undefined : json['SESSID'],
    };
}

export function UserInfoBaseToJSON(value?: UserInfoBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'NAME': value.NAME,
        'LAST_NAME': value.LAST_NAME,
        'SECOND_NAME': value.SECOND_NAME,
        'URL_PHOTO': value.URL_PHOTO,
        'BIRTHDAY': value.BIRTHDAY,
        'PHONE': value.PHONE,
        'LOGIN': value.LOGIN,
        'EMAIL': value.EMAIL,
        'GENDER': value.GENDER,
        'TELEGRAM': value.TELEGRAM,
        'VK': value.VK,
        'VERIFIED': value.VERIFIED,
        'SESSID': value.SESSID,
    };
}

