import { TextField as MuiTextField } from "@mui/material";
import { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField/TextField";

import { FieldContainer, FieldContainerProps } from "./FieldContainer";

export type TextFieldProps = Omit<MuiTextFieldProps, "label"> &
  Pick<FieldContainerProps, "label" | "helperText" | "supportingText">;

export const TextField = ({
  label,
  helperText,
  className,
  supportingText,
  ...rest
}: TextFieldProps) => {
  return (
    <FieldContainer
      label={label}
      helperText={helperText}
      className={className}
      supportingText={supportingText}
    >
      <MuiTextField InputLabelProps={{ shrink: false }} {...rest} />
    </FieldContainer>
  );
};
