import styled from "@emotion/styled";
import { Typography, ModalProps as MuiModalProps } from "@mui/material";
import * as React from "react";

import Boy43 from "~assets/images/B43.png";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import { Button, ButtonIcon, AvatarIcon, Modal } from "~components";

type ConfirmModalProps = {
  open: boolean;
  muiModalProps?: Omit<MuiModalProps, "open" | "children">;
  onClose: () => void;
  onCancel: () => void;
};

export const ConfirmModal = ({
  open,
  muiModalProps,
  onClose,
  onCancel,
}: ConfirmModalProps) => {
  return (
    <Modal open={open} onClose={onClose} muiModalProps={muiModalProps}>
      <Container>
        <Header>
          <Info>
            <AvatarIcon icon={Boy43} />
            <TitleContainer>
              <Typography variant="h1">Отменить запись</Typography>
            </TitleContainer>
          </Info>
          <ButtonIcon Icon={Cross} onClick={onClose} />
        </Header>
        <Buttons>
          <OptionButton variant="danger" onClick={onCancel}>
            Отменить запись
          </OptionButton>
          <OptionButton variant="second" onClick={onClose}>
            Не отменять
          </OptionButton>
        </Buttons>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 5px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const OptionButton = styled(Button)`
  width: 100%;
`;
