import { Field, FieldProps } from "formik";
import { ComponentProps, ReactNode } from "react";

type FormItemProps = {
  name: string;
  children: (fieldProps: FieldProps<unknown>) => ReactNode;
} & Omit<ComponentProps<typeof Field>, "children">;

export const FormItem = ({ children, ...rest }: FormItemProps) => (
  <Field {...rest}>{children}</Field>
);
