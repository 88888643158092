import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { isEqual } from "lodash";

import Boy30 from "~assets/images/B30.png";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import {
  AvatarIcon,
  ButtonIcon,
  FieldInput,
  Form,
  Modal,
  SubmitButton,
  DataUpdateSuccessfulModal,
  SupportingText,
  UnknownFormErrors,
  Captcha,
} from "~components";
import { mobileMedia } from "~constants";
import { LeaveConfirmationModal } from "~modules/user-account/LeaveConfirmationModal";
import { Palette } from "~utils/Palette";
import { InferType, object, ref, string } from "~utils/validation";
import { useChangePassword } from "./hooks";
import { useQueryParams } from "~utils/useQueryParams";

type ChangePasswordFormData = InferType<
  ReturnType<typeof getPasswordSchema>
> & { captcha?: string };

const getPasswordSchema = (isNeedCaptcha: boolean) =>
  isNeedCaptcha
    ? object({
        captcha: string().required(),
        password: string()
          .required()
          .min(8, "Пароль должен быть больше 8 символов"),
        confirmPassword: string()
          .required("Повтори пароль")
          .oneOf([ref("password")], "Пароли не совпадают"),
      })
    : object({
        password: string()
          .required()
          .min(8, "Пароль должен быть больше 8 символов"),
        confirmPassword: string()
          .required("Повтори пароль")
          .oneOf([ref("password")], "Пароли не совпадают"),
      });

export const PasswordChange = ({
  successPageUrl,
}: {
  successPageUrl?: string;
}) => {
  const {
    closeModal: closeSuccess,
    isOpenedModal: isOpenSuccess,
    openModal: openSuccess,
  } = Modal.useModal();
  const {
    closeModal: closeLeave,
    isOpenedModal: isOpenLeave,
    openModal: openLeave,
  } = Modal.useModal();

  const { getValue } = useQueryParams();
  const params = {
    USER_CHECKWORD: getValue("USER_CHECKWORD"),
    USER_LOGIN: getValue("USER_LOGIN"),
  };
  const { changePassword, settings } = useChangePassword();
  const isNeedCaptcha = !!settings?.CAPTCHA_CODE?.img_src;

  return (
    <>
      <StyledForm
        initialValues={{ confirmPassword: "", password: "" }}
        validationSchema={getPasswordSchema(isNeedCaptcha)}
        // @ts-ignore
        onSubmit={async (d: ChangePasswordFormData) => {
          await changePassword({
            captcha_sid: settings?.CAPTCHA_CODE?.captcha_sid,
            captcha_word: d.captcha,
            USER_CHECKWORD: params.USER_CHECKWORD!,
            USER_LOGIN: params.USER_LOGIN!,
            USER_PASSWORD: d.password,
            USER_CONFIRM_PASSWORD: d.confirmPassword,
            TYPE: "CHANGE_PWD",
          });
          openSuccess();
          if (successPageUrl) {
            window.location.href = successPageUrl;
          }
        }}
      >
        {({ values, initialValues }) => (
          <>
            <Header>
              <Info>
                <AvatarIcon icon={Boy30} />
                <TitleContainer>
                  <Typography variant="h1">Изменение пароля</Typography>
                </TitleContainer>
              </Info>
              {/* <ButtonIcon
                Icon={Cross}
                onClick={() => {
                  const wasTouched = !isEqual(values, initialValues);
                  if (wasTouched) {
                    openLeave();
                  }
                }}
              /> */}
            </Header>
            <ContentContainer>
              <div>
                <FieldInput.Password
                  label="Введи новый пароль"
                  name="password"
                  placeholder="••••••••••"
                  autoComplete="new-password"
                  supportingText={
                    <SupportingText>
                      <Typography variant="body3">
                        Пароль должен быть больше 8 символов
                      </Typography>
                    </SupportingText>
                  }
                />
              </div>
              <FieldInput.Password
                label="Повтори новый пароль"
                name="confirmPassword"
                placeholder="••••••••••"
              />
              {isNeedCaptcha && (
                <>
                  <Captcha src={settings?.CAPTCHA_CODE?.img_src} />
                  <FieldInput label="Введи слово с картинки" name="captcha" />
                </>
              )}
            </ContentContainer>
            <UnknownFormErrors />
            <SubmitButton fullWidth variant="first">
              Изменить пароль
            </SubmitButton>
          </>
        )}
      </StyledForm>
      <LeaveConfirmationModal
        open={isOpenLeave}
        onClose={closeLeave}
        onLeave={closeLeave}
      />
      <DataUpdateSuccessfulModal onClose={closeSuccess} open={isOpenSuccess} />
    </>
  );
};
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;

  ${mobileMedia} {
    gap: 30px;
  }
`;

const StyledForm = styled(Form)`
  background: ${Palette["B&W/00"]};

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  margin-bottom: 40px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;

  ${mobileMedia} {
    gap: 20px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;
