/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PlaceMenuItem } from './PlaceMenuItem';
import {
    PlaceMenuItemFromJSON,
    PlaceMenuItemFromJSONTyped,
    PlaceMenuItemToJSON,
} from './PlaceMenuItem';

/**
 * 
 * @export
 * @interface PlaceMenu
 */
export interface PlaceMenu {
    /**
     * идентификатор выбранного пункта меню
     * @type {string}
     * @memberof PlaceMenu
     */
    selected_id?: string;
    /**
     * Ассоциативный массив. Ключом является идентификатор коворкинга, а значанием является объект представления пункта меню
     * @type {{ [key: string]: PlaceMenuItem; }}
     * @memberof PlaceMenu
     */
    places?: { [key: string]: PlaceMenuItem; };
}

/**
 * Check if a given object implements the PlaceMenu interface.
 */
export function instanceOfPlaceMenu(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlaceMenuFromJSON(json: any): PlaceMenu {
    return PlaceMenuFromJSONTyped(json, false);
}

export function PlaceMenuFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceMenu {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selected_id': !exists(json, 'selected_id') ? undefined : json['selected_id'],
        'places': !exists(json, 'places') ? undefined : (mapValues(json['places'], PlaceMenuItemFromJSON)),
    };
}

export function PlaceMenuToJSON(value?: PlaceMenu | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selected_id': value.selected_id,
        'places': value.places === undefined ? undefined : (mapValues(value.places, PlaceMenuItemToJSON)),
    };
}

