import React, { Fragment } from "react";
import {
  FormikConfig,
  useFormikContext,
  FormikHelpers,
  Formik,
  Form as FormikForm,
} from "formik";
import { isEmpty, isFunction, omit } from "lodash";
import { FormControl } from "./FormControl";
import { Typography } from "@mui/material";
import { Message } from "~components";
import styled from "@emotion/styled";

export type FormProps<T> = {
  onChange?: (values: T) => void;
  onSubmit?: FormikConfig<T>["onSubmit"];
  initialValues: FormikConfig<T>["initialValues"];
  validationSchema?: FormikConfig<T>["validationSchema"];
  formConfig?: Omit<
    FormikConfig<T>,
    "onSubmit" | "children" | "validationSchema" | "initialValues"
  >;
  children?: FormikConfig<T>["children"];
  id?: string;
  className?: string;
};

export const Form = <T extends {}>({
  onSubmit,
  children,
  formConfig,
  validationSchema,
  initialValues,
  className,
  id,
  onChange,
  ...rest
}: FormProps<T>) => {
  const formProps = {
    ...formConfig,
    validationSchema,
    initialValues,
  };
  return (
    <Formik
      enableReinitialize
      {...formProps}
      {...rest}
      onSubmit={onSubmit ? makeSubmitHandler(onSubmit) : () => {}}
    >
      {(formikProps) => {
        return (
          <FormikForm className={className} id={id}>
            <FormControl
              formikProps={formikProps}
              onChangeSubscriber={onChange}
            />
            {isFunction(children) ? children(formikProps) : children}
          </FormikForm>
        );
      }}
    </Formik>
  );
};

function makeSubmitHandler<V>(
  onSubmit: (values: V, methods: FormikHelpers<V>) => void
) {
  return async function handleSubmit(values: V, methods: FormikHelpers<V>) {
    try {
      await onSubmit(values, methods);
    } catch (ex: any) {
      const errs = {};
      if (ex?.ERROR_TYPE) {
        // @ts-ignore
        errs[`server`] = ex.MESSAGE;
      }
      if (ex?.ERRORS?.length) {
        (ex?.ERRORS as string[])?.forEach((e, i) => {
          // @ts-ignore
          errs[`server-${i}`] = e;
        });
      }
      methods.setErrors(errs);
    } finally {
      methods.setSubmitting(false);
    }
  };
}

export const UnknownFormErrors = ({ className }: { className?: string }) => {
  const { errors, values = {} } = useFormikContext();

  if (isEmpty(errors)) return null;
  // @ts-ignore
  const notFieldErrors = omit(errors, Object.keys(values));

  if (isEmpty(notFieldErrors)) return null;

  return (
    <ErrorsContainer className={className}>
      <Message color="alert">
        <ErrorsList>
          {Object.entries(notFieldErrors).map(([k, v]) => (
            <Fragment key={k}>
              {/* @ts-ignore */}
              <Typography variant="body3">{v}</Typography>
            </Fragment>
          ))}
        </ErrorsList>
      </Message>
    </ErrorsContainer>
  );
};

const ErrorsContainer = styled.div`
  width: 100%;
`;

const ErrorsList = styled.div``;
