import styled from "@emotion/styled";
import { Portal, Typography } from "@mui/material";
import { Button, ButtonIcon, Modal } from "~components";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import { DateService } from "~services/Date";
import { SpeakerProps, Speakers } from "./Speakers";
import {
  DATE_TIME_FORMAT,
  desktopMedia,
  mobileMedia,
  tabletMedia,
} from "~constants";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { useEventById } from "./hooks";
import { Palette } from "~utils/Palette";
import { ReactComponent as Map } from "~assets/svg/map.svg";
import { PlaceMenuItem } from "prosto-api-client";

type EventInfoProps = {
  eventId: number;
  open: boolean;
  place?: PlaceMenuItem;
  onClose: () => void;
  isLoadingRegister?: boolean;
  onRegister: () => void;
  onCancel: () => void;
};
export const EventInfo = ({
  open,
  eventId,
  isLoadingRegister,
  place,
  onClose,
  onRegister,
  onCancel,
}: EventInfoProps) => {
  const { data } = useEventById(eventId);
  const canRegister = Number(data?.MEMBER_COUNT) > 0;
  return (
    <ModalStyled
      open={open}
      onClose={onClose}
      muiModalProps={{
        sx: {
          // z-index у кнопки наверх в тильде 99 999
          zIndex: 100000,
        },
      }}
    >
      <ModalBody
        registered={data?.REGISTERED}
        isLoadingRegister={isLoadingRegister}
        title={data?.EVENT_NAME}
        content={data?.DESCRIPTION}
        datetime={
          data?.EVENT_DATE
            ? DateService.parse(data.EVENT_DATE!, DATE_TIME_FORMAT).toJSDate()
            : undefined
        }
        onRegister={canRegister ? onRegister : undefined}
        onCancel={onCancel}
        place={place}
        speakers={
          data?.SPEAKERMANS &&
          Object.values(data?.SPEAKERMANS).map((s) => ({
            name: s.NAME!,
            description: s.REGALIA!,
            imgUrl: s.FILE,
          }))
        }
        onClose={onClose}
      />
    </ModalStyled>
  );
};
type ModalBodyProps = {
  title?: string;
  content?: string;
  datetime?: Date;
  place?: PlaceMenuItem;
  speakers?: SpeakerProps[];
  registered?: boolean;
  onClose: () => void;
  onCancel: () => void;
  isLoadingRegister?: boolean;
  onRegister?: () => void;
};
const ModalBody = ({
  content = "",
  datetime,
  place,
  title = "",
  isLoadingRegister,
  speakers,
  registered,
  onRegister,
  onClose,
  onCancel,
}: ModalBodyProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hasVerticalScroll, setHasVerticalScroll] = useState(false);
  useLayoutEffect(() => {
    if (ref.current) {
      const hasVerticalScrollbar =
        ref?.current?.scrollHeight! > ref?.current?.clientHeight!;
      setHasVerticalScroll(hasVerticalScrollbar);
    }
  }, [ref.current, title]);

  return (
    <Container ref={ref}>
      <Header>
        <Typography variant="h1">
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Typography>
        <ButtonIcon Icon={Cross} onClick={onClose} />
      </Header>
      <Info>
        <Date>
          <CellInfo>
            <Typography variant="label3Thin">Дата</Typography>
            <Typography variant="h3">
              {datetime &&
                DateService.toFormattedString(datetime, "dd MMM").slice(0, 6)}
            </Typography>
          </CellInfo>
          <CellInfo>
            <Typography variant="label3Thin">Время</Typography>
            <Typography variant="h3">
              {datetime && DateService.toFormattedString(datetime, "HH:mm")}
            </Typography>
          </CellInfo>
        </Date>
        <CellInfo fullWidth>
          <Typography variant="label3Thin">Точка</Typography>
          <Title variant="h3">
            {place?.name}
            {place?.address_ya_map && (
              <ButtonIconStyled
                Icon={Map}
                onClick={() => {
                  window.open(place?.address_ya_map);
                }}
              />
            )}
          </Title>
        </CellInfo>
        <GeoInfo>
          {place?.address_ya_map && (
            <ButtonIcon
              Icon={Map}
              onClick={() => {
                window.open(place?.address_ya_map);
              }}
            />
          )}
        </GeoInfo>
      </Info>
      <Content dangerouslySetInnerHTML={{ __html: content }} />
      <Speakers speakers={speakers} />
      {hasVerticalScroll && <PlaceholderForButton />}
      <RegisterButton isFixed={hasVerticalScroll}>
        <ButtonStyled
          isFixed={hasVerticalScroll}
          fullWidth
          variant={registered ? "second" : "first"}
          loading={isLoadingRegister}
          disabled={registered ? false : !onRegister}
          onClick={registered ? onCancel : onRegister}
        >
          {registered
            ? "Отменить"
            : onRegister
            ? "Зарегистрироваться"
            : "Мест нет"}
        </ButtonStyled>
      </RegisterButton>
    </Container>
  );
};

const PlaceholderForButton = styled.div`
  min-height: 100px;
  width: 100%;
  ${tabletMedia} {
    min-height: 65px;
  }
  ${mobileMedia} {
    min-height: 85px;
  }
`;

const Title = styled(Typography)`
  display: flex;
  width: 100%;
`;
const ButtonIconStyled = styled(ButtonIcon)`
  margin-top: -20px;
  margin-left: auto;

  ${desktopMedia} {
    display: none;
  }
`;

const RegisterButton = ({
  children,
  isFixed,
}: {
  isFixed?: boolean;
  children: ReactNode;
}) => (isFixed ? <Portal>{children}</Portal> : <>{children}</>);

const ModalStyled = styled(Modal)`
  max-width: 880px;
  max-height: calc(100vh - 100px);
  ${tabletMedia} {
    padding: 30px;
    max-width: calc(100% - 20px);
  }
  ${mobileMedia} {
    padding: 20px;
    max-width: calc(100% - 20px);
    max-height: calc(100vh - 60px);
  }
`;

const GeoInfo = styled.div`
  display: none;
  align-self: center;
  margin-left: -30px;
  ${desktopMedia} {
    display: block;
  }
`;

const Content = styled(Typography)`
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: 135.294%;
  margin-bottom: 50px;
  & a {
    color: ${Palette["B&W/100"]} !important;
  }
`;

const CellInfo = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  ${desktopMedia} {
    width: auto;
  }
`;

const Date = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  ${desktopMedia} {
    width: auto;
  }
  ${tabletMedia} {
    order: 1;
  }
  ${mobileMedia} {
    order: 1;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 5px;
  margin-bottom: 50px;
  justify-content: space-between;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 60px;
  margin-bottom: 80px;
  width: 100%;
  ${tabletMedia} {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 80px;
  }
  ${mobileMedia} {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 60px;
  }
`;

const ButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isFixed",
})<{
  isFixed?: boolean;
}>`
  position: ${({ isFixed }) => (isFixed ? "fixed" : "static")};
  margin: 10px auto;
  min-height: 50px;
  z-index: 100000;
  bottom: 80px;
  left: 0;
  right: 0;
  max-width: ${({ isFixed }) => (isFixed ? "820px" : "100%")};
  ${tabletMedia} {
    max-width: ${({ isFixed }) => (isFixed ? "calc(100% - 80px)" : "100%")};
  }
  ${mobileMedia} {
    max-width: ${({ isFixed }) => (isFixed ? "calc(100% - 60px)" : "100%")};
    bottom: 50px;
  }
`;
