/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PlaceMenuItemMetroStationsValue } from './PlaceMenuItemMetroStationsValue';
import {
    PlaceMenuItemMetroStationsValueFromJSON,
    PlaceMenuItemMetroStationsValueFromJSONTyped,
    PlaceMenuItemMetroStationsValueToJSON,
} from './PlaceMenuItemMetroStationsValue';

/**
 * 
 * @export
 * @interface PlaceMenuItem
 */
export interface PlaceMenuItem {
    /**
     * Малая картинка Относительный адрес картинки коворкинга
     * @type {string}
     * @memberof PlaceMenuItem
     */
    img_preview?: string;
    /**
     * Большая картинка Относительный адрес картинки коворкинга
     * @type {string}
     * @memberof PlaceMenuItem
     */
    img_detail?: string;
    /**
     * Название адрес коворкинга
     * @type {string}
     * @memberof PlaceMenuItem
     */
    name?: string;
    /**
     * Ссылка на Yandex map на адрес коворкинга
     * @type {string}
     * @memberof PlaceMenuItem
     */
    address_ya_map?: string;
    /**
     * Список ближайших станций метро
     * @type {{ [key: string]: PlaceMenuItemMetroStationsValue; }}
     * @memberof PlaceMenuItem
     */
    metro_stations?: { [key: string]: PlaceMenuItemMetroStationsValue; };
    /**
     * Указывает закрыта ли регистрация на коворкинг по данному адресу. Если true, то коворкинг закрыт на регистрацию
     * @type {boolean}
     * @memberof PlaceMenuItem
     */
    coworking_reg_disabled?: boolean;
    /**
     * Осталось мало мест
     * @type {boolean}
     * @memberof PlaceMenuItem
     */
    coworking_little_space_left?: boolean;
}

/**
 * Check if a given object implements the PlaceMenuItem interface.
 */
export function instanceOfPlaceMenuItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlaceMenuItemFromJSON(json: any): PlaceMenuItem {
    return PlaceMenuItemFromJSONTyped(json, false);
}

export function PlaceMenuItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaceMenuItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'img_preview': !exists(json, 'img_preview') ? undefined : json['img_preview'],
        'img_detail': !exists(json, 'img_detail') ? undefined : json['img_detail'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address_ya_map': !exists(json, 'address_ya_map') ? undefined : json['address_ya_map'],
        'metro_stations': !exists(json, 'metro_stations') ? undefined : (mapValues(json['metro_stations'], PlaceMenuItemMetroStationsValueFromJSON)),
        'coworking_reg_disabled': !exists(json, 'coworking_reg_disabled') ? undefined : json['coworking_reg_disabled'],
        'coworking_little_space_left': !exists(json, 'coworking_little_space_left') ? undefined : json['coworking_little_space_left'],
    };
}

export function PlaceMenuItemToJSON(value?: PlaceMenuItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'img_preview': value.img_preview,
        'img_detail': value.img_detail,
        'name': value.name,
        'address_ya_map': value.address_ya_map,
        'metro_stations': value.metro_stations === undefined ? undefined : (mapValues(value.metro_stations, PlaceMenuItemMetroStationsValueToJSON)),
        'coworking_reg_disabled': value.coworking_reg_disabled,
        'coworking_little_space_left': value.coworking_little_space_left,
    };
}

