import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { AvatarIcon } from "~components";
import SpeakerPlaceholder from "~assets/images/B30.png";
import { mobileMedia } from "~constants";

export const Speakers = ({ speakers = [] }: { speakers?: SpeakerProps[] }) =>
  !speakers?.length ? null : (
    <>
      <Title variant="label1">
        {speakers?.length > 1 ? "Спикеры" : "Спикер"}
      </Title>
      <SpeakersContainer>
        {speakers?.map((s) => (
          <Speaker key={s.name} {...s} />
        ))}
      </SpeakersContainer>
    </>
  );

export type SpeakerProps = {
  name: string;
  description: string;
  imgUrl?: string;
};
const Speaker = ({ name, description, imgUrl }: SpeakerProps) => (
  <SpeakerContainer>
    <SpeakerIcon icon={imgUrl || SpeakerPlaceholder} />
    <SpeakerInfo>
      <Typography variant="h3">{name}</Typography>
      <Typography variant="body2">{description}</Typography>
    </SpeakerInfo>
  </SpeakerContainer>
);

const Title = styled(Typography)`
  margin-bottom: 60px;
  ${mobileMedia} {
    margin-bottom: 30px;
  }
`;

const SpeakersContainer = styled.div`
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  gap: 60px 40px;
  ${mobileMedia} {
    margin-top: 0px;
    gap: 40px;
    flex-direction: column;
  }
`;

const SpeakerIcon = styled(AvatarIcon)`
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
`;

const SpeakerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex: 1 0 240px;
  ${mobileMedia} {
    flex-direction: column;
    flex: 1 0 auto;
    gap: 15px;
  }
`;

const SpeakerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
