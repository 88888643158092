/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaptchaCodeModel } from './CaptchaCodeModel';
import {
    CaptchaCodeModelFromJSON,
    CaptchaCodeModelFromJSONTyped,
    CaptchaCodeModelToJSON,
} from './CaptchaCodeModel';

/**
 * Ответ для отображения формы восстановления пароля
 * @export
 * @interface ForgetPassResponse
 */
export interface ForgetPassResponse {
    /**
     * Флаг успешной авторизации
     * @type {boolean}
     * @memberof ForgetPassResponse
     */
    AUTHORIZED?: boolean;
    /**
     * 
     * @type {CaptchaCodeModel}
     * @memberof ForgetPassResponse
     */
    CAPTCHA_CODE?: CaptchaCodeModel;
    /**
     * Сообщение от API системы (при наличии)
     * @type {string}
     * @memberof ForgetPassResponse
     */
    MESSAGE?: string;
    /**
     * Уровень ошибки
     * @type {string}
     * @memberof ForgetPassResponse
     */
    TYPE?: string;
    /**
     * Тип ошибки
     * @type {string}
     * @memberof ForgetPassResponse
     */
    ERROR_TYPE?: string;
    /**
     * Список ошибок
     * @type {Array<string>}
     * @memberof ForgetPassResponse
     */
    ERRORS?: Array<string>;
}

/**
 * Check if a given object implements the ForgetPassResponse interface.
 */
export function instanceOfForgetPassResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ForgetPassResponseFromJSON(json: any): ForgetPassResponse {
    return ForgetPassResponseFromJSONTyped(json, false);
}

export function ForgetPassResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForgetPassResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AUTHORIZED': !exists(json, 'AUTHORIZED') ? undefined : json['AUTHORIZED'],
        'CAPTCHA_CODE': !exists(json, 'CAPTCHA_CODE') ? undefined : CaptchaCodeModelFromJSON(json['CAPTCHA_CODE']),
        'MESSAGE': !exists(json, 'MESSAGE') ? undefined : json['MESSAGE'],
        'TYPE': !exists(json, 'TYPE') ? undefined : json['TYPE'],
        'ERROR_TYPE': !exists(json, 'ERROR_TYPE') ? undefined : json['ERROR_TYPE'],
        'ERRORS': !exists(json, 'ERRORS') ? undefined : json['ERRORS'],
    };
}

export function ForgetPassResponseToJSON(value?: ForgetPassResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AUTHORIZED': value.AUTHORIZED,
        'CAPTCHA_CODE': CaptchaCodeModelToJSON(value.CAPTCHA_CODE),
        'MESSAGE': value.MESSAGE,
        'TYPE': value.TYPE,
        'ERROR_TYPE': value.ERROR_TYPE,
        'ERRORS': value.ERRORS,
    };
}

