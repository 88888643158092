export const useQueryParams = () => {
  const searchParams = new URLSearchParams(location.search);
  const setQueryParam = (params?: { name: string; value: string }) => {
    if (params) {
      const url = location.pathname;
      if (!params.value) {
        searchParams.delete(params.name);
      } else {
        searchParams.set(params.name, params.value);
      }
      const isEmpty = searchParams.toString() === "";
      window.history.pushState(
        {},
        "",
        isEmpty ? url : url + "?" + searchParams.toString()
      );
    } else {
      window.history.pushState({}, "", location.pathname);
    }
  };

  return {
    setQueryParam,
    getValue: (queryParamName: string) => searchParams.get(queryParamName),
  };
};
