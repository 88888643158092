import { DateService } from "~services/Date";

export const countWeeks = (date: Date) => {
  let day = new Date(date.getFullYear(), date.getMonth(), 1).getDay() || 6;
  day--;
  let diff = 7 - day;

  const lastOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const lastDate = lastOfMonth.getDate();
  if (lastOfMonth.getDay() === 1) {
    diff--;
  }

  return Math.ceil((lastDate - diff) / 7) + 1;
};

export const getIsDisabledDate = (
  date: Date,
  enabledDates?: Date[]
): boolean => {
  if (!enabledDates) return false;

  return !enabledDates.some((d) => DateService.isEqualDates(date, d));
};
