import styled from "@emotion/styled";
import { Checkbox, FormControlLabel } from "@mui/material";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";

import { ReactComponent as FullFillCircle } from "~assets/svg/fullfill_circle.svg";
import { ReactComponent as OutlineCircle } from "~assets/svg/outline_circle.svg";
import { Palette } from "~utils/Palette";

export type RadioCheckProps = {
  label: ReactNode;
  error?: string;
  touched?: boolean;
  checkSupportingText?: ReactNode;
  uncheckSupportingText?: ReactNode;
  defaultChecked?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
};

export const RadioCheck = ({
  label,
  checkSupportingText,
  uncheckSupportingText,
  defaultChecked = false,
  disabled = false,
  onChange,
  className,
  error,
}: RadioCheckProps) => {
  const [checked, setChecked] = useState(defaultChecked);
  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);
  return (
    <Container className={className}>
      <StyledFormControlLabel
        hasError={Boolean(error)}
        label={label}
        // @ts-ignore
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onChange?.(e.target.checked);
          setChecked(e.target.checked);
        }}
        disabled={disabled}
        labelPlacement="start"
        control={
          <LockerCheckbox
            checked={checked}
            icon={
              <OutlineCircle
                fill={disabled ? Palette["B&W/10"] : Palette["B&W/100"]}
              />
            }
            checkedIcon={
              <FullFillCircle
                fill={disabled ? Palette["B&W/10"] : Palette["B&W/100"]}
              />
            }
            disableRipple
          />
        }
      />
      {!checked && checkSupportingText && (
        <SupportingTextContainer>{checkSupportingText}</SupportingTextContainer>
      )}
      {checked && uncheckSupportingText && (
        <SupportingTextContainer>
          {uncheckSupportingText}
        </SupportingTextContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  gap: 10px;

  border-radius: 12px;
`;

const StyledFormControlLabel = styled(FormControlLabel)<{ hasError?: boolean }>`
  border: ${({ hasError }) =>
    hasError
      ? `2px solid ${Palette["Error/50"]}`
      : `2px solid ${Palette["B&W/20"]}`};
`;

const LockerCheckbox = styled(Checkbox)`
  width: 40px;
  height: 40px;
`;

const SupportingTextContainer = styled.div`
  padding-left: 15px;
`;
