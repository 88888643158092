import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import Boy30 from "~assets/images/B30.png";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import {
  AvatarIcon,
  Button,
  ButtonIcon,
  Captcha,
  FieldInput,
  Form,
  Message,
  Modal,
  SubmitButton,
  UnknownFormErrors,
} from "~components";
import { mobileMedia } from "~constants";
import { InferType, object, string } from "~utils/validation";
import { EmailSendSuccessfulModal } from "./EmailSendSuccessfulModal";
import { Palette } from "~utils/Palette";
import { useForgetPassword } from "./hooks";

type ResetPasswordFormData = InferType<ReturnType<typeof getEmailInfoSchema>>;

const getEmailInfoSchema = (isNeedCaptcha: boolean) => {
  return isNeedCaptcha
    ? object({
        email: string().email(),
        captcha: string().required(),
      })
    : object({
        email: string().email(),
      });
};
type PasswordResetProps = {
  successPageUrl?: string;
};
export const PasswordReset = ({ successPageUrl }: PasswordResetProps) => {
  const { closeModal, isOpenedModal, openModal } = Modal.useModal();
  const { forgetPassword, settings } = useForgetPassword();
  const isNeedCaptcha = !!settings?.CAPTCHA_CODE?.img_src;

  return (
    <Container>
      <Header>
        <Info>
          <AvatarIcon icon={Boy30} />
          <TitleContainer>
            <Typography variant="h1">Сброс пароля</Typography>
          </TitleContainer>
        </Info>
      </Header>
      {settings?.AUTHORIZED ? (
        <Message>Ты уже авторизовался</Message>
      ) : (
        <StyledForm
          initialValues={{}}
          validationSchema={getEmailInfoSchema(isNeedCaptcha)}
          onSubmit={async (d: ResetPasswordFormData) => {
            await forgetPassword({
              USER_EMAIL: d.email,
              // @ts-ignore
              captcha_word: d.captcha,
              captcha_sid: settings?.CAPTCHA_CODE?.captcha_sid,
            });
            openModal();
          }}
        >
          {({ values }: { values: ResetPasswordFormData }) => (
            <>
              <Typography variant="body3">
                Введи эл. почту, привязанную к аккаунту.
                <br />
                <br />
                Мы отправим на нее письмо с ссылкой для сброса пароля.
              </Typography>
              <FieldInput name="email" placeholder="ivanovanov@mail.ru" />
              {isNeedCaptcha && (
                <>
                  <Captcha src={settings?.CAPTCHA_CODE?.img_src} />
                  <FieldInput label="Введи слово с картинки" name="captcha" />
                </>
              )}
              <UnknownFormErrors />
              <SubmitButton fullWidth variant="first">
                Отправить письмо
              </SubmitButton>
              <EmailSendSuccessfulModal
                email={values.email}
                onClose={closeModal}
                onEnter={() => {
                  console.log(successPageUrl)
                  if (successPageUrl) window.location.href = successPageUrl;
                }}
                open={isOpenedModal}
              />
            </>
          )}
        </StyledForm>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: ${Palette["B&W/00"]};
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  margin-bottom: 40px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;

  ${mobileMedia} {
    gap: 20px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;
