import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { mobileMedia } from "~constants";

type PointPreviewProps = {
  smallView: boolean;
  src?: string;
};

export const PointPreview = ({ src, smallView }: PointPreviewProps) => {
  return (
    <PreviewImg
      smallView={smallView}
      style={{ backgroundImage: `url('${src}')` }}
    />
  );
};

const PreviewImg = styled.div<{ smallView: boolean }>`
  width: 200px;
  height: 195px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 15px;

  ${mobileMedia} {
    border-radius: 10px;
    width: 160px;
    height: 144px;
  }

  ${(props) =>
    props.smallView &&
    css`
      width: 200px;
      height: 163px;

      ${mobileMedia} {
        width: 160px;
        height: 114px;
      }
    `};
`;
