import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import Boy24 from "~assets/images/B24.png";
import { AvatarIcon, Button, ButtonIcon, Modal } from "~components";
import { boldText, mobileMedia } from "~constants";

type EmailSendSuccessfulModalProps = {
  open: boolean;
  email?: string;
  onClose: () => void;
  onEnter: () => void;
};

export const EmailSendSuccessfulModal = ({
  open,
  onClose,
  onEnter,
  email,
}: EmailSendSuccessfulModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Header>
        <Info>
          <AvatarIcon icon={Boy24} />
          <TitleContainer>
            <Typography variant="h1">Письмо отправлено</Typography>
          </TitleContainer>
        </Info>
        <ButtonIcon Icon={Cross} onClick={onClose} />
      </Header>
      <Container>
        <Typography variant="body3">
          Мы отправили письмо для сброса пароля на эл. почту{" "}
          <Typography variant="body3" typography={boldText} component="span">
            {email}
          </Typography>
          .
          <br />
          <br />
          Не приходит письмо? Попробуй повторить попытку или проверь папку спам.
        </Typography>
        <Button fullWidth onClick={onEnter}>
          Войти в аккаунт
        </Button>
      </Container>
      <Button fullWidth variant="second" onClick={onClose}>
        Ввести почту еще раз
      </Button>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-self: stretch;
  gap: 40px;
  flex: 1 0 0;
  margin-bottom: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  margin-bottom: 40px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;

  ${mobileMedia} {
    gap: 20px;
  }
`;
