import React, { ReactNode } from "react";
import { FormItem } from "../FormItem";
import { RadioCheck, RadioCheckProps } from "../../input";

type FieldRadioCheckboxProps = {
  label: ReactNode;
  className?: string;
} & CommonFieldProps &
  RadioCheckProps;
export const FieldRadioCheckbox = ({
  name,
  label,
  ...rest
}: FieldRadioCheckboxProps) => (
  <FormItem name={name}>
    {({
      field: { value },
      meta: { error, touched },
      form: { setFieldValue, setFieldTouched },
    }) => (
      <RadioCheck
        label={label}
        defaultChecked={Boolean(value)}
        onChange={(checked) => {
          setFieldTouched(name);
          setFieldValue(name, checked);
        }}
        error={error}
        touched={touched}
        {...rest}
      />
    )}
  </FormItem>
);
