import { FC, ReactNode } from "react";
import {
  QueryClient,
  QueryClientProvider,
  QueryClientConfig,
} from "react-query";
import { BroadCast } from "~utils/broadcast";

interface QueryProviderProps {
  children: ReactNode;
}
const onError = (e: any) => {
  if (e.statusCode === 401) {
    new BroadCast('auth').post("open-auth");
  }
};
export const QueryProvider: FC<QueryProviderProps> = ({ children }) => {
  const config: QueryClientConfig = {
    defaultOptions: {
      queries: {
        retry: 1,
        onError,
        refetchOnWindowFocus: false,
      },
      mutations: {
        retry: 0,
        onError,
      },
    },
  };

  const queryClient = new QueryClient(config);

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
