import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import Boy24 from "~assets/images/B24.png";
import { AvatarIcon, Modal } from "~components";
import { mobileMedia } from "~constants";

type DataUpdateSuccessfulModalProps = {
  open?: boolean;
  onClose: () => void;
};

export const DataUpdateSuccessfulModal = ({
  open,
  onClose,
}: DataUpdateSuccessfulModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <AvatarIcon icon={Boy24} />
        <TitleContainer>
          <Typography variant="h1">Данные успешно изменены</Typography>
        </TitleContainer>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-self: stretch;
  gap: 40px;
  flex: 1 0 0;

  ${mobileMedia} {
    gap: 20px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;
