/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaptchaCodeModel } from './CaptchaCodeModel';
import {
    CaptchaCodeModelFromJSON,
    CaptchaCodeModelFromJSONTyped,
    CaptchaCodeModelToJSON,
} from './CaptchaCodeModel';
import type { ReferensUrlDataModel } from './ReferensUrlDataModel';
import {
    ReferensUrlDataModelFromJSON,
    ReferensUrlDataModelFromJSONTyped,
    ReferensUrlDataModelToJSON,
} from './ReferensUrlDataModel';

/**
 * Модель ответа с навтройками по восстановлению пароля, регистрацией и проверкой авторизации. При необходимости пройти captcha, возвращает настройки для нее
 * @export
 * @interface ResponseAuthModel
 */
export interface ResponseAuthModel {
    /**
     * Флаг успешной авторизации
     * @type {boolean}
     * @memberof ResponseAuthModel
     */
    AUTHORIZED?: boolean;
    /**
     * 
     * @type {ReferensUrlDataModel}
     * @memberof ResponseAuthModel
     */
    FORGOT_PASSWORD?: ReferensUrlDataModel;
    /**
     * 
     * @type {ReferensUrlDataModel}
     * @memberof ResponseAuthModel
     */
    REGISTER_URL?: ReferensUrlDataModel;
    /**
     * 
     * @type {CaptchaCodeModel}
     * @memberof ResponseAuthModel
     */
    CAPTCHA_CODE?: CaptchaCodeModel;
    /**
     * Сообщение от API системы (при наличии)
     * @type {string}
     * @memberof ResponseAuthModel
     */
    MESSAGE?: string;
    /**
     * Уровень ошибки
     * @type {string}
     * @memberof ResponseAuthModel
     */
    TYPE?: string;
    /**
     * Тип ошибки
     * @type {string}
     * @memberof ResponseAuthModel
     */
    ERROR_TYPE?: string;
    /**
     * Список ошибок
     * @type {Array<string>}
     * @memberof ResponseAuthModel
     */
    ERRORS?: Array<string>;
}

/**
 * Check if a given object implements the ResponseAuthModel interface.
 */
export function instanceOfResponseAuthModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseAuthModelFromJSON(json: any): ResponseAuthModel {
    return ResponseAuthModelFromJSONTyped(json, false);
}

export function ResponseAuthModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseAuthModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AUTHORIZED': !exists(json, 'AUTHORIZED') ? undefined : json['AUTHORIZED'],
        'FORGOT_PASSWORD': !exists(json, 'FORGOT_PASSWORD') ? undefined : ReferensUrlDataModelFromJSON(json['FORGOT_PASSWORD']),
        'REGISTER_URL': !exists(json, 'REGISTER_URL') ? undefined : ReferensUrlDataModelFromJSON(json['REGISTER_URL']),
        'CAPTCHA_CODE': !exists(json, 'CAPTCHA_CODE') ? undefined : CaptchaCodeModelFromJSON(json['CAPTCHA_CODE']),
        'MESSAGE': !exists(json, 'MESSAGE') ? undefined : json['MESSAGE'],
        'TYPE': !exists(json, 'TYPE') ? undefined : json['TYPE'],
        'ERROR_TYPE': !exists(json, 'ERROR_TYPE') ? undefined : json['ERROR_TYPE'],
        'ERRORS': !exists(json, 'ERRORS') ? undefined : json['ERRORS'],
    };
}

export function ResponseAuthModelToJSON(value?: ResponseAuthModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AUTHORIZED': value.AUTHORIZED,
        'FORGOT_PASSWORD': ReferensUrlDataModelToJSON(value.FORGOT_PASSWORD),
        'REGISTER_URL': ReferensUrlDataModelToJSON(value.REGISTER_URL),
        'CAPTCHA_CODE': CaptchaCodeModelToJSON(value.CAPTCHA_CODE),
        'MESSAGE': value.MESSAGE,
        'TYPE': value.TYPE,
        'ERROR_TYPE': value.ERROR_TYPE,
        'ERRORS': value.ERRORS,
    };
}

