/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ForgetPassRequest,
  ForgetPassResponse,
  RequestChangePassword,
  ResponseChangePassword,
} from '../models';
import {
    ForgetPassRequestFromJSON,
    ForgetPassRequestToJSON,
    ForgetPassResponseFromJSON,
    ForgetPassResponseToJSON,
    RequestChangePasswordFromJSON,
    RequestChangePasswordToJSON,
    ResponseChangePasswordFromJSON,
    ResponseChangePasswordToJSON,
} from '../models';

export interface AuthChangepasswdPostRequest {
    requestChangePassword: RequestChangePassword;
}

export interface AuthForgetPostRequest {
    forgetPassRequest: ForgetPassRequest;
}

/**
 * 
 */
export class RecoveryPasswordApi extends runtime.BaseAPI {

    /**
     * Данный метод позволяет получить набор данных для второго этапа восстановления пароля. Система может потребовать прохождение проверки по средствам капчи
     * Метод для получени данных по ссылке из почты для восстановления пароля
     */
    async authChangepasswdGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseChangePassword>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/changepasswd/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseChangePasswordFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет получить набор данных для второго этапа восстановления пароля. Система может потребовать прохождение проверки по средствам капчи
     * Метод для получени данных по ссылке из почты для восстановления пароля
     */
    async authChangepasswdGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseChangePassword> {
        const response = await this.authChangepasswdGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет выполнить запрос на восстановление забытого пароля. Оправляет новый пароль с подтверждением. При успешной регистрации запроса возвращается TYPE SUCCESS и отправляется письмо пользователю для восстановления пароля. При возникновении ошибки, соответствующее сообщение будет в поле MESSAGE
     * Метод для отправки запроса на изменение пароля.
     */
    async authChangepasswdPostRaw(requestParameters: AuthChangepasswdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseChangePassword>> {
        if (requestParameters.requestChangePassword === null || requestParameters.requestChangePassword === undefined) {
            throw new runtime.RequiredError('requestChangePassword','Required parameter requestParameters.requestChangePassword was null or undefined when calling authChangepasswdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/changepasswd/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestChangePasswordToJSON(requestParameters.requestChangePassword),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseChangePasswordFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет выполнить запрос на восстановление забытого пароля. Оправляет новый пароль с подтверждением. При успешной регистрации запроса возвращается TYPE SUCCESS и отправляется письмо пользователю для восстановления пароля. При возникновении ошибки, соответствующее сообщение будет в поле MESSAGE
     * Метод для отправки запроса на изменение пароля.
     */
    async authChangepasswdPost(requestParameters: AuthChangepasswdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseChangePassword> {
        const response = await this.authChangepasswdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет получить набор данных для первого этапа восстановления пароля. Система может потребовать прохождение проверки по средствам капчи
     * Метод для получени преветственных данных для восстановления пароля
     */
    async authForgetGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ForgetPassResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/forget/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ForgetPassResponseFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет получить набор данных для первого этапа восстановления пароля. Система может потребовать прохождение проверки по средствам капчи
     * Метод для получени преветственных данных для восстановления пароля
     */
    async authForgetGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ForgetPassResponse> {
        const response = await this.authForgetGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет выполнить запрос на восстановление забытого пароля. При успешной регистрации запроса возвращается TYPE SUCCESS и отправляется письмо пользователю для восстановления пароля
     * Метод для отправки запроса на восстановление пароля.
     */
    async authForgetPostRaw(requestParameters: AuthForgetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ForgetPassResponse>> {
        if (requestParameters.forgetPassRequest === null || requestParameters.forgetPassRequest === undefined) {
            throw new runtime.RequiredError('forgetPassRequest','Required parameter requestParameters.forgetPassRequest was null or undefined when calling authForgetPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/forget/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgetPassRequestToJSON(requestParameters.forgetPassRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ForgetPassResponseFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет выполнить запрос на восстановление забытого пароля. При успешной регистрации запроса возвращается TYPE SUCCESS и отправляется письмо пользователю для восстановления пароля
     * Метод для отправки запроса на восстановление пароля.
     */
    async authForgetPost(requestParameters: AuthForgetPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ForgetPassResponse> {
        const response = await this.authForgetPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
