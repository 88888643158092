import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import * as React from "react";

import Boy43 from "~assets/images/B43.png";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import { Button, ButtonIcon, AvatarIcon, Modal } from "~components";
import { mobileMedia, thinText } from "~constants";

type LeaveConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  onLeave: () => void;
};

export const LeaveConfirmationModal = ({
  open,
  onClose,
  onLeave,
}: LeaveConfirmationModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <Header>
          <Info>
            <AvatarIcon icon={Boy43} />
            <TitleContainer>
              <Typography variant="h1">Выйти без сохранения</Typography>
            </TitleContainer>
          </Info>
          <ButtonIcon Icon={Cross} onClick={onClose} />
        </Header>
        <Typography variant="body3" typography={thinText}>
          Ты действительно хочешь выйти и не сохранить изменения данных?
        </Typography>
        <Buttons>
          <OptionButton variant="danger" onClick={onLeave}>
            Выйти без изменений
          </OptionButton>
          <OptionButton variant="second" onClick={onClose}>
            Остаться
          </OptionButton>
        </Buttons>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  ${mobileMedia} {
    gap: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 10px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;

  ${mobileMedia} {
    gap: 20px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const OptionButton = styled(Button)`
  width: 100%;
`;
