import styled from "@emotion/styled";
import { ReactNode } from "react";
import { ReactComponent as Caution } from "~assets/svg/caution_circle.svg";
import { Palette } from "~utils/Palette";

type MessageProps = {
  className?: string;
  children: ReactNode;
  color?: "alert" | "info";
};
export const Message = ({
  children,
  className,
  color = "info",
}: MessageProps) => (
  <Title className={className} color={color}>
    <CautionIcon
      fill={color === "info" ? Palette["B&W/00"] : Palette["B&W/100"]}
    />
    {children}
  </Title>
);
const CautionIcon = styled(Caution)`
  padding: 12px;
  min-width: 24px;
`;
const Title = styled.div<{ color: MessageProps["color"] }>`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 12px;
  margin-bottom: 20px;
  background-color: ${({ color }) =>
    color === "alert" ? Palette["Error/50"] : Palette["B&W/100"]};
  border-radius: 12px;
`;
