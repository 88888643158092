/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthUserModel
 */
export interface AuthUserModel {
    /**
     * Логин или email пользователя
     * @type {string}
     * @memberof AuthUserModel
     */
    USER_LOGIN?: string;
    /**
     * Пароль пользователя
     * @type {string}
     * @memberof AuthUserModel
     */
    USER_PASSWORD?: string;
    /**
     * Флаг запомнить пользователя
     * @type {string}
     * @memberof AuthUserModel
     */
    USER_REMEMBER?: AuthUserModelUSERREMEMBEREnum;
    /**
     * Ответ по captha
     * @type {string}
     * @memberof AuthUserModel
     */
    captcha_word?: string;
    /**
     * Идентификатор captha
     * @type {string}
     * @memberof AuthUserModel
     */
    captcha_sid?: string;
}


/**
 * @export
 */
export const AuthUserModelUSERREMEMBEREnum = {
    Y: 'Y',
    N: 'N'
} as const;
export type AuthUserModelUSERREMEMBEREnum = typeof AuthUserModelUSERREMEMBEREnum[keyof typeof AuthUserModelUSERREMEMBEREnum];


/**
 * Check if a given object implements the AuthUserModel interface.
 */
export function instanceOfAuthUserModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuthUserModelFromJSON(json: any): AuthUserModel {
    return AuthUserModelFromJSONTyped(json, false);
}

export function AuthUserModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUserModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'USER_LOGIN': !exists(json, 'USER_LOGIN') ? undefined : json['USER_LOGIN'],
        'USER_PASSWORD': !exists(json, 'USER_PASSWORD') ? undefined : json['USER_PASSWORD'],
        'USER_REMEMBER': !exists(json, 'USER_REMEMBER') ? undefined : json['USER_REMEMBER'],
        'captcha_word': !exists(json, 'captcha_word') ? undefined : json['captcha_word'],
        'captcha_sid': !exists(json, 'captcha_sid') ? undefined : json['captcha_sid'],
    };
}

export function AuthUserModelToJSON(value?: AuthUserModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'USER_LOGIN': value.USER_LOGIN,
        'USER_PASSWORD': value.USER_PASSWORD,
        'USER_REMEMBER': value.USER_REMEMBER,
        'captcha_word': value.captcha_word,
        'captcha_sid': value.captcha_sid,
    };
}

