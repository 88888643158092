import { ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ru from "date-fns/locale/ru";

import { theme } from "~constants";
import { QueryProvider } from "~services/api/ReactQuery";

// Так как можно передавать любые пропсы в любые компоненты
type Props = any;
export const App = (Component: React.ComponentType) => (props: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <QueryProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
          <Component {...props} />
        </LocalizationProvider>
      </QueryProvider>
    </ThemeProvider>
  );
};
