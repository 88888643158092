export class DataStorage implements Storage {
  private prefix = "PROSTO_";
  private storage: Storage = localStorage;
  constructor(type: "local" | "session") {
    if (type === "local") {
      this.storage = localStorage;
    }
    if (type === "session") {
      this.storage = sessionStorage;
    }
  }
  getItem(key: string) {
    return this.storage.getItem(`${this.prefix + key}`);
  }
  setItem(key: string, value: string) {
    this.storage.setItem(`${this.prefix + key}`, value);
  }
  removeItem(key: string) {
    this.storage.removeItem(`${this.prefix + key}`);
  }
  clear() {
    this.storage.clear();
  }
  key(index: number) {
    return this.storage.key(index);
  }
  get length(): number {
    return this.storage.length;
  }
}
