import styled from "@emotion/styled";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { Palette } from "~utils/Palette";

export type ButtonIconInfo<T> = {
  name: T;
  Component: React.FC<React.SVGProps<SVGSVGElement>>;
  ActiveComponent?: React.FC<React.SVGProps<SVGSVGElement>>;
};

type ButtonSwitchProps<T> = {
  mode: T;
  items: ButtonIconInfo<T>[];
  onChange: (value: T) => void;
};

export const ButtonSwitch = <T extends string>({
  items,
  mode,
  onChange,
}: ButtonSwitchProps<T>) => {
  return (
    <ButtonGroup value={mode} exclusive onChange={(_, v) => onChange(v)}>
      {items.map(({ name, Component, ActiveComponent }) => {
        const isActive = mode === name;
        const IconComponent =
          ActiveComponent && isActive ? ActiveComponent : Component;

        return (
          <ToggleButton key={name} value={name}>
            <IconComponent
              fill={isActive ? Palette["B&W/00"] : Palette["B&W/100"]}
            />
          </ToggleButton>
        );
      })}
    </ButtonGroup>
  );
};

const ButtonGroup = styled(ToggleButtonGroup)`
  "& .muitogglebuttongroup-grouped": {
    border: 0;

    "&.mui-disabled": {
      border: 0;
    }
    ,
    "&:not(:first-of-type)": {
      border-radius: 7px;
    }
    ,
    "&:first-of-type": {
      border-radius: 7px;
    }
  }
`;
