import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";
import React, { ReactNode } from "react";
import { FormItem } from "../FormItem";

type FieldCheckboxProps = { label: ReactNode } & CommonFieldProps & CheckboxProps;
export const FieldCheckbox = ({ name, label, ...rest }: FieldCheckboxProps) => (
  <FormItem name={name}>
    {({ field: { onChange, value } }) => (
      <FormControlLabel
        control={
          <Checkbox
            {...rest}
            name={name}
            checked={!!value}
            onChange={onChange}
          />
        }
        label={label}
      />
    )}
  </FormItem>
);
