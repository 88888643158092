/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeyValueItem
 */
export interface KeyValueItem {
    /**
     * 
     * @type {string}
     * @memberof KeyValueItem
     */
    KEY?: string;
    /**
     * 
     * @type {string}
     * @memberof KeyValueItem
     */
    VALUE?: string;
}

/**
 * Check if a given object implements the KeyValueItem interface.
 */
export function instanceOfKeyValueItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KeyValueItemFromJSON(json: any): KeyValueItem {
    return KeyValueItemFromJSONTyped(json, false);
}

export function KeyValueItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyValueItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'KEY': !exists(json, 'KEY') ? undefined : json['KEY'],
        'VALUE': !exists(json, 'VALUE') ? undefined : json['VALUE'],
    };
}

export function KeyValueItemToJSON(value?: KeyValueItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'KEY': value.KEY,
        'VALUE': value.VALUE,
    };
}

