import { UserClickRequest } from "prosto-api-client";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DataStorage } from "~services/DataStorage";
import { coworkingApi } from "~services/api/prosto-api";
import { Env } from "~utils/Env";

const GET_PLACES = "GET_PLACES";
const GET_EVENT_INFO = "GET_EVENT_INFO";

const getPlaces = () => coworkingApi.coworkingGet();
const getEventInfo = (placeId: string) =>
  coworkingApi.coworkingElementIdGet({
    elementId: Number(placeId),
    showSurvey: Env.isDev ? "yes" : undefined,
  });

const registerOnPlace = (userClickRequest: UserClickRequest) =>
  coworkingApi.coworkingUserClickPost({
    userClickRequest: {
      ...userClickRequest,
      EVENT_ID: Number(userClickRequest.EVENT_ID),
    },
  });

const cancelRegisterOnPlace = (elementId: number) =>
  coworkingApi.coworkingUserClickElementIdDelete({
    elementId,
  });

export const useCoworkingApi = () => {
  const { data, isLoading } = useQuery(GET_PLACES, getPlaces);
  const { cancelRegistration, registerInfo, registerOn, isWaitingBookLocker } =
    useUserActionsApi();
  return {
    cancelRegistration,
    placeMenuItems: data,
    reservedEvents: data?.US_RESERVATED_EVENTS,
    isLoadingPlaces: isLoading,
    registerOn,
    registerInfo,
    isWaitingBookLocker,
  };
};

export const useUserActionsApi = () => {
  const queryClient = useQueryClient();
  const {
    mutateAsync: registerOn,
    data: registerInfo,
    isLoading,
  } = useMutation(registerOnPlace, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_EVENT_INFO);
      queryClient.invalidateQueries(GET_PLACES);
    },
  });

  const { mutateAsync: cancelRegistration } = useMutation(
    cancelRegisterOnPlace,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_EVENT_INFO);
        queryClient.invalidateQueries(GET_PLACES);
      },
    }
  );

  return {
    isWaitingBookLocker: isLoading,
    registerOn,
    registerInfo,
    cancelRegistration,
  };
};

export const useCoworkingEventsApi = (eventId: string) => {
  const { data, isLoading } = useQuery(
    [GET_EVENT_INFO, eventId],
    () => getEventInfo(eventId),
    { enabled: !!eventId }
  );
  return { event: data, isLoading };
};

export type CoworkingPickerMode = "row" | "column";
export const useCoworkingPickerMode = () => {
  const key = "coworking-list-picker-mode";
  const defaultMode = dstorage.getItem(key) || "row";
  const [mode, setMode] = useState<CoworkingPickerMode>(
    defaultMode as CoworkingPickerMode
  );

  return {
    mode,
    setMode: (mode: CoworkingPickerMode) => {
      setMode(mode);
      dstorage.setItem(key, mode);
    },
  };
};
const dstorage = new DataStorage("local");
