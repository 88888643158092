import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ReactNode } from "react";

import { Palette } from "~utils/Palette";

export type FieldContainerProps = {
  children: ReactNode;
  label?: ReactNode;
  helperText?: ReactNode;
  supportingText?: ReactNode;
  className?: string;
};

export const FieldContainer = ({
  label,
  helperText,
  children,
  className,
  supportingText,
}: FieldContainerProps) => {
  return (
    <Container className={className}>
      {label && <Label variant="label2">{label}</Label>}
      {children}
      {helperText && <HelperText variant="body3">{helperText}</HelperText>}
      {supportingText}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1 1 0;
`;

const Label = styled(Typography)`
  color: ${Palette["B&W/40"]};
  margin-bottom: 5px;
  padding-left: 15px;
`;

const HelperText = styled(Typography)`
  color: ${Palette["Error/50"]};
  margin-top: 10px;
  padding-left: 15px;
`;
