import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { StaticDatePicker, StaticDatePickerProps } from "@mui/x-date-pickers";
import { forwardRef, useState } from "react";

import { ReactComponent as ArrowLeftIcon } from "~assets/svg/arrow_left.svg";
import { ReactComponent as ArrowRightIcon } from "~assets/svg/arrow_right.svg";
import { Button } from "~components";
import { mobileMedia } from "~constants";
import { DateService } from "~services/Date";
import { Palette } from "~utils/Palette";
import { CalendarHeader } from "./CalendarHeader";
import { months } from "./constants";
import { DayCell } from "./DayCell";
import { countWeeks, getIsDisabledDate } from "./utils";

export type DatePickerProps = {
  date?: Date;
  onChange?: (date: Date) => void;
  appointmentDates?: Date[];
  enabledDates?: Date[];
  showFooter?: boolean;
  showYearPicker?: boolean;
  minDate?: Date;
  maxDate?: Date;
} & StaticDatePickerProps<Date>;

export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
  (
    {
      date,
      onChange,
      appointmentDates,
      enabledDates,
      showFooter = false,
      showYearPicker = false,
      minDate = new Date(1930, 0, 1),
      maxDate = new Date(2050, 0, 1),
      className,
      ...restPickerProps
    },
    ref
  ) => {
    const [viewedMonth, setViewedMonth] = useState(date);

    const isLargeMonth = viewedMonth ? countWeeks(viewedMonth) === 6 : false;

    const stringDate = date
      ? DateService.toFormattedString(date, "dd")
      : undefined;

    const currentMonth = date
      ? months[date.getMonth()].toLowerCase()
      : undefined;

    const formattedDate = date
      ? `${stringDate} ${currentMonth} ${date.getFullYear()}`
      : undefined;

    return (
      <Container ref={ref} className={className}>
        <TextContainer>
          <SelectedDateTypography>{formattedDate}</SelectedDateTypography>
        </TextContainer>
        <Calendar
          shouldDisableDate={(date) => getIsDisabledDate(date, enabledDates)}
          slots={{
            day: DayCell,
            calendarHeader: CalendarHeader,
            leftArrowIcon: ArrowLeftIcon,
            rightArrowIcon: ArrowRightIcon,
          }}
          slotProps={{
            day: {
              //@ts-ignore Кастомный проп https://github.com/mui/mui-x/issues/9775
              appointmentDates,
            },
            calendarHeader: {
              //@ts-ignore Кастомный проп https://github.com/mui/mui-x/issues/9775
              showMonthPicker: true,
              showYearPicker: showYearPicker,
              onMonthViewChange: setViewedMonth,
              minDate,
              maxDate,
            },
            actionBar: { actions: [] },
            toolbar: { hidden: true },
          }}
          dayOfWeekFormatter={(day) => {
            return day.charAt(0).toUpperCase() + day.charAt(1);
          }}
          views={["month", "day"]}
          showDaysOutsideCurrentMonth
          value={date}
          onChange={(newValue) => newValue && onChange?.(newValue)}
          isLargeMonth={isLargeMonth}
          showFooter={showFooter}
          {...restPickerProps}
        />
        {showFooter && (
          <Footer>
            <FooterButton variant="third">Подтвердить</FooterButton>
          </Footer>
        )}
      </Container>
    );
  }
);

const Container = styled.div`
  border-radius: 20px;
  background: ${Palette["B&W/05"]};
  width: 100%;
`;

const Calendar = styled(StaticDatePicker<Date>)<{
  isLargeMonth: boolean;
  showFooter: boolean;
}>`
  border-radius: ${(props) => (props.showFooter ? "20px 20px 0 0" : "20px")};
  background: ${Palette["B&W/10"]};

  .MuiDateCalendar-root {
    width: 100%;
  }

  .MuiPickersFadeTransitionGroup-root .MuiDayCalendar-slideTransition {
    overflow-y: hidden;
  }

  .MuiDayCalendar-header {
    display: flex;
    justify-content: space-around;
  }

  .MuiDayCalendar-weekDayLabel {
    display: flex;
    color: white;

    font-size: 13px;
    line-height: 1.23;
    letter-spacing: 0.4px;
  }

  .MuiPickersFadeTransitionGroup-root .MuiDayCalendar-slideTransition {
    min-height: ${({ isLargeMonth }) => (isLargeMonth ? "260px" : "220px")};
  }

  ${mobileMedia} {
    .MuiPickersFadeTransitionGroup-root .MuiDayCalendar-slideTransition {
      min-height: ${({ isLargeMonth }) => (isLargeMonth ? "254px" : "211px")};
    }
  }
`;

const TextContainer = styled.div`
  padding: 15px 15px 10px 20px;
`;

const SelectedDateTypography = styled(Typography)`
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.7px;

  ${mobileMedia} {
    font-size: 29px;
    line-height: 1;
  }

  padding: 4px 0 7px;
`;

const Footer = styled.div`
  width: 100%;
`;

const FooterButton = styled(Button)`
  width: 100%;
  background-color: ${Palette["B&W/10"]};
  border-radius: 0 0 15px 15px;
`;
