import styled from "@emotion/styled";
import { Typography, useMediaQuery } from "@mui/material";
import { Chart, registerables, ScriptableContext } from "chart.js";
import { TooltipModel } from "chart.js/dist/types";
import { clipArea } from "chart.js/helpers";
import { RefObject, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { devices, mobileMedia } from "~constants";
import { Palette } from "~utils/Palette";
import { plural } from "~utils/Plural";
import { ChartData } from "../types";

Chart.register(...registerables);

type CapacityChartProps = {
  data?: ChartData[];
  label?: string[];
  selectedFrom?: number;
  selectedTo?: number;
  disabledFrom?: number;
  disabledTo?: number;
  parentWidth?: number;
};

type TooltipConf = {
  opacity: number;
  top: number;
  left: string;
  right: string;
  intervalId: number;
  vacant: number;
  capacity?: number;
};

const MIN_GRID_LINE_INDEX = 0;
const MAX_GRID_LINE_INDEX = 5;

export const CapacityChart = ({
  label,
  data = [],
  selectedFrom,
  selectedTo,
  disabledFrom,
  disabledTo,
  parentWidth,
}: CapacityChartProps) => {
  const isMobileView = useMediaQuery(devices.mobile);
  const chartRef = useRef(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const capacity = Math.max(...data?.map((v) => v?.memberCount));
  const [tooltip, setTooltip] = useState<TooltipConf>({
    opacity: 0,
    top: 0,
    left: "auto",
    right: "auto",
    intervalId: 0,
    vacant: 0,
    capacity,
  });

  const normalizeData = data?.map((v) => ({
    ...v,
    memberCount:
      v.memberCount - v.value === 0 || v.value === 0 ? capacity : v.memberCount,
    value: v.memberCount - v.value === 0 ? capacity : v.value,
  }));

  return (
    <>
      <Bar
        ref={chartRef}
        data={{
          labels: label,
          datasets: [
            {
              barPercentage: 0.9,
              parsing: { yAxisKey: "value", xAxisKey: "value" },
              backgroundColor: getBackgroundColor({
                disabledFrom,
                disabledTo,
                selectedFrom,
                selectedTo,
              }),
              borderRadius: 3,
              borderSkipped: false,
              barThickness: "flex",
              categoryPercentage: 1,
              data: normalizeData,
            },
          ],
        }}
        options={{
          aspectRatio: isMobileView ? 390 / 111 : 586 / 129,
          responsive: true,
          scales: {
            y: {
              max: capacity,
              min: -5,
              border: { display: false },
              ticks: { count: MAX_GRID_LINE_INDEX, display: false },
              grid: {
                drawOnChartArea: true,
                color: (ctx) => {
                  if (
                    ctx.index === MAX_GRID_LINE_INDEX - 1 ||
                    ctx.index === MIN_GRID_LINE_INDEX
                  ) {
                    return Palette["B&W/20"];
                  }
                  return Palette["B&W/10"];
                },
              },
            },
            x: {
              ticks: { color: "transparent" },
              grid: { display: false },
            },
          },
          layout: {
            padding: 9,
          },
          animation: false,
          plugins: {
            tooltip: {
              enabled: false,
              external: getCustomTooltip({
                chartRef,
                parentWidth,
                setTooltip,
                tooltip,
                tooltipRef,
                capacity,
              }),
            },
            legend: { display: false },
          },
        }}
        plugins={[
          {
            id: "afterDraw",
            afterDraw: ({ ctx, scales }) => {
              const xAxis = scales.x;
              const tickGap =
                xAxis.getPixelForTick(1) - xAxis.getPixelForTick(0);

              xAxis.ticks.forEach((tick, index) => {
                const yPos = xAxis.bottom;
                const xPos = xAxis.getPixelForTick(index);
                const yPadding = 10;

                ctx.save();
                ctx.textBaseline = "middle";
                ctx.textAlign = "center";
                ctx.fillStyle = Palette["B&W/40"];
                ctx.font = '500 13px/1.15 "CodecPro"';
                ctx.fillText(
                  tick.label as string,
                  xPos - tickGap / 2,
                  yPos - yPadding
                );
                ctx.restore();
              });
            },
          },
          {
            id: "beforeDraw",
            beforeDraw: function ({ ctx, scales, chartArea, height }) {
              const xAxis = scales.x;
              const tickGap =
                xAxis.getPixelForTick(1) - xAxis.getPixelForTick(0);
              const leftPadding = isMobileView ? 7 : 6;
              const rightPadding = isMobileView
                ? tickGap * 0.8
                : tickGap * 0.87;

              clipArea(ctx, {
                left: chartArea.left - leftPadding,
                top: 0,
                right: chartArea.right - rightPadding,
                bottom: height,
              });
              return true;
            },
          },
        ]}
      />
      <TooltipContainer
        ref={tooltipRef}
        top={tooltip.top}
        left={tooltip.left}
        right={tooltip.right}
        opacity={tooltip.opacity}
      >
        <TooltipText>
          {Number(tooltip?.vacant) === 0
            ? "Мест нет"
            : `Осталось ${plural(Number(tooltip?.vacant), [
                "место",
                "места",
                "мест",
              ])}
          из ${tooltip.capacity}`}
        </TooltipText>
      </TooltipContainer>
    </>
  );
};

type tooltipSetter = (tooltip: TooltipConf) => TooltipConf;

const getCustomTooltip =
  ({
    chartRef,
    setTooltip,
    tooltip,
    tooltipRef,
    capacity,
    parentWidth = 0,
  }: {
    capacity: number;
    parentWidth?: number;
    chartRef: RefObject<unknown>;
    tooltipRef: RefObject<HTMLDivElement>;
    tooltip: TooltipConf;
    setTooltip: (arg: TooltipConf | tooltipSetter) => void;
  }) =>
  (context: { chart: Chart; tooltip: TooltipModel<"bar"> }) => {
    const tooltipModel = context.tooltip;
    if (!chartRef || !chartRef.current) return;

    if (tooltipModel.opacity === 0) {
      if (tooltip.opacity !== 0) {
        setTooltip((prev) => ({ ...prev, opacity: 0 }));
      }
      return;
    }

    const canvas = context.chart.canvas;
    const tooltipRectWidth =
      tooltipRef.current?.getBoundingClientRect().width || 2;

    const leftPadding = -(tooltipRectWidth / 2) + tooltipModel.caretX;
    const left = leftPadding < 0 ? "0px" : leftPadding + "px";

    const isFitParent =
      tooltipRectWidth / 2 + tooltipModel.caretX <= parentWidth;

    const newTooltipData = {
      opacity: 1,
      capacity,
      left: isFitParent ? left : "auto",
      right: isFitParent ? "auto" : "0px",
      top: canvas.offsetTop + canvas.offsetHeight,
      vacant: capacity - +tooltipModel.dataPoints[0].formattedValue,
      intervalId: (tooltipModel.dataPoints[0].raw as ChartData).intervalId,
    };

    if (
      tooltip.opacity !== newTooltipData.opacity ||
      tooltip.intervalId !== newTooltipData.intervalId
    ) {
      setTooltip((prev) => ({ ...prev, ...newTooltipData }));
    }
  };

const getBackgroundColor =
  ({
    disabledFrom,
    disabledTo,
    selectedFrom,
    selectedTo,
  }: {
    selectedFrom?: number;
    selectedTo?: number;
    disabledFrom?: number;
    disabledTo?: number;
  }) =>
  (ctx: ScriptableContext<"bar">) => {
    if (
      selectedFrom &&
      selectedTo &&
      (ctx.raw as ChartData)?.intervalId >= selectedFrom &&
      (ctx.raw as ChartData)?.intervalId <= selectedTo
    ) {
      return Palette["B&W/100"];
    }

    if (
      disabledFrom &&
      disabledTo &&
      (ctx.raw as ChartData)?.intervalId >= disabledFrom &&
      (ctx.raw as ChartData)?.intervalId < disabledTo
    ) {
      return Palette["B&W/00"];
    }

    return Palette["B&W/20"];
  };

const TooltipContainer = styled.div<{
  top: number;
  left: string;
  right: string;
  opacity: number;
}>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  opacity: ${({ opacity }) => opacity};

  padding: 5px 8px;
  border-radius: 7px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  background: ${Palette["B&W/20"]};
  box-shadow: 0 10.5px 36px 0 rgba(0, 0, 0, 0.19),
    0 3px 13px 0 rgba(0, 0, 0, 0.04);
`;

const TooltipText = styled(Typography)`
  text-align: center;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.16;

  ${mobileMedia} {
    font-size: 12px;
    line-height: 1.16;
  }
`;
