import styled from "@emotion/styled";

import { Palette } from "~utils/Palette";

type QRCodeProps = {
  content?: string;
};

export const QRCode = ({ content }: QRCodeProps) => {
  return (
    <QrCodeBlock>
      <QRImg src={content} alt="" />
    </QrCodeBlock>
  );
};

const QRImg = styled.img`
  max-width: 245px;
  max-height: 245px;
`;

const QrCodeBlock = styled.div`
  display: flex;
  padding: 15px;
  align-items: flex-start;

  border-radius: 10px;
  background: ${Palette["B&W/100"]};
`;
