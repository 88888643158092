import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";

import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import { ButtonIcon, Modal } from "~components";
import { ArchivedRecordModal } from "~modules/user-account/ArchivedRecordModal";
import { Card } from "~modules/user-account/Card";
import { RecordInfo } from "~modules/user-account/types";

type RecordsArchiveProps = {
  type: "coworking" | "event";
  open?: boolean;
  items?: RecordInfo[];
  onClose: () => void;
};

export const RecordsArchive = ({
  type,
  open,
  items = [],
  onClose,
}: RecordsArchiveProps) => {
  const { closeModal, isOpenedModal, openModal } = Modal.useModal();
  const [card, setCard] = useState<RecordInfo>();
  const isCoworking = type === "coworking";

  const title = isCoworking
    ? "Архив записей в коворкинг"
    : "Архив записей на мероприятия";

  return (
    <>
      <Modal open={open} onClose={onClose} fullPage>
        <Container>
          <Header>
            <TitleContainer>
              <Typography variant="h1">
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Typography>
            </TitleContainer>
            <ButtonIcon Icon={Cross} onClick={onClose} />
          </Header>
          <Content>
            {items.map((item) => (
              <Card
                key={item.title}
                title={item.title}
                date={item.date}
                startTime={item.startTime}
                endTime={item.endTime}
                additionalInfo={item.additionalInfo}
                onClick={() => {
                  openModal();
                  setCard(item);
                }}
              />
            ))}
          </Content>
        </Container>
      </Modal>
      {card && (
        <ArchivedRecordModal
          open={isOpenedModal}
          onClose={closeModal}
          date={card?.date}
          name={card?.title}
          geoLink={card.geoLink}
          placeName={card?.placeName}
          startTime={card?.startTime}
          endTime={card?.endTime}
        />
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;
