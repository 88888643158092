/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Перечисление cпособов аутентификации. Указывает каким способом проверяется пользователь на конкретном адресе коворкинга
 * @export
 */
export const EPlaceAuthenticationMode = {
    QrSite: 'QR_site',
    QrLocker: 'QR_locker',
    QrSkud: 'QR_skud',
    FaceId: 'Face_ID'
} as const;
export type EPlaceAuthenticationMode = typeof EPlaceAuthenticationMode[keyof typeof EPlaceAuthenticationMode];


export function EPlaceAuthenticationModeFromJSON(json: any): EPlaceAuthenticationMode {
    return EPlaceAuthenticationModeFromJSONTyped(json, false);
}

export function EPlaceAuthenticationModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EPlaceAuthenticationMode {
    return json as EPlaceAuthenticationMode;
}

export function EPlaceAuthenticationModeToJSON(value?: EPlaceAuthenticationMode | null): any {
    return value as any;
}

