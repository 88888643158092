import { useEffect } from "react";
import { FormikProps } from "formik";

type FormControlProps<T,> = {
  formikProps: FormikProps<T>,
  onChangeSubscriber?: (values: T) => void;
}
export const FormControl = <T,>({ formikProps, onChangeSubscriber }: FormControlProps<T>) => {
  useEffect(() => {
    onChangeSubscriber && onChangeSubscriber(formikProps.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikProps.values])
  return null;
}
