import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { ReactComponent as Delete } from "~assets/svg/delete.svg";
import { ReactComponent as Upload } from "~assets/svg/upload.svg";
import { ButtonIcon } from "~components";
import { TextField } from "./TextField";
import { Palette } from "~utils/Palette";
import { mobileMedia } from "~constants";

export type PhotoUploadInputProps = {
  photoUrl?: string;
  onChange?: (file?: File | string) => void;
};

export const PhotoUploadInput = ({
  onChange,
  photoUrl,
}: PhotoUploadInputProps) => {
  const [selectedImage, setSelectedImage] = useState<File>();
  const [imgSrc, setImgSrc] = useState(photoUrl);
  const objectUrl = selectedImage && URL.createObjectURL(selectedImage);

  useEffect(() => {
    onChange?.(selectedImage);
  }, [selectedImage]);

  const isSelectedFile = Boolean(selectedImage);

  const removeFile = () => {
    setImgSrc(undefined);
    setSelectedImage(undefined);
    onChange?.("");
  };

  return (
    <>
      <FileUploadInput
        type="file"
        id="upload-photo"
        accept="image/png, image/gif, image/jpeg"
        onChange={(e) => setSelectedImage(e.target.files![0])}
      />
      <StyledTextField
        isSelectedFile={isSelectedFile}
        name="photo"
        label="Фото"
        value={isSelectedFile ? selectedImage?.name : "Загрузить файл"}
        InputProps={{
          startAdornment: <ImgPreview src={objectUrl || imgSrc} />,
          endAdornment:
            imgSrc || isSelectedFile ? (
              <ButtonIcon
                Icon={Delete}
                fill={Palette["Error/50"]}
                onClick={removeFile}
              />
            ) : (
              !isSelectedFile && (
                <UploadLabel htmlFor="upload-photo">
                  <ButtonIcon Icon={Upload} />
                </UploadLabel>
              )
            ),
          readOnly: true,
        }}
      />
    </>
  );
};
const UploadLabel = styled.label`
  width: calc(100% - 10px);
  position: absolute;
  left: 0;
  display: flex;
  justify-content: end;
  cursor: pointer;
`;
const FileUploadInput = styled.input`
  display: none;
`;

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "isSelectedFile",
})<{ isSelectedFile: boolean }>`
  & .MuiInputBase-root {
    padding-right: 10px;
    ${mobileMedia} {
      padding-left: 9px;
      padding-right: 5px;
    }
  }

  & input {
    font-weight: ${({ isSelectedFile }) =>
      isSelectedFile ? "300" : "initial"};
  }
`;

type ImgPreviewProps = {
  src?: string;
};

const ImgPreview = ({ src }: ImgPreviewProps) => {
  return src ? (
    <Container>
      <IconImage src={src} alt="icon" />
    </Container>
  ) : null;
};

const Container = styled.div`
  width: 40px;
  display: flex;
  flex-shrink: 0;
`;

const IconImage = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 70px;
  object-fit: cover;
`;
