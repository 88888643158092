/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Статусы синхронизации со СКУД
 * @export
 */
export const ESkudSyncStatus = {
    None: 'None',
    Success: 'SUCCESS',
    Error: 'ERROR',
    Faild: 'FAILD'
} as const;
export type ESkudSyncStatus = typeof ESkudSyncStatus[keyof typeof ESkudSyncStatus];


export function ESkudSyncStatusFromJSON(json: any): ESkudSyncStatus {
    return ESkudSyncStatusFromJSONTyped(json, false);
}

export function ESkudSyncStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ESkudSyncStatus {
    return json as ESkudSyncStatus;
}

export function ESkudSyncStatusToJSON(value?: ESkudSyncStatus | null): any {
    return value as any;
}

