import { AuthPostRequest } from "prosto-api-client";
import { useMutation, useQuery } from "react-query";
import { authApi, getAuthSettingsApi } from "~services/api/prosto-api";

const GET_CAPTCHA = "GET_CAPTCHA";
const startAuth = (authUserModel: AuthPostRequest["authUserModel"]) =>
  authApi.authPost({ authUserModel });
const getCAPTCHA = () => getAuthSettingsApi.authGet();
const userInfo = () => authApi.authUseraccountGet();

export const useAuthFlow = () => {
  const { isLoading, mutateAsync, error } = useMutation(startAuth, {
    onSuccess: () => {
      window.location.reload();
    },
  });

  const { isLoading: isLoadingCAPTCHA, data } = useQuery(
    GET_CAPTCHA,
    getCAPTCHA
  );
  const { mutateAsync: getUserData } = useMutation(userInfo);
  return {
    isLoading,
    error,
    isLoadingCAPTCHA,
    settings: data,
    getUserData,
    startAuth: mutateAsync,
  };
};
