/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthUserModel,
  ChangePasswordModel,
  RegistrationAccount,
  ResponseAccount,
  ResponseAuthUserModel,
  ResponseUserInfo,
  UserInfoBase,
  UserInfoCreate,
} from '../models';
import {
    AuthUserModelFromJSON,
    AuthUserModelToJSON,
    ChangePasswordModelFromJSON,
    ChangePasswordModelToJSON,
    RegistrationAccountFromJSON,
    RegistrationAccountToJSON,
    ResponseAccountFromJSON,
    ResponseAccountToJSON,
    ResponseAuthUserModelFromJSON,
    ResponseAuthUserModelToJSON,
    ResponseUserInfoFromJSON,
    ResponseUserInfoToJSON,
    UserInfoBaseFromJSON,
    UserInfoBaseToJSON,
    UserInfoCreateFromJSON,
    UserInfoCreateToJSON,
} from '../models';

export interface AuthPostRequest {
    authUserModel: AuthUserModel;
}

export interface AuthRegistrationPostRequest {
    userInfoCreate: UserInfoCreate;
}

export interface AuthUseraccountPostRequest {
    userInfoBase: UserInfoBase;
}

export interface AuthUseraccountPutRequest {
    changePasswordModel: ChangePasswordModel;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Данный метод позволяет получить набор данных для отображении ЛК
     * Метод для получения данных для ЛК
     */
    async authPersonalGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseAccount>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/personal/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseAccountFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет получить набор данных для отображении ЛК
     * Метод для получения данных для ЛК
     */
    async authPersonalGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseAccount> {
        const response = await this.authPersonalGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Авторизация пользователя
     * Авторизация пользователя
     */
    async authPostRaw(requestParameters: AuthPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.authUserModel === null || requestParameters.authUserModel === undefined) {
            throw new runtime.RequiredError('authUserModel','Required parameter requestParameters.authUserModel was null or undefined when calling authPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthUserModelToJSON(requestParameters.authUserModel),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Авторизация пользователя
     * Авторизация пользователя
     */
    async authPost(requestParameters: AuthPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.authPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет получить набор данных для регистрации нового пользователя
     * Метод для получения данных для регистрации нового пользователя
     */
    async authRegistrationGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegistrationAccount>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/registration/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationAccountFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет получить набор данных для регистрации нового пользователя
     * Метод для получения данных для регистрации нового пользователя
     */
    async authRegistrationGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegistrationAccount> {
        const response = await this.authRegistrationGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет зарегичтрировать нового пользователя. При успешной регистрации возвращается TYPE SUCCESS и новый пользователь авторизуется
     * Метод для регистрации нового пользователя
     */
    async authRegistrationPostRaw(requestParameters: AuthRegistrationPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegistrationAccount>> {
        if (requestParameters.userInfoCreate === null || requestParameters.userInfoCreate === undefined) {
            throw new runtime.RequiredError('userInfoCreate','Required parameter requestParameters.userInfoCreate was null or undefined when calling authRegistrationPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/registration/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInfoCreateToJSON(requestParameters.userInfoCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationAccountFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет зарегичтрировать нового пользователя. При успешной регистрации возвращается TYPE SUCCESS и новый пользователь авторизуется
     * Метод для регистрации нового пользователя
     */
    async authRegistrationPost(requestParameters: AuthRegistrationPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegistrationAccount> {
        const response = await this.authRegistrationPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет получить набор данных пользователя для формы изменения данных
     * Метод для получения данных пользователя
     */
    async authUseraccountGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseUserInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/useraccount/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseUserInfoFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет получить набор данных пользователя для формы изменения данных
     * Метод для получения данных пользователя
     */
    async authUseraccountGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseUserInfo> {
        const response = await this.authUseraccountGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет обновить данные пользователя в ЛК
     * Метод обновления данных пользователя в ЛК
     */
    async authUseraccountPostRaw(requestParameters: AuthUseraccountPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseUserInfo>> {
        if (requestParameters.userInfoBase === null || requestParameters.userInfoBase === undefined) {
            throw new runtime.RequiredError('userInfoBase','Required parameter requestParameters.userInfoBase was null or undefined when calling authUseraccountPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/useraccount/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInfoBaseToJSON(requestParameters.userInfoBase),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseUserInfoFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет обновить данные пользователя в ЛК
     * Метод обновления данных пользователя в ЛК
     */
    async authUseraccountPost(requestParameters: AuthUseraccountPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseUserInfo> {
        const response = await this.authUseraccountPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет обновить пароль пользователя
     * Метод изменения пароля
     */
    async authUseraccountPutRaw(requestParameters: AuthUseraccountPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseUserInfo>> {
        if (requestParameters.changePasswordModel === null || requestParameters.changePasswordModel === undefined) {
            throw new runtime.RequiredError('changePasswordModel','Required parameter requestParameters.changePasswordModel was null or undefined when calling authUseraccountPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/useraccount/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordModelToJSON(requestParameters.changePasswordModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseUserInfoFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет обновить пароль пользователя
     * Метод изменения пароля
     */
    async authUseraccountPut(requestParameters: AuthUseraccountPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseUserInfo> {
        const response = await this.authUseraccountPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
