import styled from "@emotion/styled";
import { mobileMedia } from "~constants";

type ImageProps = {
  icon: string;
  className?: string;
};

export const AvatarIcon = ({ icon, className }: ImageProps) => {
  return (
    <Container className={className}>
      <IconImage src={icon} alt="icon" />
    </Container>
  );
};

const Container = styled.div`
  height: 100px;
  width: 100px;

  ${mobileMedia} {
    height: 70px;
    width: 70px;
  }
`;

const IconImage = styled.img`
  height: 100%;
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 70px;
  background: radial-gradient(
    100% 100% at 50% 0%,
    #424242 17.92%,
    #222222 100%
  );
`;
