/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Представляет собой объект, который передает на сервер данные для бронирования коворкинга
 * @export
 * @interface UserClickRequest
 */
export interface UserClickRequest {
    /**
     * Идентификатор конкретного коворкинга.
     * @type {number}
     * @memberof UserClickRequest
     */
    EVENT_ID?: number;
    /**
     * Массив выбранных пользователем идентификаторов расписания
     * @type {Array<number>}
     * @memberof UserClickRequest
     */
    CLICK?: Array<number>;
    /**
     * Массив выбранных пользователем идентификаторов опроса цели посещения коворкинга
     * @type {Array<number>}
     * @memberof UserClickRequest
     */
    TARGET?: Array<number>;
    /**
     * Массив выбранных пользователем идентификаторов опроса желаемого оборудования при посящении коворкинга
     * @type {Array<number>}
     * @memberof UserClickRequest
     */
    EQUIPMENT?: Array<number>;
    /**
     * требуется ли посетителю локер
     * @type {boolean}
     * @memberof UserClickRequest
     */
    LOCKER?: boolean;
}

/**
 * Check if a given object implements the UserClickRequest interface.
 */
export function instanceOfUserClickRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserClickRequestFromJSON(json: any): UserClickRequest {
    return UserClickRequestFromJSONTyped(json, false);
}

export function UserClickRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserClickRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'EVENT_ID': !exists(json, 'EVENT_ID') ? undefined : json['EVENT_ID'],
        'CLICK': !exists(json, 'CLICK') ? undefined : json['CLICK'],
        'TARGET': !exists(json, 'TARGET') ? undefined : json['TARGET'],
        'EQUIPMENT': !exists(json, 'EQUIPMENT') ? undefined : json['EQUIPMENT'],
        'LOCKER': !exists(json, 'LOCKER') ? undefined : json['LOCKER'],
    };
}

export function UserClickRequestToJSON(value?: UserClickRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EVENT_ID': value.EVENT_ID,
        'CLICK': value.CLICK,
        'TARGET': value.TARGET,
        'EQUIPMENT': value.EQUIPMENT,
        'LOCKER': value.LOCKER,
    };
}

