/**
 * plural(seconds, ['секунду', 'секунды', 'секунд'])
 */
export function plural(
  n: number,
  [one, two, many]: [string, string, string],
  {
    onlyWord,
    separator = "\u00A0",
  }: { onlyWord?: boolean; separator?: string } = {}
) {
  let result = many;
  if (n % 10 === 1 && n % 100 !== 11) {
    result = one;
  }

  if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    result = two;
  }

  return onlyWord ? result : `${n}${separator}${result}`;
}
