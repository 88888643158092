import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import Boy14 from "~assets/images/B14.png";
import {
  Button,
  Captcha,
  FieldInput,
  Form,
  AvatarIcon,
  SubmitButton,
  TextLink,
  UnknownFormErrors,
  Modal,
} from "~components";
import { mobileMedia } from "~constants";
import { Palette } from "~utils/Palette";
import { object, string } from "~utils/validation";
import { useAuthFlow } from "./hooks";
import { useEffect, useState } from "react";
import { BroadCast } from "~utils/broadcast";

const authBroadcast = new BroadCast("auth");

type LoginProps = {
  resetPasswordPageUrl: string;
  registerPageUrl: string;
};
export const Login = ({
  registerPageUrl,
  resetPasswordPageUrl,
}: LoginProps) => {
  const { startAuth, settings, getUserData } = useAuthFlow();
  const [avatar, setAvatar] = useState<string>();
  const { isOpenedModal, openModal } = Modal.useModal();

  useEffect(() => {
    authBroadcast.subscribe(openModal);

    return () => authBroadcast.close();
  }, []);

  const onRegisterClick = () => {
    window.location.href = registerPageUrl;
  };

  useEffect(() => {
    if (settings?.AUTHORIZED) {
      getUserData().then((info) => {
        setAvatar(info.USER_INFO?.URL_PHOTO);
      });
    }
  }, [settings?.AUTHORIZED]);

  useEffect(() => {
    updateMenuAvatar(avatar);
  }, [avatar]);

  return (
    <Modal
      onClose={() => {}}
      open={isOpenedModal}
      muiModalProps={{
        disableEscapeKeyDown: true,
        sx: {
          // z-index у модалки регистрации на мероприятия 100 000
          zIndex: 100001,
        },
      }}
    >
      <Form
        initialValues={{ login: "", password: "", captcha: "" }}
        onSubmit={(d) =>
          startAuth({
            USER_LOGIN: d.login,
            USER_PASSWORD: d.password,
            captcha_sid: settings?.CAPTCHA_CODE?.captcha_sid,
            captcha_word: d.captcha,
          })
        }
        validationSchema={LoginUserSchema}
      >
        <Container>
          <ImageTopBarContainer>
            <AvatarIcon icon={Boy14} />
            <Typography variant="h1">Вход</Typography>
          </ImageTopBarContainer>
          <ContentContainer>
            <FieldsContainer>
              <FieldInput
                name="login"
                label="Эл.почта"
                placeholder="ivanovanov@mail.ru"
              />
              <PasswordContainer>
                <FieldInput.Password
                  label="Пароль"
                  name="password"
                  placeholder="••••••••••"
                />
                <NotRememberPasswordLink
                  variant="body3"
                  onClick={() => {
                    window.location.href = resetPasswordPageUrl;
                  }}
                  underline="none"
                >
                  Не помню пароль
                </NotRememberPasswordLink>
              </PasswordContainer>
            </FieldsContainer>
            {settings?.CAPTCHA_CODE && (
              <CaptchaContainer>
                <Captcha src={settings?.CAPTCHA_CODE?.img_src} />
                <FieldInput label="Введите слово на картинке" name="captcha" />
              </CaptchaContainer>
            )}
          </ContentContainer>
          <ButtonsContainer>
            <UnknownFormErrors />
            <LoginButton variant="first">Войти</LoginButton>
            <RegisterButton variant="third" onClick={onRegisterClick}>
              Нет аккаунта? Зарегистрируйся
            </RegisterButton>
          </ButtonsContainer>
        </Container>
      </Form>
    </Modal>
  );
};

const updateMenuAvatar = (avatar?: string) => {
  if (!avatar) return;
  // Поиск картинки аватара в шапке.
  // В Тильда задан класс для shape ссылки accaunt_avatar_image
  const link = document.querySelector<HTMLAnchorElement>(
    `.accaunt_avatar_image`
  )?.children[0];
  if (link && link?.nodeName === "A") {
    link.setAttribute(
      "style",
      `
      background-image: url('${avatar}');
      background-size: cover;
    `
    );
  }
};

const LoginUserSchema = object({
  login: string().email("Неверный формат почты").required(),
  password: string().required(),
  captcha: string(),
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  background-color: black;
  padding: 10px 0;
`;

const ImageTopBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${mobileMedia} {
    gap: 20px;
  }
`;

const FieldsContainer = styled.div`
  display: flex;
  gap: 30px;

  ${mobileMedia} {
    flex-direction: column;
  }
`;

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1 1 0;
`;

const NotRememberPasswordLink = styled(TextLink)`
  color: ${Palette["B&W/100"]};
  margin-top: 10px;
  padding-left: 15px;
`;

const CaptchaContainer = styled.div`
  display: flex;
  align-items: flex-end;
  align-self: stretch;
  gap: 30px;

  ${mobileMedia} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${mobileMedia} {
    gap: 30px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const LoginButton = styled(SubmitButton)`
  width: 100%;
`;

const RegisterButton = styled(Button)`
  width: 100%;
`;
