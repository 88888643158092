/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaptchaCodeModel } from './CaptchaCodeModel';
import {
    CaptchaCodeModelFromJSON,
    CaptchaCodeModelFromJSONTyped,
    CaptchaCodeModelToJSON,
} from './CaptchaCodeModel';

/**
 * Данный объект представляет ответ для регистрации пользователя в системе (заведение нового пользователя). При успехе вернет TYPE = SUCCESS
 * @export
 * @interface RegistrationAccount
 */
export interface RegistrationAccount {
    /**
     * Флаг успешной авторизации
     * @type {boolean}
     * @memberof RegistrationAccount
     */
    AUTHORIZED?: boolean;
    /**
     * 
     * @type {CaptchaCodeModel}
     * @memberof RegistrationAccount
     */
    CAPTCHA_CODE?: CaptchaCodeModel;
    /**
     * URL ссылка на правила коворкинга. Настраивается в админке. Название настройки: SETTINGS_CONSENT_RULES
     * @type {string}
     * @memberof RegistrationAccount
     */
    CONSENT_RULES?: string;
    /**
     * URL ссылка на информацию по обработке персональных данных. Настраивается в админке. Название настройки: SETTINGS_CONSENT_PERSONALDATA
     * @type {string}
     * @memberof RegistrationAccount
     */
    CONSENT_PERSONALDATA?: string;
    /**
     * Сообщение от API системы (при наличии)
     * @type {string}
     * @memberof RegistrationAccount
     */
    MESSAGE?: string;
    /**
     * Уровень ошибки
     * @type {string}
     * @memberof RegistrationAccount
     */
    TYPE?: string;
    /**
     * Тип ошибки
     * @type {string}
     * @memberof RegistrationAccount
     */
    ERROR_TYPE?: string;
    /**
     * Список ошибок
     * @type {Array<string>}
     * @memberof RegistrationAccount
     */
    ERRORS?: Array<string>;
}

/**
 * Check if a given object implements the RegistrationAccount interface.
 */
export function instanceOfRegistrationAccount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RegistrationAccountFromJSON(json: any): RegistrationAccount {
    return RegistrationAccountFromJSONTyped(json, false);
}

export function RegistrationAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AUTHORIZED': !exists(json, 'AUTHORIZED') ? undefined : json['AUTHORIZED'],
        'CAPTCHA_CODE': !exists(json, 'CAPTCHA_CODE') ? undefined : CaptchaCodeModelFromJSON(json['CAPTCHA_CODE']),
        'CONSENT_RULES': !exists(json, 'CONSENT_RULES') ? undefined : json['CONSENT_RULES'],
        'CONSENT_PERSONALDATA': !exists(json, 'CONSENT_PERSONALDATA') ? undefined : json['CONSENT_PERSONALDATA'],
        'MESSAGE': !exists(json, 'MESSAGE') ? undefined : json['MESSAGE'],
        'TYPE': !exists(json, 'TYPE') ? undefined : json['TYPE'],
        'ERROR_TYPE': !exists(json, 'ERROR_TYPE') ? undefined : json['ERROR_TYPE'],
        'ERRORS': !exists(json, 'ERRORS') ? undefined : json['ERRORS'],
    };
}

export function RegistrationAccountToJSON(value?: RegistrationAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AUTHORIZED': value.AUTHORIZED,
        'CAPTCHA_CODE': CaptchaCodeModelToJSON(value.CAPTCHA_CODE),
        'CONSENT_RULES': value.CONSENT_RULES,
        'CONSENT_PERSONALDATA': value.CONSENT_PERSONALDATA,
        'MESSAGE': value.MESSAGE,
        'TYPE': value.TYPE,
        'ERROR_TYPE': value.ERROR_TYPE,
        'ERRORS': value.ERRORS,
    };
}

