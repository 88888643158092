import styled from "@emotion/styled";

import { mobileMedia } from "~constants";

type CaptchaProps = {
  src?: string;
};

export const Captcha = ({ src }: CaptchaProps) => {
  return <CaptchaImage src={src} alt="captcha" />;
};

const CaptchaImage = styled.img`
  width: 295px;
  height: 60px;
  border-radius: 18px;

  ${mobileMedia} {
    width: 250px;
    height: 56px;
    border-radius: 16px;
  }
`;
