import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import { ReactComponent as MoreInfo } from "~assets/svg/more_info.svg";
import { mobileMedia } from "~constants";
import { DateService } from "~services/Date";
import { Palette } from "~utils/Palette";
import { Icon } from "../../components/images/Icon";

type CardProps = {
  title: string;
  date: Date;
  startTime: number | string;
  endTime?: number;
  additionalInfo?: string;
  onClick: () => void;
};

export const Card = ({
  title,
  date,
  startTime,
  endTime,
  additionalInfo,
  onClick,
}: CardProps) => {
  const timeText = endTime ? `${startTime}-${endTime}` : startTime;
  const dateString = DateService.fromJSDate(date)
    .toFormat("dd MMM", {
      locale: "ru",
    })
    .slice(0, 6);

  return (
    <Container onClick={onClick}>
      <MainInfo>
        <Content largeGap={!endTime}>
          <Typography variant="h4">
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Typography>
          <TimeInfo>
            <Typography variant="label1">{dateString}</Typography>
            <TimeTypography>{timeText}</TimeTypography>
          </TimeInfo>
        </Content>
        <Icon Component={MoreInfo} />
      </MainInfo>
      {additionalInfo && (
        <Education>
          <EducationInfo>
            <EducationTypography>{additionalInfo}</EducationTypography>
          </EducationInfo>
        </Education>
      )}
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  border-radius: 20px;
  background: ${Palette["B&W/10"]};

  ${mobileMedia} {
    border-radius: 15px;
  }
`;

const MainInfo = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;

const Content = styled.div<{ largeGap: boolean }>`
  display: flex;
  padding: 17px 0 15px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => (props.largeGap ? "20px" : "12px")};
  flex: 1 0 0;

  ${mobileMedia} {
    padding: 15px 0 14px 18px;
    gap: ${(props) => (props.largeGap ? "16px" : "10px")};
  }
`;

const Education = styled.div`
  display: flex;
  padding: 0 10px 10px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const EducationInfo = styled.div`
  display: flex;
  padding: 10px 12px;
  align-items: center;
  align-self: stretch;

  border-radius: 10px;
  background: ${Palette["B&W/05"]};

  ${mobileMedia} {
    padding: 9px 10px;
  }
`;

const TimeInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const TimeTypography = styled(Typography)`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;

  ${mobileMedia} {
    font-size: 19px;
    line-height: 1;
  }
`;

const EducationTypography = styled(Typography)`
  color: ${Palette["B&W/50"]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.21;

  ${mobileMedia} {
    font-size: 12px;
    line-height: 1.16;
  }
`;
