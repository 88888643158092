import {
  DateTime,
  DateTimeFormatOptions,
  DateTimeOptions,
  DiffOptions,
  Duration,
  DurationObjectUnits,
  DurationUnits,
  LocaleOptions,
} from "luxon";

export const DateService = {
  DateTime,
  parse(text: string, fmt: string, opts?: DateTimeOptions) {
    return DateTime.fromFormat(text, fmt, opts);
  },
  format: (
    date?: Date,
    options: LocaleOptions & DateTimeFormatOptions = DateTime.DATETIME_SHORT
  ) =>
    date
      ? DateTime.fromJSDate(date).toLocaleString(options)
      : "ДАТА ОТСУТСТВУЕТ!",
  toFormattedString(date: Date, fmt: string) {
    return DateTime.fromJSDate(date).toFormat(fmt, { locale: "ru" });
  },
  convertSecondsToDuration(
    s?: number,
    units: Array<keyof DurationObjectUnits> = [
      "years",
      "weeks",
      "months",
      "days",
      "hours",
      "minutes",
      "seconds",
    ]
  ) {
    if (s && s >= 0) {
      const duration = Duration.fromMillis(s * 1000);

      return duration.shiftTo(...units);
    }
    return undefined;
  },
  fromJSDate(date: Date) {
    return DateTime.fromJSDate(date);
  },
  compare(a: Date, b: Date) {
    const aTime = a.getTime();
    const bTime = b.getTime();
    return aTime === bTime ? 0 : aTime > bTime ? 1 : -1;
  },
  isEqualDates(a: Date, b: Date) {
    return (
      // @ts-ignore
      DateTime.fromJSDate(a).startOf("day").ts ===
      // @ts-ignore
      DateTime.fromJSDate(b).startOf("day").ts
    );
  },
  isToday(date?: Date) {
    return date && DateTime.fromJSDate(date).hasSame(DateTime.local(), "day");
  },
  isYesterday(date: Date) {
    const yesterday = DateTime.local().minus({ days: 1 });
    return DateTime.fromJSDate(date).hasSame(yesterday, "day");
  },
  getDiffNow(date: Date, unit?: DurationUnits, options?: DiffOptions) {
    return DateTime.fromJSDate(date).diffNow(unit, options);
  },
  toRelative(date: Date) {
    return DateTime.fromJSDate(date).setLocale("ru").toRelative();
  },
  getWeekNumber(date: Date) {
    return DateTime.fromJSDate(date).weekNumber;
  },
  isNowAndEarly(hour: number, selectedDate?: Date) {
    return DateService.isToday(selectedDate) && hour < new Date().getHours();
  },
  min(dates: Date[] = []) {
    const dt = dates.map((d) => DateService.fromJSDate(d));
    return DateService.DateTime.min(...dt);
  },
  max(dates: Date[] = []) {
    const dt = dates.map((d) => DateService.fromJSDate(d));
    return DateService.DateTime.max(...dt);
  },
};
