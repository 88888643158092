/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPlaceAuthenticationMode } from './EPlaceAuthenticationMode';
import {
    EPlaceAuthenticationModeFromJSON,
    EPlaceAuthenticationModeFromJSONTyped,
    EPlaceAuthenticationModeToJSON,
} from './EPlaceAuthenticationMode';
import type { ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS } from './ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS';
import {
    ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGSFromJSON,
    ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGSFromJSONTyped,
    ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGSToJSON,
} from './ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS';

/**
 * Информация о месте (адреае) коворкинга и его настройки
 * @export
 * @interface ResponseCoworkingCalendarDetailINFOPLACE
 */
export interface ResponseCoworkingCalendarDetailINFOPLACE {
    /**
     * Идентификатор адреса
     * @type {number}
     * @memberof ResponseCoworkingCalendarDetailINFOPLACE
     */
    ID?: number;
    /**
     * 
     * @type {EPlaceAuthenticationMode}
     * @memberof ResponseCoworkingCalendarDetailINFOPLACE
     */
    MODE?: EPlaceAuthenticationMode;
    /**
     * Указывает, разрешено ли обновлять запись в коворкинг. После успешной записи, пользователь может захотеть изменить выбранное время. Данная настройка разрешает или запрещает данное дейставие. Настройка персональна для адреса
     * @type {boolean}
     * @memberof ResponseCoworkingCalendarDetailINFOPLACE
     */
    RECORDS_UPGRATABLE?: boolean;
    /**
     * Лимит по времени бронирования. Указывает максимальное количество часов, на которое пользователь может забронировать коворкинг. Настройка персональна для адреса
     * @type {number}
     * @memberof ResponseCoworkingCalendarDetailINFOPLACE
     */
    REG_HOUR_LIMIT?: number;
    /**
     * 
     * @type {ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS}
     * @memberof ResponseCoworkingCalendarDetailINFOPLACE
     */
    LOCKER_SETTINGS?: ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS;
}

/**
 * Check if a given object implements the ResponseCoworkingCalendarDetailINFOPLACE interface.
 */
export function instanceOfResponseCoworkingCalendarDetailINFOPLACE(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseCoworkingCalendarDetailINFOPLACEFromJSON(json: any): ResponseCoworkingCalendarDetailINFOPLACE {
    return ResponseCoworkingCalendarDetailINFOPLACEFromJSONTyped(json, false);
}

export function ResponseCoworkingCalendarDetailINFOPLACEFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCoworkingCalendarDetailINFOPLACE {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ID': !exists(json, 'ID') ? undefined : json['ID'],
        'MODE': !exists(json, 'MODE') ? undefined : EPlaceAuthenticationModeFromJSON(json['MODE']),
        'RECORDS_UPGRATABLE': !exists(json, 'RECORDS_UPGRATABLE') ? undefined : json['RECORDS_UPGRATABLE'],
        'REG_HOUR_LIMIT': !exists(json, 'REG_HOUR_LIMIT') ? undefined : json['REG_HOUR_LIMIT'],
        'LOCKER_SETTINGS': !exists(json, 'LOCKER_SETTINGS') ? undefined : ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGSFromJSON(json['LOCKER_SETTINGS']),
    };
}

export function ResponseCoworkingCalendarDetailINFOPLACEToJSON(value?: ResponseCoworkingCalendarDetailINFOPLACE | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ID': value.ID,
        'MODE': EPlaceAuthenticationModeToJSON(value.MODE),
        'RECORDS_UPGRATABLE': value.RECORDS_UPGRATABLE,
        'REG_HOUR_LIMIT': value.REG_HOUR_LIMIT,
        'LOCKER_SETTINGS': ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGSToJSON(value.LOCKER_SETTINGS),
    };
}

