import styled from "@emotion/styled";
import { css, useThemeProps } from "@mui/material/styles";
import { PickersCalendarHeaderProps } from "@mui/x-date-pickers";
import {
  PickersArrowSwitcher,
  useNextMonthDisabled,
  usePreviousMonthDisabled,
  useUtils,
} from "@mui/x-date-pickers/internals";
import { ElementType } from "react";
import { SelectInput } from "~components";
import { getYearOptions, monthsItems } from "./constants";
import { DateService } from "~services/Date";
import { Palette } from "~utils/Palette";

export const CalendarHeader: ElementType<PickersCalendarHeaderProps<Date>> = (
  inProps: PickersCalendarHeaderProps<Date>
) => {
  const utils = useUtils<Date>();

  const props = useThemeProps({
    props: inProps,
    name: "MuiPickersCalendarHeader",
  });

  const {
    slots,
    slotProps,
    currentMonth: currentDate,
    disableFuture,
    disablePast,
    maxDate,
    minDate,
    onMonthChange,
    timezone,
  } = props;

  //@ts-ignore calendarHeader передается как кастомный проп
  const showMonthPicker = slotProps?.calendarHeader?.showMonthPicker ?? false;
  //@ts-ignore calendarHeader передается как кастомный проп
  const showYearPicker = slotProps?.calendarHeader?.showYearPicker ?? false;
  //@ts-ignore calendarHeader передается как кастомный проп
  const onMonthViewChange = slotProps?.calendarHeader?.onMonthViewChange;

  const selectNextMonth = () => {
    const newDate = utils.addMonths(currentDate, 1);
    onMonthViewChange(newDate);
    onMonthChange(newDate, "left");
  };

  const selectPreviousMonth = () => {
    const newDate = utils.addMonths(currentDate, -1);
    onMonthViewChange(newDate);
    onMonthChange(newDate, "right");
  };

  const isNextMonthDisabled = useNextMonthDisabled(currentDate, {
    disableFuture,
    maxDate,
    timezone,
  });

  const isPreviousMonthDisabled = usePreviousMonthDisabled(currentDate, {
    disablePast,
    minDate,
    timezone,
  });

  return (
    <HeaderContainer>
      <Selectors>
        {showMonthPicker && (
          <StyledSelect
            options={monthsItems}
            value={currentDate.getMonth()}
            onChange={(value) => {
              const newDate = new Date(currentDate.getTime());
              newDate.setMonth(Number(value));
              onMonthViewChange(newDate);
              const isLargeNewDate =
                DateService.compare(newDate, currentDate) === 1;
              onMonthChange(newDate, isLargeNewDate ? "left" : "right");
            }}
            customMenuStyles={customMenuStyles}
          />
        )}
        {showYearPicker && (
          <StyledSelect
            value={currentDate.getFullYear()}
            options={getYearOptions(
              minDate?.getFullYear(),
              maxDate?.getFullYear()
            )}
            onChange={(value) => {
              const newDate = new Date(currentDate.getTime());
              newDate.setFullYear(Number(value));
              onMonthChange(newDate, "left");
            }}
            customMenuStyles={customMenuStyles}
          />
        )}
      </Selectors>
      <PickersArrowSwitcher
        slots={slots}
        slotProps={slotProps}
        onGoToPrevious={selectPreviousMonth}
        isPreviousDisabled={isPreviousMonthDisabled}
        previousLabel={"Предыдущий месяц"}
        onGoToNext={selectNextMonth}
        isNextDisabled={isNextMonthDisabled}
        nextLabel={"Следующий месяц"}
      />
    </HeaderContainer>
  );
};

const Selectors = styled.div`
  display: flex;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  height: 54px;
  padding: 5px 10px;
  box-sizing: border-box;
`;

const StyledSelect = styled(SelectInput)`
  .MuiSelect-outlined {
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
  }
  .MuiInputBase-root {
    svg {
      top: calc(50% - 0.6em);
    }

    fieldset {
      border: 0;
    }

    .MuiSelect-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 44px;
      box-sizing: border-box;
      padding-right: 0px !important;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;
const customMenuStyles = css`
  background: ${Palette["B&W/20"]};
  max-height: 276px;
  margin-top: -8px;
  margin-bottom: -8px;
  border-radius: 9px;
  min-width: 89px;
  & ul {
    padding-top: 7px;
  }
  & li:last-child {
    align-items: start;
    &:after {
      display: block;
      content: "";
      min-height: 24px;
      margin-bottom: 10px;
    }
  }
  & label {
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
  }
`;
