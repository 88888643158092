import { css } from "@emotion/react";

export const adaptive = {
  xs: 320,
  sm: 640,
  md: 960,
  lg: 1100,
  xl: 1400,
  xxl: 1920,
};

export const devices = {
  mobile: `(min-width: ${adaptive.xs}px) and (max-width: ${adaptive.sm}px)`,
  tablet: `(min-width: ${adaptive.sm}px) and (max-width: ${adaptive.md}px)`,
  desktop: `(min-width: ${adaptive.md}px)`,
};

export const mobileMedia = `@media only screen and ${devices.mobile}`;
export const tabletMedia = `@media only screen and ${devices.tablet}`;
export const desktopMedia = `@media only screen and ${devices.desktop}`;
