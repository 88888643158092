import { intersection } from "lodash";
import {
  PlaceMenuItem,
  ResponseCoworkingCalendarDetail,
  ResponseCoworkingCalendarList,
} from "prosto-api-client";
import { DATE_FORMAT } from "~constants";
import { DateService } from "~services/Date";
import { PlaceInfo } from "./CoWorkingPicker";

export const parseEvent = (event?: ResponseCoworkingCalendarDetail) => {
  const hasAppointmentOnEvent = event?.INFO?.USER?.REGISTERED;
  const canEditAppointment = event?.INFO?.PLACE?.RECORDS_UPGRATABLE;
  const lockerSettings = event?.INFO?.PLACE?.LOCKER_SETTINGS;
  const bookLocker = event?.INFO?.USER?.LOCKER;
  const lockerInfo = event?.INFO?.USER?.QR_LOCKER;
  const eqItems = event?.SURVEY_EQUIPMENT?.ITEMS;
  const isVerified = event?.INFO?.USER?.VERIFIED;
  const qrMain = event?.INFO?.USER?.QR_MAIN;
  const qrVerification = event?.INFO?.USER?.QR_VERIFICATION;

  const equipments = eqItems && Object.values(eqItems);

  // 8.11.2023 коворкинги без времени
  const eventDate =
    event?.INFO?.DATE &&
    DateService.parse(event?.INFO?.DATE, DATE_FORMAT).toJSDate();

  const time =
    event?.INFO?.USER?.RECORDS &&
    Object.keys(event?.INFO?.USER?.RECORDS).map((t) => Number(t));

  const startTimeId = time?.[0];
  const endTimeId = time?.[time?.length - 1];

  const startTime =
    // @ts-ignore
    event?.SCHEDULE?.[startTimeId]?.HOUR;
  const endTime =
    // @ts-ignore
    event?.SCHEDULE?.[endTimeId]?.HOUR + 1;
  return {
    qrMain,
    qrVerification,
    isVerified,
    hasAppointmentOnEvent,
    canEditAppointment,
    startTime,
    endTime,
    timeIntervalIds: time,
    lockerSettings,
    lockerInfo,
    equipments,
    eventDate,
    bookLocker,
  };
};

export const getRegisteredPlaces = (
  items?: ResponseCoworkingCalendarList["items"],
  reservedEvents?: string[]
) =>
  items &&
  Object.keys(items)
    .map((itm) => {
      const evnts = items?.[itm] && Object.values(items?.[itm]);
      return intersection(evnts, reservedEvents).length ? itm : undefined;
    })
    .filter(Boolean);

type PlaceMenuItemWithId = { id: string } & PlaceMenuItem;
export const mapPlaceItemsToPoints = ({
  itms,
  activePointId,
  frequentVisitPlaceId,
  registeredPlaces,
}: {
  itms?: PlaceMenuItemWithId[];
  frequentVisitPlaceId?: string;
  activePointId?: string;
  registeredPlaces?: (string | undefined)[];
}) =>
  itms?.map<PlaceInfo>((itm) => ({
    id: itm.id,
    address: itm.name!,
    metro: itm.metro_stations && Object.values(itm.metro_stations)[0].NAME,
    // Логика переключения состояний между Frequent visit и Existing event
    // Всегда отдавать привилегию Existing event.
    // Если на точку, которую часто посещает пользователь, уже есть запись, то выводить Existing event.
    // Если записи на точку нет, но человек часто посещает ее, то отображается Frequent visit.
    // Если записи нет и человек не часто посещает точку, то блок Info bar скрывается
    infoBarContent: registeredPlaces?.includes(itm?.id)
      ? "Уже есть запись"
      : frequentVisitPlaceId === itm.id
      ? "Часто ходишь сюда"
      : undefined,
    fewPlacesLeft: Boolean(itm.coworking_little_space_left),
    disabled: itm.coworking_reg_disabled,
    imgUrl: itm.img_preview!,
    active: itm.id === activePointId,
    geoLink: itm.address_ya_map,
  }));
