import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Button } from "~components";
import { Palette } from "~utils/Palette";

type ItemProps = {
  label: string;
  value: number;
  active?: boolean;
  onClick?: (value?: number) => void;
  disabled?: boolean;
};

export const Item = ({
  label,
  value,
  active,
  disabled = false,
  onClick,
}: ItemProps) => {
  return (
    <Container selected={active}>
      <InnerButton
        disableRipple={true}
        selected={active}
        disabled={disabled}
        onClick={() => onClick?.(active ? undefined : value)}
      >
        <Text variant="h4" dark={active || disabled}>
          {label}
        </Text>
      </InnerButton>
    </Container>
  );
};

const Container = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1 0 0;
  padding: ${({ selected }) => (!selected ? "3px" : "0")};
`;

const InnerButton = styled(Button)<{ selected?: boolean; disabled: boolean }>`
  height: auto;
  width: auto;
  padding: ${({ selected }) => (selected ? "13px 5px 11px" : "10px 5px 8px")};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: ${({ selected }) => (selected ? "10px" : "8px")};
  background: ${({ disabled, selected }) =>
    disabled
      ? Palette["B&W/05"]
      : selected
      ? `${Palette["B&W/100"]} !important`
      : Palette["B&W/10"]};

  &:hover {
    background: ${Palette["B&W/20"]};
  }

  &:active {
    background: ${Palette["B&W/05"]};
  }
`;

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "dark",
})<{ dark: boolean }>`
  color: ${({ dark }) => (dark ? Palette["B&W/00"] : Palette["B&W/100"])};
`;
