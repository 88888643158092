import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import { ReactComponent as MoreInfo } from "~assets/svg/more_info.svg";
import { ReactComponent as AvatarPlaceholder } from "~assets/svg/avatar_placeholder.svg";

import { AvatarIcon } from "~components";
import { mobileMedia } from "~constants";
import { Palette } from "~utils/Palette";
import { plural } from "~utils/Plural";
import { Icon } from "../../components/images/Icon";

type UserCardProps = {
  name: string;
  age?: number | string;
  avatarUrl?: string;
  onClick: () => void;
};

export const UserCard = ({ name, age, avatarUrl, onClick }: UserCardProps) => {
  const ageText =
    age && plural(Math.floor(Number(age)), ["год", "года", "лет"]);

  return (
    <Container onClick={onClick}>
      <Content>
        {avatarUrl ? (
          <AvatarIcon icon={avatarUrl} />
        ) : (
          <Icon Component={AvatarPlaceholder} />
        )}
        <UserInfo>
          <Typography variant="h3">{name}</Typography>
          {age && <Typography variant="label1">{ageText}</Typography>}
        </UserInfo>
      </Content>
      <Icon Component={MoreInfo} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  cursor: pointer;

  border-radius: 20px;
  background: ${Palette["B&W/10"]};

  ${mobileMedia} {
    border-radius: 15px;
  }
`;

const Content = styled.div`
  display: flex;
  padding: 20px 0 20px 20px;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;

  ${mobileMedia} {
    padding: 15px 0 14px 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 12px;

  ${mobileMedia} {
    gap: 10px;
  }
`;
