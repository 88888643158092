import * as Yup from "yup";
import { DateService } from "~services/Date";
import { plural } from "~utils/Plural";

Yup.setLocale({
  mixed: {
    required: "Заполни поле",
    notType: ({ type }: any) =>
      type === "number" ? "Введи число" : "Некорректное значение",
  },
  string: {
    max: ({ max }: any) =>
      `Максимум ${plural(max, ["символ", "символа", "символов"])}`,
    min: ({ min }: any) =>
      `Минимум ${plural(min, ["символ", "символа", "символов"])}`,
    email: "Введи валидный email",
    // eslint-disable-next-line no-template-curly-in-string
    matches: '${path} должно соответствовать: "${regex}"',
    // @ts-ignore
    required: "Заполни поле",
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    max: "Максимальное значение - ${max}",
    // eslint-disable-next-line no-template-curly-in-string
    min: "Минимальное значение - ${min}",
    integer: "Введи целое число",
  },
  array: {
    // @ts-ignore
    required: "Заполни поле",
    min: ({ min }: any) =>
      `Минимум ${plural(min, ["элемент", "элемента", "элементов"])}`,
    max: ({ max }: any) =>
      `Максимум ${plural(max, ["элемент", "элемента", "элементов"])}`,
  },
  date: {
    // @ts-ignore
    required: "Введи дату",
    max: ({ max }: any) => `Максимальная дата ${DateService.format(max)}`,
    min: ({ min }: any) => `Минимальная дата ${DateService.format(min)}`,
  },
});

type DateRangeOptions = {
  getMinStartDate?: (end?: Date) => Date;
  getMinEndDate?: (start?: Date) => Date;
};

export const DateRangeSchema = ({
  getMinStartDate,
  getMinEndDate,
}: DateRangeOptions = {}) =>
  Yup.object().shape(
    {
      // @ts-ignore
      start: Yup.date().when("end", (end: Date | undefined, schema: any) => {
        if (!getMinStartDate) return schema;

        const min = getMinStartDate(end);
        return schema.min(
          min,
          `Минимальная левая дата ${DateService.format(min)}`
        );
      }),
      // @ts-ignore
      end: Yup.date().when("start", (start: Date | undefined, schema: any) => {
        if (getMinEndDate) {
          const min = getMinEndDate(start);
          return schema.min(
            min,
            `Минимальная правая дата ${DateService.format(min)}`
          );
        }

        if (start) {
          return schema.min(start, "Правая дата не может быть раньше левой");
        }

        return schema;
      }),
    },
    [
      ["start", "end"],
      ["end", "start"],
    ]
  );

// @ts-ignore
export const OptionSchema: Yup.SchemaOf<Option> = Yup.object({
  label: Yup.string().required(),
  value: Yup.string(),
});

const phoneRegExp = /^\+7 ([0-9]{3}) ([0-9]{3}) ([0-9]{2}) ([0-9]{2})?$/;
export const phoneValidator = Yup.string()
  .matches(phoneRegExp, "Неверный формат телефона")
  .required();

const EMAIL_PATTERN =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
export const emailValidator = Yup.string()
  .matches(EMAIL_PATTERN, "Некорректная почта")
  .required();

export * from "yup";
