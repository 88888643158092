import { useEffect } from "react";
import { ReactMaskProps, useIMask } from "react-imask";
import { InputMaskElement, FactoryOpts } from "imask";

import { TextField, TextFieldProps } from "./TextField";
type Handlers = Partial<
  Pick<ReactMaskProps<InputMaskElement, FactoryOpts>, "onAccept" | "onComplete">
>;
export type MaskedInputProps = TextFieldProps & {
  config: FactoryOpts;
} & Handlers;
export const MaskedInput = ({
  config,
  onAccept,
  onComplete,
  value,
  ...rest
}: MaskedInputProps) => {
  const { ref, setValue } = useIMask(config, { onAccept, onComplete });
  useEffect(() => {
    value && setValue(value as string);
  }, [value]);

  return <TextField inputRef={ref} {...rest} />;
};
