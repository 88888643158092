/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * При необходимости прохождения captha, данный объект будет заполнен информацией captcha
 * @export
 * @interface CaptchaCodeModel
 */
export interface CaptchaCodeModel {
    /**
     * Идентификатор captha
     * @type {string}
     * @memberof CaptchaCodeModel
     */
    captcha_sid?: string;
    /**
     * относительный адрес картинки для captcha
     * @type {string}
     * @memberof CaptchaCodeModel
     */
    img_src?: string;
}

/**
 * Check if a given object implements the CaptchaCodeModel interface.
 */
export function instanceOfCaptchaCodeModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CaptchaCodeModelFromJSON(json: any): CaptchaCodeModel {
    return CaptchaCodeModelFromJSONTyped(json, false);
}

export function CaptchaCodeModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaptchaCodeModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'captcha_sid': !exists(json, 'captcha_sid') ? undefined : json['captcha_sid'],
        'img_src': !exists(json, 'img_src') ? undefined : json['img_src'],
    };
}

export function CaptchaCodeModelToJSON(value?: CaptchaCodeModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'captcha_sid': value.captcha_sid,
        'img_src': value.img_src,
    };
}

