import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { ReactComponent as CalendarFilledView } from "~assets/svg/calendar_filled_head_view.svg";
import { ReactComponent as CalendarOutlinedView } from "~assets/svg/calendar_outlined_view.svg";
import { ReactComponent as TableFilledView } from "~assets/svg/table_filled_view.svg";
import { ReactComponent as TableOutlinedView } from "~assets/svg/table_outlined_view.svg";
import { mobileMedia } from "~constants";
import { ButtonIconInfo } from "~modules/co-working/ButtonSwitch";
import { TitleWithViewModes } from "~modules/co-working/TitleWithViewModes";
import { DatePicker, WeekCalendar } from "../../components/datepicker";
import { useFormContext } from "~components";
import { DateService } from "~services/Date";

type CoWorkingDatePickerProps = {
  name: string;
  onSelect: (date: Date) => void;
  enabledDates?: Date[];
  appointmentDates?: Date[];
};

export const CoWorkingDatePicker = ({
  onSelect,
  name,
  enabledDates,
  appointmentDates,
}: CoWorkingDatePickerProps) => {
  const [mode, setMode] = useState<"calendar" | "table">("table");
  const isTableMode = mode === "table";
  const { setFieldValue, initialValues } = useFormContext<{
    time: number[];
    date: Date;
  }>();
  const defaultSelected = initialValues.date;

  const [date, setDate] = useState(defaultSelected);
  useEffect(() => {
    setFieldValue(name, date);
    onSelect?.(date);
  }, [date?.toISOString()]);

  const viewModes: ButtonIconInfo<"calendar" | "table">[] = [
    {
      name: "calendar",
      Component: CalendarFilledView,
      ActiveComponent: CalendarOutlinedView,
    },
    {
      name: "table",
      Component: TableOutlinedView,
      ActiveComponent: TableFilledView,
    },
  ];

  return (
    <Container>
      <TitleWithViewModes
        title="Выбери дату"
        onChange={setMode}
        viewModes={viewModes}
        mode={mode}
      />
      {!isTableMode && (
        <DatePicker
          date={date}
          // @ts-ignore
          onChange={setDate}
          enabledDates={enabledDates}
          appointmentDates={appointmentDates}
          minDate={new Date()}
          maxDate={DateService.max(enabledDates)?.toJSDate()}
        />
      )}
      {isTableMode && (
        <WeekCalendar
          date={date}
          setDate={setDate}
          defaultSelected={defaultSelected}
          enabledDates={enabledDates}
          appointmentDates={appointmentDates}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 20px;

  ${mobileMedia} {
    gap: 10px;
  }
`;
