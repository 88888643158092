import styled from "@emotion/styled";
import { Typography, useMediaQuery } from "@mui/material";
import * as React from "react";
import { useMemo, useState } from "react";

import Boy2 from "~assets/images/B02.png";
import {
  Button,
  Form,
  AvatarIcon,
  Modal,
  SubmitButton,
  SuccessfulRequestModal,
} from "~components";
import { DATE_FORMAT, devices, mobileMedia } from "~constants";
import { CoWorkingDatePicker } from "~modules/co-working/CoWorkingDatePicker";
import { CoWorkingPicker } from "~modules/co-working/CoWorkingPicker";
import { CoworkingTimePicker } from "~modules/co-working/CoworkingTimePicker";
import { Locker } from "~modules/co-working/Locker";
import { DateService } from "~services/Date";
import { mapObjToList } from "~utils/objectMapperToList";
import {
  array,
  boolean,
  date,
  number,
  object,
  string,
} from "~utils/validation";
import { useCoworkingApi, useCoworkingEventsApi } from "./hooks";
import { useQueryParams } from "~utils/useQueryParams";
import {
  parseEvent,
  mapPlaceItemsToPoints,
  getRegisteredPlaces,
} from "./utils";

const MAX_WORKING_HOUR = 22;
const isTimeMoreThanWorkingHours = new Date().getHours() > MAX_WORKING_HOUR;
const today = new Date();
const tomorrow = DateService.DateTime.now().plus({ day: 1 }).toJSDate();

type CoWorkingRegistrationProps = {
  modalLockerAnchor: string;
  activePlaceId?: string;
};
export const CoWorkingRegistration = ({
  activePlaceId,
  modalLockerAnchor,
}: CoWorkingRegistrationProps) => {
  const value = useQueryParams().getValue("activePlaceId");
  const isMobileView = useMediaQuery(devices.mobile);
  const {
    placeMenuItems,
    registerOn,
    cancelRegistration,
    reservedEvents,
    isWaitingBookLocker,
  } = useCoworkingApi();

  const [activePointId, setActivePointId] = useState<string | undefined>(
    value || activePlaceId
  );
  const [activeDate, setActiveDate] = useState<string | undefined>();
  const { closeModal, isOpenedModal, openModal } = Modal.useModal();

  // @ts-ignore
  const eventId = placeMenuItems?.items?.[activePointId]?.[activeDate];

  const { enabledDates, appointmentDates } = useMemo(() => {
    const dates: { date: Date; reserved?: boolean }[] =
      // @ts-ignore
      placeMenuItems?.items[activePointId] &&
      Object.entries(
        // @ts-ignore
        placeMenuItems?.items[activePointId]
      ).map(([dt, eid]) => ({
        date: DateService.parse(dt, DATE_FORMAT).toJSDate(),
        reserved: reservedEvents?.includes(eid as string),
      }));
    const apDates = dates?.filter((d) => d.reserved);

    return {
      enabledDates: dates
        ?.map((d) => d.date)
        .filter((d) => !(DateService.isToday(d) && today.getHours() > 21)),
      appointmentDates: apDates?.map((d) => d.date),
    };
  }, [activePointId, placeMenuItems?.items, eventId, reservedEvents?.length]);

  const { event } = useCoworkingEventsApi(eventId);

  const {
    endTime,
    startTime,
    timeIntervalIds,
    equipments,
    lockerSettings,
    eventDate,
    canEditAppointment,
    hasAppointmentOnEvent,
    lockerInfo,
    isVerified,
    qrMain,
    qrVerification,
    bookLocker,
  } = parseEvent(event);

  const places = mapPlaceItemsToPoints({
    itms: mapObjToList(placeMenuItems?.place_menu?.places),
    activePointId,
    frequentVisitPlaceId: placeMenuItems?.frequent_visit_place,
    registeredPlaces: getRegisteredPlaces(
      placeMenuItems?.items,
      reservedEvents
    ),
  });

  const registerPlace = places?.find((p) => p.active);

  const initialValues = {
    place: activePlaceId || activePointId,
    date: isTimeMoreThanWorkingHours ? tomorrow : today,
    locker: bookLocker,
    time: timeIntervalIds,
    equipments: undefined,
  };
  return (
    <>
      <Container>
        <ImageTopBarContainer>
          <AvatarIcon icon={Boy2} />
          <Typography variant="h1">Запись в коворкинг</Typography>
        </ImageTopBarContainer>
        <ContentForm
          initialValues={initialValues}
          validationSchema={CoworkingRegisterSchema}
          onSubmit={async (d) => {
            // TODO: Функционал выбора оборудования реализован, но пока не готова инфраструктура.
            // Отсылать данные только после согласования с заказчиком
            await registerOn({
              EVENT_ID: eventId,
              CLICK: d.time,
              LOCKER: d.locker,
            });
            openModal();
          }}
        >
          {({ dirty }) => {
            return (
              <>
                <MainInfo>
                  <PickerContainer>
                    <CoWorkingPicker
                      places={places}
                      setActivePlace={(pid) => {
                        setActivePointId(pid);
                      }}
                      activePlaceId={activePointId}
                      equipment={equipments}
                    />
                    {!isMobileView && (
                      <Locker
                        disabled={!canEditAppointment}
                        settings={lockerSettings}
                        modalLockerAnchor={modalLockerAnchor}
                      />
                    )}
                  </PickerContainer>
                  <PickerContainer>
                    <CoWorkingDatePicker
                      onSelect={(d) => {
                        const date = DateService.toFormattedString(
                          d,
                          DATE_FORMAT
                        );
                        setActiveDate(date);
                      }}
                      name="date"
                      appointmentDates={appointmentDates}
                      enabledDates={enabledDates}
                    />
                    <TimePickerContainer>
                      <TimePickerTitleContainer>
                        <Typography variant="h2">Выбери время</Typography>
                      </TimePickerTitleContainer>
                      <CoworkingTimePicker
                        disabled={!canEditAppointment}
                        regHourLimit={event?.INFO?.PLACE?.REG_HOUR_LIMIT}
                        records={event?.RECORDS}
                        schedule={event?.SCHEDULE}
                        name="time"
                        selectedDate={
                          activeDate
                            ? DateService.parse(
                                activeDate,
                                DATE_FORMAT
                              ).toJSDate()
                            : undefined
                        }
                      />
                    </TimePickerContainer>
                  </PickerContainer>
                </MainInfo>
                {isMobileView && (
                  <Locker
                    disabled={!canEditAppointment}
                    settings={lockerSettings}
                    modalLockerAnchor={modalLockerAnchor}
                  />
                )}
                {hasAppointmentOnEvent ? (
                  canEditAppointment && dirty ? (
                    <SubmitButton fullWidth>Изменить запись</SubmitButton>
                  ) : (
                    <Button fullWidth onClick={openModal}>
                      Показать QR код
                    </Button>
                  )
                ) : (
                  <SubmitButton fullWidth>Зарегистрироваться</SubmitButton>
                )}
              </>
            );
          }}
        </ContentForm>
      </Container>
      {event && registerPlace && eventDate && (
        <SuccessfulRequestModal
          geoLink={registerPlace.geoLink}
          isWaitingBookLocker={isWaitingBookLocker}
          open={isOpenedModal}
          onClose={closeModal}
          onCancelRegister={() => {
            eventId && cancelRegistration(eventId);
            closeModal();
          }}
          qrContent={isVerified ? qrMain : qrVerification}
          placeName={registerPlace?.address}
          date={eventDate}
          startTime={startTime}
          endTime={endTime}
          userVerified={isVerified}
          lockerInfo={lockerInfo}
          onAddLocker={
            lockerSettings?.EXISTS && !event.INFO?.USER?.LOCKER
              ? async () => {
                  await registerOn({
                    EVENT_ID: eventId,
                    CLICK: timeIntervalIds,
                    LOCKER: true,
                  });
                }
              : undefined
          }
        />
      )}
    </>
  );
};

const CoworkingRegisterSchema = object({
  place: string().required(),
  date: date().required(),
  locker: boolean(),
  time: array().of(number()).required(),
  equipments: array().of(number()),
});

const Container = styled.div`
  background-color: black;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 60px;

  ${mobileMedia} {
    align-items: center;
  }
`;

const MainInfo = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 60px;

  ${mobileMedia} {
    flex-direction: column;
  }
`;

const ImageTopBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  gap: 40px;
  flex: 1 0 0;

  ${mobileMedia} {
    gap: 20px;
    width: 50%;
  }
`;

const ContentForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 60px;
` as typeof Form;

const PickerContainer = styled.div`
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  flex: 1 0 0;
  align-self: stretch;

  ${mobileMedia} {
    width: auto;
    gap: 50px;
  }
`;

const TimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  ${mobileMedia} {
    gap: 10px;
  }
`;

const TimePickerTitleContainer = styled.div`
  display: flex;
  padding: 1px 0;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;

  ${mobileMedia} {
    padding: 7px 0 6px;
  }
`;
