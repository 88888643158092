import { css } from "@emotion/react";
import styled from "@emotion/styled";
import MuiModal from "@mui/material/Modal";
import { ModalProps as MuiModalProps } from "@mui/material";

import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import { mobileMedia } from "~constants";
import { Palette } from "~utils/Palette";

type ModalProps = {
  open?: boolean;
  onClose: MuiModalProps["onClose"];
  children?: React.ReactNode;
  autoclose?: boolean | number;
  fullPage?: boolean;
  className?: string;
  muiModalProps?: Omit<MuiModalProps, "open" | "children">;
};

const Modal = ({
  open = false,
  onClose,
  children,
  autoclose = false,
  fullPage = false,
  className,
  muiModalProps,
  ...rest
}: ModalProps) => {
  useEffect(() => {
    if (open && autoclose) {
      const milliseconds = typeof autoclose === "number" ? autoclose : 3000;
      // @ts-ignore
      setTimeout(() => onClose?.(), milliseconds);
    }
  }, [open]);

  return (
    <MuiModal open={open} onClose={onClose} {...muiModalProps}>
      <Container fullPage={fullPage} className={className} {...rest}>
        {!className ? <InnerContainer>{children}</InnerContainer> : children}
      </Container>
    </MuiModal>
  );
};

const Container = styled.div<{ fullPage: boolean }>`
  outline: none;
  max-width: 620px;
  max-height: 100%;

  display: flex;

  width: 100%;
  padding: 29px 28px;
  box-sizing: border-box;

  border-radius: 30px;
  border: 2px solid ${Palette["B&W/10"]};
  background: ${Palette["B&W/00"]};

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
  }

  &::-webkit-scrollbar-button {
    height: 0;
  }

  ${mobileMedia} {
    padding: 20px;
    max-width: calc(100% - 20px);
    border-radius: 20px;
  }

  ${(props) =>
    props.fullPage &&
    css`
      padding: 0;
      padding-top: 90px; /* Высота меню + отступ 20px */
      justify-content: center;
      height: 100%;
      max-width: 100%;
      border-width: 0;
      border-radius: 0;

      ${mobileMedia} {
        padding: 40px 10px;
        max-width: calc(100% - 20px);
      }
    `};
`;

const InnerContainer = styled.div`
  max-width: 620px;
  max-height: 100%;
  width: 100%;
`;

const useModal = (isOpenedByDefault = false) => {
  const [isOpenedModal, setIsOpenedModal] = useState(isOpenedByDefault);
  const closeModal = useCallback(() => setIsOpenedModal(false), []);
  const openModal = useCallback(() => setIsOpenedModal(true), []);

  return {
    closeModal,
    openModal,
    isOpenedModal,
  };
};

Modal.useModal = useModal;

export { Modal };
