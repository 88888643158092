import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as Point } from "~assets/svg/point.svg";
import { devices, mobileMedia } from "~constants";
import { DateService } from "~services/Date";
import { Palette } from "~utils/Palette";
import { weekdays } from "../constants";

const mobilePointSize = 4;
const desktopPointSize = 7;

type DayCellProps = {
  date: Date;
  selected: Date;
  onSelect: (date: Date) => void;
  disabled?: boolean;
  hasAppointment?: boolean;
};

export const DayCell = ({
  date,
  selected,
  onSelect,
  disabled = false,
  hasAppointment = false,
}: DayCellProps) => {
  const isMobileView = useMediaQuery(devices.mobile);

  const currentSelected =
    selected.getFullYear() === date.getFullYear() &&
    selected.getMonth() === date.getMonth() &&
    selected.getDate() === date.getDate();

  const handleCellClick = () => {
    if (!currentSelected) {
      onSelect(date);
    }
  };

  return (
    <Container selected={currentSelected}>
      <StyledButton
        hasAppointment={hasAppointment}
        disabled={disabled}
        selected={currentSelected}
        onClick={handleCellClick}
      >
        <TextContainer selected={currentSelected} disabled={disabled}>
          <Text
            selected={currentSelected}
            disabled={disabled}
            variant={"label3Thin"}
          >
            {weekdays[date.getDay()]}
          </Text>
          <Text selected={currentSelected} disabled={disabled} variant={"h4"}>
            {DateService.toFormattedString(date, "dd")}
          </Text>
        </TextContainer>
        {hasAppointment && (
          <PointSvg
            fill={currentSelected ? Palette["B&W/00"] : Palette["B&W/100"]}
            height={isMobileView ? mobilePointSize : desktopPointSize}
            width={isMobileView ? mobilePointSize : desktopPointSize}
          />
        )}
      </StyledButton>
    </Container>
  );
};

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  box-sizing: border-box;
  ${mobileMedia} {
    height: 60px;
    padding: ${({ selected }) => (selected ? "0px" : "2px")};
  }
`;

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "hasAppointment",
})<{
  selected: boolean;
  disabled: boolean;
  hasAppointment: boolean;
}>`
  height: auto;
  width: 100%;
  min-width: auto;
  padding: 17px 12px 15px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 15px;
  background-color: ${({ selected, disabled }) =>
    disabled
      ? Palette["B&W/05"]
      : selected
      ? Palette["B&W/100"]
      : Palette["B&W/10"]};

  &:hover {
    background-color: ${({ selected }) =>
      selected ? Palette["B&W/100"] : Palette["B&W/00"]};
  }

  ${mobileMedia} {
    border-radius: 10px;
    padding: 10px 5px 7px;
  }

  ${(props) =>
    props.hasAppointment &&
    css`
      padding-bottom: ${props.selected ? "8px" : "6px"};
      ${mobileMedia} {
        padding-bottom: ${props.selected ? "9px" : "7px"};
      }
    `};
`;

const TextContainer = styled.div<{ selected: boolean; disabled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;

  ${mobileMedia} {
    gap: 2px;
  }
`;

const Text = styled(Typography)<{ selected: boolean; disabled: boolean }>`
  color: ${({ selected, disabled }) =>
    disabled || selected ? Palette["B&W/00"] : Palette["B&W/100"]};
`;

const PointSvg = styled(Point)`
  padding-top: 2px;

  ${mobileMedia} {
    padding-top: 0;
    margin-top: -4px;
  }
`;
