import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useRef } from "react";
import { ReactComponent as CautionCircle } from "~assets/svg/caution_circle.svg";

import { ReactComponent as ColumnView } from "~assets/svg/column_view.svg";
import { ReactComponent as ListView } from "~assets/svg/list_view.svg";
import { mobileMedia } from "~constants";
import { ButtonIconInfo } from "~modules/co-working/ButtonSwitch";
import { Card } from "~modules/co-working/Card";
import { EquipmentInfo } from "~modules/co-working/EquipmentInfo";
import { TitleWithViewModes } from "~modules/co-working/TitleWithViewModes";
import { Palette } from "~utils/Palette";
import { CoworkingPickerMode, useCoworkingPickerMode } from "./hooks";
import { EquipmentItem } from "prosto-api-client";
import { useFormContext } from "~components";

export type PlaceInfo = {
  id: string;
  address: string;
  metro?: string;
  infoBarContent?: string;
  fewPlacesLeft: boolean;
  disabled?: boolean;
  imgUrl: string;
  active: boolean;
  geoLink?: string;
};

type CoWorkingPickerProps = {
  places?: PlaceInfo[];
  equipment?: EquipmentItem[];
  activePlaceId?: string;
  setActivePlace: (pid: PlaceInfo["id"]) => void;
};

export const CoWorkingPicker = ({
  places,
  activePlaceId,
  equipment,
  setActivePlace,
}: CoWorkingPickerProps) => {
  const { setFieldValue, values } = useFormContext<{
    equipments: number[];
  }>();
  const pickerRef = useRef<HTMLDivElement>(null);
  const activePlace = places?.find((p) => p.id === activePlaceId);
  const { mode, setMode } = useCoworkingPickerMode();
  const viewModes: ButtonIconInfo<CoworkingPickerMode>[] = [
    { name: "row", Component: ListView },
    { name: "column", Component: ColumnView },
  ];

  const isColumnView = mode === "column";
  const PickerContainer = isColumnView
    ? ColumnViewPickerContainer
    : RowViewPickerContainer;

  return (
    <Container>
      <MainInfoContainer>
        <TitleWithViewModes
          title="Выбери точку"
          onChange={(mode) => {
            setMode(mode);
            if (pickerRef.current) {
              pickerRef.current.scrollTop = 0;
            }
          }}
          viewModes={viewModes}
          mode={mode}
        />
        <AddressInfoContainer>
          <PickerContainer ref={pickerRef}>
            <CardsContainer isColumnView={isColumnView}>
              {places?.map((point) => (
                <Card
                  key={point.address}
                  mode={mode}
                  point={point}
                  onClick={() => {
                    setFieldValue("place", point.id);
                    setActivePlace(point.id);
                  }}
                  active={point.id === activePlaceId}
                />
              ))}
              {!isColumnView && <ExtraScrollEndSpace />}
            </CardsContainer>
          </PickerContainer>
          {!!equipment?.length && (
            <EquipmentInfo
              equipment={equipment}
              onClickEquipment={(eqItem) => {
                // TODO: Включить при готовности инфраструктуры
                return;
                if (values?.equipments) {
                  if (values?.equipments.includes(eqItem.ID!)) {
                    setFieldValue(
                      "equipments",
                      values?.equipments.filter((e) => e !== eqItem.ID)
                    );
                  } else {
                    setFieldValue("equipments", [
                      ...values?.equipments,
                      eqItem.ID,
                    ]);
                  }
                } else {
                  setFieldValue("equipments", [eqItem.ID]);
                }
              }}
            />
          )}
        </AddressInfoContainer>
      </MainInfoContainer>
      {activePlace?.fewPlacesLeft && (
        <CautionContainer>
          <IconContainer>
            <CautionCircle fill={Palette["B&W/100"]} />
          </IconContainer>
          <CautionTextContainer>
            <Typography variant="body3">Здесь осталось мало мест</Typography>
          </CautionTextContainer>
        </CautionContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;

  ${mobileMedia} {
    gap: 10px;
  }
`;

const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  ${mobileMedia} {
    gap: 10px;
  }
`;

const GeneralPickerContainerStyles = css`
  box-sizing: border-box;
  background-color: ${Palette["B&W/00"]};

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-self: stretch;

  border-radius: 25px;
  border: 2px solid ${Palette.darkGrey};

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background: ${Palette["B&W/20"]};

    border-style: solid;
    border-color: ${Palette["B&W/00"]};
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-button {
    height: 10px;
    width: 10px;
  }

  ${mobileMedia} {
    height: 240px;
    border-radius: 20px;
  }
`;

const RowViewPickerContainer = styled.div`
  ${GeneralPickerContainerStyles};

  height: 310px;
  padding: 10px 0px 0px 10px;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    height: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border-width: 8px;
  }

  ${mobileMedia} {
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-width: 3px;
    }
  }
`;

const ColumnViewPickerContainer = styled.div`
  ${GeneralPickerContainerStyles};

  padding: 8px 8px 2px;

  overflow-y: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-width: 3px;
  }

  ${mobileMedia} {
    &::-webkit-scrollbar-thumb {
      border-width: 3px;
    }
    &::-webkit-scrollbar {
      height: 8px;
    }
  }
`;

const ExtraScrollEndSpace = styled.div`
  width: 1px;
  height: 1px;

  display: flex;
  flex-shrink: 0;
`;

const CardsContainer = styled.div<{ isColumnView: boolean }>`
  display: flex;
  flex-direction: ${({ isColumnView }) => (isColumnView ? "row" : "column")};
  align-self: ${({ isColumnView }) => (isColumnView ? "auto" : "stretch")};
  align-items: flex-start;
  gap: 10px;
  ${mobileMedia} {
    height: 220px;
  }
`;

const AddressInfoContainer = styled.div`
  width: 100%;
  background-color: ${Palette["B&W/05"]};
  border-radius: 25px;

  ${mobileMedia} {
    border-radius: 20px;
  }
`;

const CautionContainer = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  align-self: stretch;

  border-radius: 12px;
  background: ${Palette["B&W/10"]};

  ${mobileMedia} {
    padding: 5px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
`;

const CautionTextContainer = styled.div`
  display: flex;
  padding: 8px 10px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;
