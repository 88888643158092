import styled from "@emotion/styled";

import { DataUpdateSuccessfulModal, Button, Message } from "~components";
import { mobileMedia } from "~constants";
import { RecordsArchive } from "~modules/user-account/RecordsArchive";
import { RecordsList } from "~modules/user-account/RecordsList";
import { UserCard } from "~modules/user-account/UserCard";
import { UserInfoChangeModal } from "~modules/user-account/UserInfoChangeModal";
import { Palette } from "~utils/Palette";
import { useAccountModals, useUserInfo } from "./hooks";
import {
  EWhenEventOccurs,
  PlaceMenu,
  ResponseAccount,
  ResponseUserRecord,
} from "prosto-api-client";
import { RecordInfo } from "./types";
import { useUserActionsApi } from "~modules/co-working/hooks";
import { DateService } from "~services/Date";
import { Typography } from "@mui/material";
import { encodeImageFileAsURL } from "~utils/toFileUrl";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "~constants";
import { openTildaModal } from "~utils/tildaHelpers";

type UserAccountProps = {
  coworkingPageUrl: string;
  eventsPageUrl: string;
  modalAccountVerificationAnchor: string;
};

export const UserAccount = ({
  coworkingPageUrl,
  eventsPageUrl,
  modalAccountVerificationAnchor,
}: UserAccountProps) => {
  const { cancelRegistration, registerOn, isWaitingBookLocker } =
    useUserActionsApi();
  const { info, refetch, updateUser, updatePassword, logout, isLoadingLogout } =
    useUserInfo();
  const userName = info
    ? `${info?.USER_INFO?.LAST_NAME || ""} ${info?.USER_INFO?.NAME || ""} ${
        info?.USER_INFO?.SECOND_NAME || ""
      }`
    : "";
  const userInfo = info?.USER_INFO;
  const {
    actualCoworkingRecords,
    archivedCoworkingRecords,
    actualEventsRecords,
    archivedEventsRecords,
  } = getRecords(info);

  const { close, open, modalState } = useAccountModals();
  const age =
    userInfo?.BIRTHDAY &&
    -1 *
      DateService.parse(userInfo.BIRTHDAY!, DATE_FORMAT).diffNow("years").years;
  return (
    <>
      <Container>
        <UnverifiedAccaunt
          isVerifiedUser={userInfo?.VERIFIED}
          modalAccountVerificationAnchor={modalAccountVerificationAnchor}
        />
        <UserCard
          age={age}
          name={userName}
          avatarUrl={userInfo?.URL_PHOTO}
          onClick={() => open("userInfo")}
        />
        <RecordsContainer>
          <RecordsList
            type="COWORKING"
            pageUrl={coworkingPageUrl}
            isWaitingBookLocker={isWaitingBookLocker}
            onCancelRegister={(id) => {
              id && cancelRegistration(id, { onSuccess: () => refetch() });
            }}
            title={"Записи в коворкинг"}
            onIconClick={() => open("coworkingArchive")}
            items={actualCoworkingRecords}
            onAddLocker={async (eventInfo) =>
              registerOn(
                {
                  // @ts-ignore
                  CLICK: Object.keys(eventInfo.times).map(Number),
                  LOCKER: true,
                  EVENT_ID: eventInfo.id,
                },
                { onSuccess: () => refetch() }
              )
            }
          />

          <RecordsList
            pageUrl={eventsPageUrl}
            type="EVENTS"
            title={"Записи на мероприятия"}
            onIconClick={() => open("eventArchive")}
            items={actualEventsRecords}
            onCancelRegister={(id) => {
              id && cancelRegistration(id, { onSuccess: () => refetch() });
            }}
          />
        </RecordsContainer>
        <LogOutButton
          variant="third"
          onClick={() => logout()}
          loading={isLoadingLogout}
        >
          Выйти из аккаунта
        </LogOutButton>
      </Container>
      <UserInfoChangeModal
        userInfo={userInfo}
        open={modalState?.userInfo}
        onSubmit={async (d) => {
          let dataUrl = null;
          try {
            if (d.avatar) {
              dataUrl = await encodeImageFileAsURL(d.avatar);
            }
          } catch (error) {
            console.log(error);
          } finally {
            const userData = {
              EMAIL: d.email,
              GENDER: d.gender,
              LAST_NAME: d.lastname,
              NAME: d.firstname,
              SECOND_NAME: d.middlename,
              TELEGRAM: d.telegram,
              VK: d.vkontakte,
              PHONE: d.phone,
            };
            // Пользователь заменил аватар
            if (dataUrl) {
              // @ts-ignore
              userData["URL_PHOTO"] = dataUrl ? dataUrl.toString() : "";
            }
            // Пользователь удалил аватар
            if (d.avatar === "") {
              // @ts-ignore
              userData["URL_PHOTO"] = "";
            }
            return await updateUser(userData);
          }
        }}
        // onSubmitCode={async ({ code }) => {
        //   verifyCode(code);
        // }}
        // onSubmitPhone={async (phone) => {
        //   updateUser({
        //     ...userInfo,
        //     phone,
        //   });
        // }}
        onSubmitPassword={updatePassword}
        onClose={close}
      />
      <DataUpdateSuccessfulModal
        open={modalState?.dataChanged}
        onClose={close}
      />
      <RecordsArchive
        type="coworking"
        open={modalState?.coworkingArchive}
        onClose={close}
        items={archivedCoworkingRecords}
      />
      <RecordsArchive
        type="event"
        open={modalState?.eventArchive}
        onClose={close}
        items={archivedEventsRecords}
      />
    </>
  );
};

const UnverifiedAccaunt = ({
  isVerifiedUser,
  modalAccountVerificationAnchor,
}: {
  isVerifiedUser?: boolean;
  modalAccountVerificationAnchor: string;
}) =>
  isVerifiedUser === false ? (
    <UnverifiedContainer>
      <CautionBlockStyled color="alert">
        <Typography variant="body3">
          Чтобы посещать наши пространства, тебе нужно{" "}
          <strong>подтвердить свой аккаунт</strong>
        </Typography>
      </CautionBlockStyled>
      <Button
        fullWidth
        variant="second"
        onClick={() => {
          openTildaModal(modalAccountVerificationAnchor);
        }}
      >
        Как это сделать
      </Button>
    </UnverifiedContainer>
  ) : null;

const CautionBlockStyled = styled(Message)`
  justify-content: center;
`;
const UnverifiedContainer = styled.div`
  margin: 90px auto 0px;
  max-width: 640px;
`;

const getRecords = (data?: ResponseAccount) => {
  const coworkingRecords =
    data?.RECORDS_COWORKING && Object.values(data?.RECORDS_COWORKING);

  const eventsRecords =
    data?.RECORDS_EVENTS && Object.values(data?.RECORDS_EVENTS);

  const actualCoworkingRecords = mapCoworkingInfoToRecordInfo(
    coworkingRecords,
    data?.PLACE_MENU,
    (r) =>
      r.WHEN_OCCURS === EWhenEventOccurs.Future ||
      r.WHEN_OCCURS === EWhenEventOccurs.Current
  );

  const archivedCoworkingRecords = mapCoworkingInfoToRecordInfo(
    coworkingRecords,
    data?.PLACE_MENU,
    (r) => r.WHEN_OCCURS === EWhenEventOccurs.Archive
  );

  const actualEventsRecords = mapEventInfoToRecordInfo(
    eventsRecords,
    data?.PLACE_MENU,
    (r) =>
      r.WHEN_OCCURS === EWhenEventOccurs.Future ||
      r.WHEN_OCCURS === EWhenEventOccurs.Current
  );

  const archivedEventsRecords = mapEventInfoToRecordInfo(
    eventsRecords,
    data?.PLACE_MENU,
    (r) => r.WHEN_OCCURS === EWhenEventOccurs.Archive
  );

  return {
    actualCoworkingRecords,
    archivedCoworkingRecords,
    actualEventsRecords,
    archivedEventsRecords,
  };
};

const mapCoworkingInfoToRecordInfo = (
  coworkingRecords?: ResponseUserRecord[],
  placeMenu?: PlaceMenu,
  compare: (r: ResponseUserRecord) => boolean = () => true
) => {
  return coworkingRecords?.filter(compare)?.map((r) => {
    const place = placeMenu?.places?.[r.PLACE_ID!];
    // @ts-ignore
    const times: number[] =
      // @ts-ignore
      (r.RECORDS && Object.values(r.RECORDS).map((t) => t.HOUR!)) || [];
    // 8.11.2023 коворкинги без времени
    const eDate = DateService.parse(r.EVENT_DATE!, DATE_FORMAT);
    return {
      records: r.RECORDS,
      id: r.EVENT_ID,
      title: r.EVENT_NAME!,
      date: eDate.toJSDate(),
      startTime: times.length ? Math.min(...times) : undefined,
      endTime: times.length
        ? times.length === 1
          ? Math.min(...times) + 1
          : Math.max(...times) + 1
        : undefined,
      placeName: place?.name,
      geoLink: place?.address_ya_map,
      lockerBooked: r.LOCKER,
      qrCode: r.QR_MAIN ? r.QR_MAIN : r.QR_VERIFICATION,
      userVerified: r.VERIFIED,
      lockerInfo: r.QR_LOCKER,
    } as RecordInfo;
  });
};

const mapEventInfoToRecordInfo = (
  eventsRecords?: ResponseUserRecord[],
  placeMenu?: PlaceMenu,
  compare: (r: ResponseUserRecord) => boolean = () => true
) => {
  return eventsRecords?.filter(compare)?.map((r) => {
    const place = placeMenu?.places?.[r.PLACE_ID!];
    // 28.11.2023 19:00:00
    const eDate = DateService.parse(r.EVENT_DATE!, DATE_TIME_FORMAT);
    return {
      records: r.RECORDS,
      id: r.EVENT_ID,
      title: r.EVENT_NAME!,
      date: eDate.toJSDate(),
      startTime: eDate.toFormat("HH:mm"),
      placeName: place?.name,
      geoLink: place?.address_ya_map,
      lockerBooked: r.LOCKER,
      qrCode: r.QR_MAIN ? r.QR_MAIN : r.QR_VERIFICATION,
      userVerified: r.VERIFIED,
      lockerInfo: r.QR_LOCKER,
    } as RecordInfo;
  });
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 90px;
  align-self: stretch;

  background-color: black;

  ${mobileMedia} {
    align-items: center;
    gap: 60px;
  }
`;

const RecordsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  justify-content: center;

  ${mobileMedia} {
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }
`;

const LogOutButton = styled(Button)`
  width: 100%;
  background-color: ${Palette["B&W/00"]};
  color: ${Palette["Error/60"]};
`;
