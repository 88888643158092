import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import * as React from "react";
import { mobileMedia } from "~constants";

import { ButtonSwitch } from "~modules/co-working/ButtonSwitch";

type TitleWithViewModesProps<T> = {
  title: string;
  mode: T;
  onChange: (mode: T) => void;
  viewModes: Array<{
    name: T;
    Component: React.FC<React.SVGProps<SVGSVGElement>>;
  }>;
};

export const TitleWithViewModes = <T extends string>({
  title,
  onChange,
  viewModes,
  mode,
}: TitleWithViewModesProps<T>) => {
  return (
    <Container>
      <TextContainer>
        <Typography variant="h2">{title}</Typography>
      </TextContainer>
      <ButtonSwitch<T> mode={mode} onChange={onChange} items={viewModes} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  width: 100%;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  box-sizing: border-box;
  padding: 1px 0;

  ${mobileMedia} {
    padding: 7px 0 6px;
  }
`;
