/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipmentItem } from './EquipmentItem';
import {
    EquipmentItemFromJSON,
    EquipmentItemFromJSONTyped,
    EquipmentItemToJSON,
} from './EquipmentItem';

/**
 * Данный объект предоставляет данные для отображения опросника требуемого оборудования при посещении коворкинга
 * @export
 * @interface ResponseCoworkingCalendarDetailSURVEYEQUIPMENT
 */
export interface ResponseCoworkingCalendarDetailSURVEYEQUIPMENT {
    /**
     * Данная переменная указывает, необходимо ли отображать данный опросник
     * @type {boolean}
     * @memberof ResponseCoworkingCalendarDetailSURVEYEQUIPMENT
     */
    DISPLAY?: boolean;
    /**
     * Список оборудования для бронирования в коворкинге
     * @type {{ [key: string]: EquipmentItem; }}
     * @memberof ResponseCoworkingCalendarDetailSURVEYEQUIPMENT
     */
    ITEMS?: { [key: string]: EquipmentItem; };
}

/**
 * Check if a given object implements the ResponseCoworkingCalendarDetailSURVEYEQUIPMENT interface.
 */
export function instanceOfResponseCoworkingCalendarDetailSURVEYEQUIPMENT(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseCoworkingCalendarDetailSURVEYEQUIPMENTFromJSON(json: any): ResponseCoworkingCalendarDetailSURVEYEQUIPMENT {
    return ResponseCoworkingCalendarDetailSURVEYEQUIPMENTFromJSONTyped(json, false);
}

export function ResponseCoworkingCalendarDetailSURVEYEQUIPMENTFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCoworkingCalendarDetailSURVEYEQUIPMENT {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DISPLAY': !exists(json, 'DISPLAY') ? undefined : json['DISPLAY'],
        'ITEMS': !exists(json, 'ITEMS') ? undefined : (mapValues(json['ITEMS'], EquipmentItemFromJSON)),
    };
}

export function ResponseCoworkingCalendarDetailSURVEYEQUIPMENTToJSON(value?: ResponseCoworkingCalendarDetailSURVEYEQUIPMENT | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DISPLAY': value.DISPLAY,
        'ITEMS': value.ITEMS === undefined ? undefined : (mapValues(value.ITEMS, EquipmentItemToJSON)),
    };
}

