/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResponseBaseModel,
  ResponseCoworkingCalendarDetail,
  ResponseCoworkingCalendarList,
  ResponseReservationCovorking,
  UserClickRequest,
} from '../models';
import {
    ResponseBaseModelFromJSON,
    ResponseBaseModelToJSON,
    ResponseCoworkingCalendarDetailFromJSON,
    ResponseCoworkingCalendarDetailToJSON,
    ResponseCoworkingCalendarListFromJSON,
    ResponseCoworkingCalendarListToJSON,
    ResponseReservationCovorkingFromJSON,
    ResponseReservationCovorkingToJSON,
    UserClickRequestFromJSON,
    UserClickRequestToJSON,
} from '../models';

export interface CoworkingElementIdGetRequest {
    elementId: number;
    showSurvey?: string;
}

export interface CoworkingGetRequest {
    place?: number;
}

export interface CoworkingUserClickElementIdDeleteRequest {
    elementId: number;
}

export interface CoworkingUserClickPostRequest {
    userClickRequest: UserClickRequest;
}

/**
 * 
 */
export class CoworkingApi extends runtime.BaseAPI {

    /**
     * Данный метод позволяет получить данные для заполнения календаря бронирования коворкингов
     * Получение данных для заполнения календаря бронирования коворкинга
     */
    async coworkingElementIdGetRaw(requestParameters: CoworkingElementIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseCoworkingCalendarDetail>> {
        if (requestParameters.elementId === null || requestParameters.elementId === undefined) {
            throw new runtime.RequiredError('elementId','Required parameter requestParameters.elementId was null or undefined when calling coworkingElementIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.showSurvey !== undefined) {
            queryParameters['show_survey'] = requestParameters.showSurvey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/coworking/{elementId}/`.replace(`{${"elementId"}}`, encodeURIComponent(String(requestParameters.elementId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseCoworkingCalendarDetailFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет получить данные для заполнения календаря бронирования коворкингов
     * Получение данных для заполнения календаря бронирования коворкинга
     */
    async coworkingElementIdGet(requestParameters: CoworkingElementIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseCoworkingCalendarDetail> {
        const response = await this.coworkingElementIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет получить данные для заполнения календаря бронирования коворкингов
     * Получение данных для заполнения календаря бронирования коворкинга
     */
    async coworkingGetRaw(requestParameters: CoworkingGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseCoworkingCalendarList>> {
        const queryParameters: any = {};

        if (requestParameters.place !== undefined) {
            queryParameters['place'] = requestParameters.place;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/coworking/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseCoworkingCalendarListFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет получить данные для заполнения календаря бронирования коворкингов
     * Получение данных для заполнения календаря бронирования коворкинга
     */
    async coworkingGet(requestParameters: CoworkingGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseCoworkingCalendarList> {
        const response = await this.coworkingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет отменить регистрацию на конкрентый коворкинг.
     * Метод для отмены регистрации в коворкинге
     */
    async coworkingUserClickElementIdDeleteRaw(requestParameters: CoworkingUserClickElementIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseBaseModel>> {
        if (requestParameters.elementId === null || requestParameters.elementId === undefined) {
            throw new runtime.RequiredError('elementId','Required parameter requestParameters.elementId was null or undefined when calling coworkingUserClickElementIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/coworking/user_click/{elementId}/`.replace(`{${"elementId"}}`, encodeURIComponent(String(requestParameters.elementId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseBaseModelFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет отменить регистрацию на конкрентый коворкинг.
     * Метод для отмены регистрации в коворкинге
     */
    async coworkingUserClickElementIdDelete(requestParameters: CoworkingUserClickElementIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseBaseModel> {
        const response = await this.coworkingUserClickElementIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Данный метод позволяет зарегистрироваться на конкрентый коворкинг. В ответ возвращает данные для формирования QR кода
     * Метод для регистрации на коворкинг
     */
    async coworkingUserClickPostRaw(requestParameters: CoworkingUserClickPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseReservationCovorking>> {
        if (requestParameters.userClickRequest === null || requestParameters.userClickRequest === undefined) {
            throw new runtime.RequiredError('userClickRequest','Required parameter requestParameters.userClickRequest was null or undefined when calling coworkingUserClickPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/coworking/user_click/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserClickRequestToJSON(requestParameters.userClickRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseReservationCovorkingFromJSON(jsonValue));
    }

    /**
     * Данный метод позволяет зарегистрироваться на конкрентый коворкинг. В ответ возвращает данные для формирования QR кода
     * Метод для регистрации на коворкинг
     */
    async coworkingUserClickPost(requestParameters: CoworkingUserClickPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseReservationCovorking> {
        const response = await this.coworkingUserClickPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
