import React from "react";
import { Button, ButtonProps } from "@mui/material";
import styled from "@emotion/styled";

import { useFormContext } from "./UseFormContext";

export const ResetButton = ({ children, onClick, ...rest }: ButtonProps) => {
  const { resetForm } = useFormContext();

  return (
    <ButtonStyled
      onClick={(e) => {
        resetForm();
        onClick?.(e);
      }}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)`
  margin-bottom: 16px;
`;
