/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CaptchaCodeModel } from './CaptchaCodeModel';
import {
    CaptchaCodeModelFromJSON,
    CaptchaCodeModelFromJSONTyped,
    CaptchaCodeModelToJSON,
} from './CaptchaCodeModel';

/**
 * Объект для восстановления пароля.
 * @export
 * @interface ResponseChangePassword
 */
export interface ResponseChangePassword {
    /**
     * Проверочное слово из письма (приходит в query string в параметре USER_CHECKWORD)
     * @type {string}
     * @memberof ResponseChangePassword
     */
    USER_CHECKWORD?: string;
    /**
     * Логин пользователя из письма (приходит в query string в параметре USER_LOGIN)
     * @type {string}
     * @memberof ResponseChangePassword
     */
    USER_LOGIN?: string;
    /**
     * Новый пароль пользователя
     * @type {string}
     * @memberof ResponseChangePassword
     */
    USER_PASSWORD?: string;
    /**
     * Подтверждение нового пароля пользователя
     * @type {string}
     * @memberof ResponseChangePassword
     */
    USER_CONFIRM_PASSWORD?: string;
    /**
     * Используется при запросе установки пароля. Передаваемое значение должно быть CHANGE_PWD
     * @type {string}
     * @memberof ResponseChangePassword
     */
    TYPE?: string;
    /**
     * 
     * @type {CaptchaCodeModel}
     * @memberof ResponseChangePassword
     */
    CAPTCHA_CODE?: CaptchaCodeModel;
    /**
     * Сообщение от API системы (при наличии)
     * @type {string}
     * @memberof ResponseChangePassword
     */
    MESSAGE?: string;
    /**
     * Тип ошибки
     * @type {string}
     * @memberof ResponseChangePassword
     */
    ERROR_TYPE?: string;
    /**
     * Список ошибок
     * @type {Array<string>}
     * @memberof ResponseChangePassword
     */
    ERRORS?: Array<string>;
}

/**
 * Check if a given object implements the ResponseChangePassword interface.
 */
export function instanceOfResponseChangePassword(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseChangePasswordFromJSON(json: any): ResponseChangePassword {
    return ResponseChangePasswordFromJSONTyped(json, false);
}

export function ResponseChangePasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseChangePassword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'USER_CHECKWORD': !exists(json, 'USER_CHECKWORD') ? undefined : json['USER_CHECKWORD'],
        'USER_LOGIN': !exists(json, 'USER_LOGIN') ? undefined : json['USER_LOGIN'],
        'USER_PASSWORD': !exists(json, 'USER_PASSWORD') ? undefined : json['USER_PASSWORD'],
        'USER_CONFIRM_PASSWORD': !exists(json, 'USER_CONFIRM_PASSWORD') ? undefined : json['USER_CONFIRM_PASSWORD'],
        'TYPE': !exists(json, 'TYPE') ? undefined : json['TYPE'],
        'CAPTCHA_CODE': !exists(json, 'CAPTCHA_CODE') ? undefined : CaptchaCodeModelFromJSON(json['CAPTCHA_CODE']),
        'MESSAGE': !exists(json, 'MESSAGE') ? undefined : json['MESSAGE'],
        'ERROR_TYPE': !exists(json, 'ERROR_TYPE') ? undefined : json['ERROR_TYPE'],
        'ERRORS': !exists(json, 'ERRORS') ? undefined : json['ERRORS'],
    };
}

export function ResponseChangePasswordToJSON(value?: ResponseChangePassword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'USER_CHECKWORD': value.USER_CHECKWORD,
        'USER_LOGIN': value.USER_LOGIN,
        'USER_PASSWORD': value.USER_PASSWORD,
        'USER_CONFIRM_PASSWORD': value.USER_CONFIRM_PASSWORD,
        'TYPE': value.TYPE,
        'CAPTCHA_CODE': CaptchaCodeModelToJSON(value.CAPTCHA_CODE),
        'MESSAGE': value.MESSAGE,
        'ERROR_TYPE': value.ERROR_TYPE,
        'ERRORS': value.ERRORS,
    };
}

