import {
  AuthApi,
  Configuration,
  GetAuthorizeSettingsApi,
  CoworkingApi,
  EventApi,
  RecoveryPasswordApi,
} from "prosto-api-client";

import { fixChromeResponseIssue } from "./middlewares";

const configuration = new Configuration({
  middleware: [fixChromeResponseIssue],
  fetchApi: (input, init) =>
    window.fetch(input, { ...init, credentials: "include" }),
});

export const authApi = new AuthApi(configuration);
export const getAuthSettingsApi = new GetAuthorizeSettingsApi(configuration);
export const coworkingApi = new CoworkingApi(configuration);
export const eventApi = new EventApi(configuration);
export const recoveryPasswordApi = new RecoveryPasswordApi(configuration);
