import { TextFieldProps } from "@mui/material";
import { FormikHelpers } from "formik/dist/types";
import { FormItem } from "../FormItem";
import { PhotoUploadInput } from "../../input/PhotoUploadInput";

type FieldPhotoUploadProps = TextFieldProps & {
  name: string;
  onChange?: (f?: File | string) => void;
};

export const FieldPhotoUpload = ({
  name,
  ...inputProps
}: FieldPhotoUploadProps) => {
  return (
    <FormItem name={name}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (
        <PhotoUploadInput
          {...inputProps}
          photoUrl={
            value
              ? typeof value === "string"
                ? value
                : URL.createObjectURL(value as Blob)
              : undefined
          }
          onChange={(f) => {
            setFieldTouched(name);
            setFieldValue?.(name, f);
            inputProps?.onChange?.(f);
          }}
        />
      )}
    </FormItem>
  );
};
