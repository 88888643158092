/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseCoworkingCalendarDetailINFOPLACE } from './ResponseCoworkingCalendarDetailINFOPLACE';
import {
    ResponseCoworkingCalendarDetailINFOPLACEFromJSON,
    ResponseCoworkingCalendarDetailINFOPLACEFromJSONTyped,
    ResponseCoworkingCalendarDetailINFOPLACEToJSON,
} from './ResponseCoworkingCalendarDetailINFOPLACE';
import type { ResponseUserRecord } from './ResponseUserRecord';
import {
    ResponseUserRecordFromJSON,
    ResponseUserRecordFromJSONTyped,
    ResponseUserRecordToJSON,
} from './ResponseUserRecord';

/**
 * 
 * @export
 * @interface ResponseCoworkingCalendarDetailINFO
 */
export interface ResponseCoworkingCalendarDetailINFO {
    /**
     * Название коворкинга с датой
     * @type {string}
     * @memberof ResponseCoworkingCalendarDetailINFO
     */
    NAME?: string;
    /**
     * 
     * @type {ResponseCoworkingCalendarDetailINFOPLACE}
     * @memberof ResponseCoworkingCalendarDetailINFO
     */
    PLACE?: ResponseCoworkingCalendarDetailINFOPLACE;
    /**
     * 
     * @type {ResponseUserRecord}
     * @memberof ResponseCoworkingCalendarDetailINFO
     */
    USER?: ResponseUserRecord;
    /**
     * Дата проведения коворкинга.
     * @type {string}
     * @memberof ResponseCoworkingCalendarDetailINFO
     */
    DATE?: string;
    /**
     * Общее количество мест на данном коворкинге.
     * @type {number}
     * @memberof ResponseCoworkingCalendarDetailINFO
     */
    PARTICIPANTS?: number;
}

/**
 * Check if a given object implements the ResponseCoworkingCalendarDetailINFO interface.
 */
export function instanceOfResponseCoworkingCalendarDetailINFO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseCoworkingCalendarDetailINFOFromJSON(json: any): ResponseCoworkingCalendarDetailINFO {
    return ResponseCoworkingCalendarDetailINFOFromJSONTyped(json, false);
}

export function ResponseCoworkingCalendarDetailINFOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCoworkingCalendarDetailINFO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'NAME': !exists(json, 'NAME') ? undefined : json['NAME'],
        'PLACE': !exists(json, 'PLACE') ? undefined : ResponseCoworkingCalendarDetailINFOPLACEFromJSON(json['PLACE']),
        'USER': !exists(json, 'USER') ? undefined : ResponseUserRecordFromJSON(json['USER']),
        'DATE': !exists(json, 'DATE') ? undefined : json['DATE'],
        'PARTICIPANTS': !exists(json, 'PARTICIPANTS') ? undefined : json['PARTICIPANTS'],
    };
}

export function ResponseCoworkingCalendarDetailINFOToJSON(value?: ResponseCoworkingCalendarDetailINFO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'NAME': value.NAME,
        'PLACE': ResponseCoworkingCalendarDetailINFOPLACEToJSON(value.PLACE),
        'USER': ResponseUserRecordToJSON(value.USER),
        'DATE': value.DATE,
        'PARTICIPANTS': value.PARTICIPANTS,
    };
}

