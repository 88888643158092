import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography, useMediaQuery } from "@mui/material";

import { ReactComponent as CircleAgreeIcon } from "~assets/svg/circle_agree.svg";
import { ReactComponent as MetroIcon } from "~assets/svg/metro.svg";
import { PointPreview } from "~components";
import { devices, mobileMedia } from "~constants";
import { PlaceInfo } from "~modules/co-working/CoWorkingPicker";
import { Palette } from "~utils/Palette";

type CardProps = {
  mode: "row" | "column";
  point: PlaceInfo;
  onClick: () => void;
  active?: boolean;
};

export const Card = ({ mode, point, onClick, active = false }: CardProps) => {
  const isMobileView = useMediaQuery(devices.mobile);
  const disabled = point.disabled || false;

  const isColumnView = mode === "column";
  const CardInfoContainer = isColumnView
    ? ColumnViewCardInfoContainer
    : RowViewCardInfoContainer;

  return (
    <Container active={active} isColumnView={isColumnView}>
      <CardInfoContainer
        active={active}
        disabled={disabled}
        onClick={() => !disabled && onClick()}
      >
        {isColumnView && (
          <PointPreview src={point.imgUrl} smallView={!!point.infoBarContent} />
        )}
        <ContentContainer>
          <OverflowableText variant="h5" disabled={disabled}>
            {point.address}
          </OverflowableText>
          <StationContainer>
            <MetroIcon
              height={isMobileView ? "10px" : "11px"}
              width={isMobileView ? "11px" : "13px"}
              fill={disabled ? Palette["B&W/20"] : Palette["B&W/100"]}
            />
            <OverflowableText variant="label3Thin" disabled={disabled}>
              {point.metro}
            </OverflowableText>
          </StationContainer>
        </ContentContainer>
        {active && (
          <IconContainer isColumnView={isColumnView}>
            <CircleAgreeIcon />
          </IconContainer>
        )}
      </CardInfoContainer>
      {point.infoBarContent && (
        <FrequentVisitContainer>
          <Typography variant="label5">{point.infoBarContent}</Typography>
        </FrequentVisitContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{ active: boolean; isColumnView: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 0 0;
  cursor: pointer;

  border-radius: 15px;
  background: ${({ active }) =>
    active ? Palette["B&W/20"] : Palette["B&W/05"]};

  ${mobileMedia} {
    max-height: ${({ isColumnView }) => (isColumnView ? "218px" : "initial")};
  }
`;

const FrequentVisitContainer = styled.div`
  display: flex;
  padding: 10px 0 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const GeneralCardInfoStyles = css`
  display: flex;
  align-self: stretch;
  flex: 1 0 0;

  border-radius: 15px;
  box-sizing: border-box;

  ${mobileMedia} {
    gap: 1px;
    border-radius: 10px;
  }
`;

const ColumnViewCardInfoContainer = styled.div<{
  active: boolean;
  disabled: boolean;
}>`
  ${GeneralCardInfoStyles};

  width: 200px;
  height: 288px;

  flex-direction: column;
  align-items: flex-start;

  outline-width: 2px;
  outline-style: solid;

  background: ${({ disabled }) =>
    disabled ? Palette["B&W/05"] : Palette["B&W/10"]};
  outline-color: ${({ active }) =>
    active ? Palette["B&W/100"] : "transparent"};

  ${mobileMedia} {
    width: 160px;
    height: 218px;
    max-height: 218px;
  }
`;

const RowViewCardInfoContainer = styled.div<{
  active: boolean;
  disabled: boolean;
}>`
  ${GeneralCardInfoStyles};

  width: auto;
  height: auto;

  flex-direction: row;
  align-items: center;

  border-width: 2px;
  border-style: solid;

  background: ${({ disabled }) =>
    disabled ? Palette["B&W/05"] : Palette["B&W/10"]};
  border-color: ${({ active }) =>
    active ? Palette["B&W/100"] : "transparent"};
`;

const OverflowableText = styled(Typography)<{ disabled: boolean }>`
  word-break: break-word;
  color: ${({ disabled }) => (disabled ? Palette["B&W/20"] : "")};
`;

const IconContainer = styled.div<{ isColumnView: boolean }>`
  position: ${({ isColumnView }) => (isColumnView ? "relative" : "static")};
  left: calc(100% - 40px);
  bottom: calc(100% - 40px + 200px);
  margin-bottom: ${({ isColumnView }) => (isColumnView ? "-200px" : "0")};

  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  padding: 13px;
  gap: 8px;
  flex: 1 0 0;

  box-sizing: border-box;

  ${mobileMedia} {
    padding: 10px 10px 8px;
    gap: 7px;
  }
`;

const StationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;

  ${mobileMedia} {
    gap: 4px;
  }
`;
