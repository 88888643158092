/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Перечисление которое показывает, когда проходит событие. Варианты прошло, сегодня и будет проходить
 * @export
 */
export const EWhenEventOccurs = {
    Archive: 'ARCHIVE',
    Current: 'CURRENT',
    Future: 'FUTURE'
} as const;
export type EWhenEventOccurs = typeof EWhenEventOccurs[keyof typeof EWhenEventOccurs];


export function EWhenEventOccursFromJSON(json: any): EWhenEventOccurs {
    return EWhenEventOccursFromJSONTyped(json, false);
}

export function EWhenEventOccursFromJSONTyped(json: any, ignoreDiscriminator: boolean): EWhenEventOccurs {
    return json as EWhenEventOccurs;
}

export function EWhenEventOccursToJSON(value?: EWhenEventOccurs | null): any {
    return value as any;
}

