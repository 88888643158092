import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ResponseUserInfo, UserInfoBase } from "prosto-api-client";
import Boy02 from "~assets/images/B02.png";
import { ReactComponent as Cross } from "~assets/svg/cross.svg";
import { ReactComponent as Edit } from "~assets/svg/edit.svg";
import {
  AvatarIcon,
  ButtonIcon,
  FieldInput,
  Form,
  Modal,
  SubmitButton,
  FieldPhotoUpload,
  TextField,
  FieldSelect,
  SupportingText,
  UnknownFormErrors,
} from "~components";
import { boldText, mobileMedia } from "~constants";
import { LeaveConfirmationModal } from "~modules/user-account/LeaveConfirmationModal";
import { Palette } from "~utils/Palette";
import {
  InferType,
  mixed,
  object,
  phoneValidator,
  string,
} from "~utils/validation";
import {
  PasswordChangeModal,
  PasswordChangeModalProps,
} from "./PasswordChangeModal";
import { isEqual } from "lodash";

type UserInfoChangeProps = {
  open?: boolean;
  userInfo?: UserInfoBase;
  onSubmit: (d: FormData) => Promise<ResponseUserInfo>;
  // onSubmitPhone: PhoneChangeModalProps["onSubmitPhone"];
  // onSubmitCode: PhoneChangeModalProps["onSubmitCode"];
  onSubmitPassword: PasswordChangeModalProps["onSubmit"];
  onClose: () => void;
};

type FormData = InferType<typeof UserInfoSchema>;
const UserInfoSchema = object({
  avatar: mixed(),
  firstname: string().required(),
  lastname: string().required(),
  middlename: string(),
  email: string().email("Неверный формат почты").required(),
  gender: string().required(),
  phone: phoneValidator,
  telegram: string().test(
    "telegram",
    "Неверный формат, введи свой ник без t.me/ или @",
    (v) => {
      if (!v) return true;
      return !v.includes("t.me/") && !v?.includes("@");
    }
  ),
  vkontakte: string().test(
    "vkontakte",
    "Неверный формат, введи свой ник без vk.com/",
    (v) => {
      if (!v) return true;
      return !v.includes("vk.com/");
    }
  ),
});
export const UserInfoChangeModal = ({
  open,
  userInfo,
  onSubmit,
  onClose,
  // onSubmitCode,
  // onSubmitPhone,
  onSubmitPassword,
}: UserInfoChangeProps) => {
  const { closeModal, isOpenedModal, openModal } = Modal.useModal();
  const {
    closeModal: closePasswordModal,
    isOpenedModal: isOpenedPasswordModal,
    openModal: openPasswordModal,
  } = Modal.useModal();

  const {
    closeModal: closePhoneModal,
    isOpenedModal: isOpenedPhoneModal,
    openModal: openPhoneModal,
  } = Modal.useModal();
  const birthday = userInfo?.BIRTHDAY;
  const initialValues = {
    gender: userInfo?.GENDER || "unknown",
    email: userInfo?.EMAIL!,
    firstname: userInfo?.NAME!,
    lastname: userInfo?.LAST_NAME!,
    phone: userInfo?.PHONE!,
    middlename: userInfo?.SECOND_NAME,
    telegram: userInfo?.TELEGRAM,
    vkontakte: userInfo?.VK,
  };
  return (
    <Modal open={open} onClose={onClose} fullPage>
      <Form
        initialValues={{ ...initialValues, avatar: userInfo?.URL_PHOTO }}
        onSubmit={async (d) => {
          // @ts-ignore
          await onSubmit(d);
          onClose();
        }}
        validationSchema={UserInfoSchema}
      >
        {({ values }) => (
          <Container>
            <Header>
              <ImageTopBarContainer>
                <AvatarIcon icon={Boy02} />
                <Typography variant="h1">Изменение профиля</Typography>
              </ImageTopBarContainer>
              <ButtonIcon
                Icon={Cross}
                onClick={() => {
                  const wasTouched = !isEqual(values, initialValues);
                  if (wasTouched) {
                    openModal();
                  } else {
                    onClose();
                  }
                }}
              />
            </Header>
            <InfoBlock>
              <Typography variant="h2">Личные данные</Typography>
              <FieldsContainer>
                <FieldPhotoUpload name="avatar" />
                <FieldWithTextContainer>
                  <FieldInput
                    name="firstname"
                    label="Имя*"
                    placeholder="Иван"
                    supportingText={
                      <SupportingText>
                        <Typography variant="body3">
                          <Typography
                            variant="body3"
                            typography={boldText}
                            component="span"
                          >
                            Укажи имя, как написано в паспорте.
                          </Typography>{" "}
                          Если имя не будет совпадать, ты не сможешь посещать
                          наши пространства.
                        </Typography>
                      </SupportingText>
                    }
                  />
                </FieldWithTextContainer>
                <FieldWithTextContainer>
                  <FieldInput
                    name="lastname"
                    label="Фамилия*"
                    placeholder="Иванов"
                    supportingText={
                      <SupportingText>
                        <Typography variant="body3">
                          <Typography
                            variant="body3"
                            typography={boldText}
                            component="span"
                          >
                            Укажи фамилию, как написано в паспорте.
                          </Typography>{" "}
                          Если фамилия не будет совпадать, ты не сможешь
                          посещать наши пространства.
                        </Typography>
                      </SupportingText>
                    }
                  />
                </FieldWithTextContainer>
                <FieldInput
                  name="middlename"
                  label="Отчество"
                  placeholder="Иванович"
                />
                <FieldSelect
                  name="gender"
                  label="Пол*"
                  options={[
                    { label: "Женский", value: "F" },
                    { label: "Мужской", value: "M" },
                    { label: "Не указывать", value: "unknown" },
                  ]}
                />
                <TextField
                  label="Дата рождения*"
                  disabled
                  defaultValue={birthday}
                />
              </FieldsContainer>
            </InfoBlock>

            <InfoBlock>
              <Typography variant="h2">Контакты</Typography>
              <AdditionalInfoTextBlock>
                <Typography variant="body3">
                  Укажи свои контакты и соц. сети, чтобы мы могли связаться с
                  тобой удобным тебе способом
                </Typography>
              </AdditionalInfoTextBlock>
              <FieldsContainer>
                <FieldInput
                  name="email"
                  label="Эл.почта*"
                  placeholder="ivanovanov@mail.ru"
                />
                <FieldInput
                  name="phone"
                  label="Телефон*"
                  placeholder={"+7 999 999 99 99"}
                  value={userInfo?.PHONE}
                  maskProps={{
                    config: { mask: "+{7} 000 000 00 00" },
                  }}
                  // InputProps={{
                  //   readOnly: true,
                  //   endAdornment: <EditIcon onClick={openPhoneModal} />,
                  // }}
                />
                <FieldInput
                  name="telegram"
                  label="Telegram"
                  placeholder="ivanivanov"
                />
                <FieldInput
                  name="vkontakte"
                  label="Вконтакте"
                  placeholder="ivanivanov"
                />
              </FieldsContainer>
            </InfoBlock>

            <InfoBlock>
              <Typography variant="h2">Пароль</Typography>
              <TextField
                value="••••••••••"
                InputProps={{
                  readOnly: true,
                  endAdornment: <EditIcon onClick={openPasswordModal} />,
                }}
              />
            </InfoBlock>
            <UnknownFormErrors />
            <ChangeInfoButton variant="first">
              Сохранить изменения
            </ChangeInfoButton>
          </Container>
        )}
      </Form>
      <LeaveConfirmationModal
        open={isOpenedModal}
        onClose={closeModal}
        onLeave={() => {
          closeModal();
          onClose();
        }}
      />
      {/* <PhoneChangeModal
        initialValues={{ phone: userInfo?.pHONE }}
        onClose={closePhoneModal}
        open={isOpenedPhoneModal}
        onSubmitCode={onSubmitCode}
        onSubmitPhone={onSubmitPhone}
      /> */}
      <PasswordChangeModal
        open={isOpenedPasswordModal}
        onSubmit={async (d) => {
          await onSubmitPassword(d);
          closePasswordModal();
        }}
        onClose={closePasswordModal}
      />
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const FieldWithTextContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
`;

const EditIcon = styled(Edit)`
  cursor: pointer;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
`;

const ImageTopBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1 0 0;

  ${mobileMedia} {
    gap: 20px;
  }
`;

const AdditionalInfoTextBlock = styled.div`
  display: flex;
  padding: 18px 20px;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 0 0;

  border-radius: 15px;
  background: ${Palette["B&W/10"]};

  ${mobileMedia} {
    padding: 13px 15px;
    border-radius: 12px;
  }
`;

const ChangeInfoButton = styled(SubmitButton)`
  width: 100%;
`;
