import { FormItem } from "../FormItem";
import { SelectInput, SelectInputProps } from "../../input/Select";

type FieldSelectProps = { options: Option[]; name: string } & SelectInputProps;

export const FieldSelect = ({
  name,
  options,
  size = "small",
  ...inputProps
}: FieldSelectProps) => (
  <FormItem name={name}>
    {({
      field: { value },
      form: { setFieldTouched, setFieldValue },
      meta: { touched, error },
    }) => (
      <SelectInput
        options={options}
        name={name}
        size={size}
        {...inputProps}
        value={value as Option['value']}
        onChange={(e) => {
          setFieldTouched(name);
          setFieldValue(name, e);
          inputProps?.onChange?.(e?.toString());
        }}
        onBlur={() => {
          setFieldTouched(name);
          inputProps?.onBlur?.();
        }}
        error={touched && Boolean(error)}
        helperText={touched && error}
      />
    )}
  </FormItem>
);
