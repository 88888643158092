/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseCoworkingCalendarDetailINFO } from './ResponseCoworkingCalendarDetailINFO';
import {
    ResponseCoworkingCalendarDetailINFOFromJSON,
    ResponseCoworkingCalendarDetailINFOFromJSONTyped,
    ResponseCoworkingCalendarDetailINFOToJSON,
} from './ResponseCoworkingCalendarDetailINFO';
import type { ResponseCoworkingCalendarDetailPICTURE } from './ResponseCoworkingCalendarDetailPICTURE';
import {
    ResponseCoworkingCalendarDetailPICTUREFromJSON,
    ResponseCoworkingCalendarDetailPICTUREFromJSONTyped,
    ResponseCoworkingCalendarDetailPICTUREToJSON,
} from './ResponseCoworkingCalendarDetailPICTURE';
import type { ResponseCoworkingCalendarDetailSCHEDULEValue } from './ResponseCoworkingCalendarDetailSCHEDULEValue';
import {
    ResponseCoworkingCalendarDetailSCHEDULEValueFromJSON,
    ResponseCoworkingCalendarDetailSCHEDULEValueFromJSONTyped,
    ResponseCoworkingCalendarDetailSCHEDULEValueToJSON,
} from './ResponseCoworkingCalendarDetailSCHEDULEValue';
import type { ResponseCoworkingCalendarDetailSURVEYEQUIPMENT } from './ResponseCoworkingCalendarDetailSURVEYEQUIPMENT';
import {
    ResponseCoworkingCalendarDetailSURVEYEQUIPMENTFromJSON,
    ResponseCoworkingCalendarDetailSURVEYEQUIPMENTFromJSONTyped,
    ResponseCoworkingCalendarDetailSURVEYEQUIPMENTToJSON,
} from './ResponseCoworkingCalendarDetailSURVEYEQUIPMENT';
import type { ResponseCoworkingCalendarDetailSURVEYTARGET } from './ResponseCoworkingCalendarDetailSURVEYTARGET';
import {
    ResponseCoworkingCalendarDetailSURVEYTARGETFromJSON,
    ResponseCoworkingCalendarDetailSURVEYTARGETFromJSONTyped,
    ResponseCoworkingCalendarDetailSURVEYTARGETToJSON,
} from './ResponseCoworkingCalendarDetailSURVEYTARGET';

/**
 * Объект, представляющий данные конкретной записи в коворкинг
 * @export
 * @interface ResponseCoworkingCalendarDetail
 */
export interface ResponseCoworkingCalendarDetail {
    /**
     * Идентификатор выбранного коворкинга
     * @type {number}
     * @memberof ResponseCoworkingCalendarDetail
     */
    EVENT_ID?: number;
    /**
     * 
     * @type {ResponseCoworkingCalendarDetailPICTURE}
     * @memberof ResponseCoworkingCalendarDetail
     */
    PICTURE?: ResponseCoworkingCalendarDetailPICTURE;
    /**
     * 
     * @type {ResponseCoworkingCalendarDetailINFO}
     * @memberof ResponseCoworkingCalendarDetail
     */
    INFO?: ResponseCoworkingCalendarDetailINFO;
    /**
     * Асоциативный масив. В качестве ключа идентификатор записи. Данный объект представляет собой элементы расписания. На каждый час сужествует своя запись.
     * @type {{ [key: string]: ResponseCoworkingCalendarDetailSCHEDULEValue; }}
     * @memberof ResponseCoworkingCalendarDetail
     */
    SCHEDULE?: { [key: string]: ResponseCoworkingCalendarDetailSCHEDULEValue; };
    /**
     * Асоциативный масив имеющихся записей. В качестве ключа идентификатор расписания, в качестве значения количество занятых мест на данной записи
     * @type {{ [key: string]: number; }}
     * @memberof ResponseCoworkingCalendarDetail
     */
    RECORDS?: { [key: string]: number; };
    /**
     * 
     * @type {ResponseCoworkingCalendarDetailSURVEYTARGET}
     * @memberof ResponseCoworkingCalendarDetail
     */
    SURVEY_TARGET?: ResponseCoworkingCalendarDetailSURVEYTARGET;
    /**
     * 
     * @type {ResponseCoworkingCalendarDetailSURVEYEQUIPMENT}
     * @memberof ResponseCoworkingCalendarDetail
     */
    SURVEY_EQUIPMENT?: ResponseCoworkingCalendarDetailSURVEYEQUIPMENT;
}

/**
 * Check if a given object implements the ResponseCoworkingCalendarDetail interface.
 */
export function instanceOfResponseCoworkingCalendarDetail(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseCoworkingCalendarDetailFromJSON(json: any): ResponseCoworkingCalendarDetail {
    return ResponseCoworkingCalendarDetailFromJSONTyped(json, false);
}

export function ResponseCoworkingCalendarDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCoworkingCalendarDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'EVENT_ID': !exists(json, 'EVENT_ID') ? undefined : json['EVENT_ID'],
        'PICTURE': !exists(json, 'PICTURE') ? undefined : ResponseCoworkingCalendarDetailPICTUREFromJSON(json['PICTURE']),
        'INFO': !exists(json, 'INFO') ? undefined : ResponseCoworkingCalendarDetailINFOFromJSON(json['INFO']),
        'SCHEDULE': !exists(json, 'SCHEDULE') ? undefined : (mapValues(json['SCHEDULE'], ResponseCoworkingCalendarDetailSCHEDULEValueFromJSON)),
        'RECORDS': !exists(json, 'RECORDS') ? undefined : json['RECORDS'],
        'SURVEY_TARGET': !exists(json, 'SURVEY_TARGET') ? undefined : ResponseCoworkingCalendarDetailSURVEYTARGETFromJSON(json['SURVEY_TARGET']),
        'SURVEY_EQUIPMENT': !exists(json, 'SURVEY_EQUIPMENT') ? undefined : ResponseCoworkingCalendarDetailSURVEYEQUIPMENTFromJSON(json['SURVEY_EQUIPMENT']),
    };
}

export function ResponseCoworkingCalendarDetailToJSON(value?: ResponseCoworkingCalendarDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EVENT_ID': value.EVENT_ID,
        'PICTURE': ResponseCoworkingCalendarDetailPICTUREToJSON(value.PICTURE),
        'INFO': ResponseCoworkingCalendarDetailINFOToJSON(value.INFO),
        'SCHEDULE': value.SCHEDULE === undefined ? undefined : (mapValues(value.SCHEDULE, ResponseCoworkingCalendarDetailSCHEDULEValueToJSON)),
        'RECORDS': value.RECORDS,
        'SURVEY_TARGET': ResponseCoworkingCalendarDetailSURVEYTARGETToJSON(value.SURVEY_TARGET),
        'SURVEY_EQUIPMENT': ResponseCoworkingCalendarDetailSURVEYEQUIPMENTToJSON(value.SURVEY_EQUIPMENT),
    };
}

