import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useState } from "react";

import { ReactComponent as TimePicker } from "~assets/svg/time_picker.svg";
import { Button, Modal, SuccessfulRequestModal } from "~components";
import { mobileMedia } from "~constants";
import { Card } from "~modules/user-account/Card";
import { RecordInfo } from "~modules/user-account/types";
import { Icon } from "../../components/images/Icon";
import { Palette } from "~utils/Palette";
import { ResponseReservationCovorking } from "prosto-api-client";

type RecordsListProps = {
  title: string;
  type: "COWORKING" | "EVENTS";
  items?: RecordInfo[];
  isWaitingBookLocker?: boolean;
  pageUrl: string;
  onIconClick: () => void;
  onAddLocker?: (eventInfo: {
    id: RecordInfo["id"];
    times: RecordInfo["records"];
    locker: boolean;
  }) => Promise<ResponseReservationCovorking>;
  onCancelRegister?: (id: RecordInfo["id"]) => void;
};

export const RecordsList = ({
  title,
  type,
  items = [],
  onIconClick,
  onCancelRegister,
  onAddLocker,
  isWaitingBookLocker,
  pageUrl,
}: RecordsListProps) => {
  const [card, setCard] = useState<RecordInfo>();
  const { closeModal, isOpenedModal, openModal } = Modal.useModal();

  return (
    <>
      <Container>
        <Header>
          <HeaderTextContainer>
            <HeaderText>{title}</HeaderText>
            <Icon Component={TimePicker} onClick={onIconClick} />
          </HeaderTextContainer>
        </Header>
        {items.length === 0 ? (
          <Empty
            title={
              type === "COWORKING"
                ? "Нет записей в коворкинг"
                : "Нет записей на мероприятия"
            }
            url={{
              title:
                type === "COWORKING"
                  ? "Записаться в коворкинг"
                  : "Записаться на мероприятие",
              value: pageUrl,
            }}
          />
        ) : (
          items.map((item) => (
            <Card
              key={item.title}
              {...item}
              onClick={() => {
                setCard(item);
                openModal();
              }}
            />
          ))
        )}
      </Container>
      {card && (
        <SuccessfulRequestModal
          eventTitle={card.title}
          isWaitingBookLocker={isWaitingBookLocker}
          open={isOpenedModal}
          geoLink={card.geoLink}
          placeName={card.placeName!}
          date={card.date}
          startTime={card.startTime?.toString()}
          endTime={card.endTime as number}
          onClose={closeModal}
          userVerified={card.userVerified}
          onCancelRegister={() => {
            onCancelRegister?.(card.id);
            closeModal();
          }}
          qrContent={card.qrCode}
          lockerInfo={card.lockerInfo}
          onAddLocker={
            onAddLocker && card.lockerBooked === false
              ? async () => {
                  const response = await onAddLocker({
                    id: card.id,
                    times: card.records,
                    locker: true,
                  });
                  setCard({
                    ...card,
                    lockerInfo: response.DATA?.QR_LOCKER,
                    lockerBooked: response.DATA?.LOCKER,
                  });
                }
              : undefined
          }
        />
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  padding-bottom: 20px;

  ${mobileMedia} {
    padding-bottom: 0;
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const HeaderTextContainer = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  flex: 1 0 0;
`;

const HeaderText = styled(Typography)`
  color: #fff;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.95px;

  ${mobileMedia} {
    font-size: 29px;
    line-height: 1;
    letter-spacing: -0.7px;
  }
`;

const Empty = ({
  title,
  url,
}: {
  title: string;
  url: { title: string; value: string };
}) => (
  <EmptyContainer>
    <Typography variant="h3">{title}</Typography>
    <Button fullWidth variant="second" href={url.value}>
      {url.title}
    </Button>
  </EmptyContainer>
);
const EmptyContainer = styled.div`
  display: flex;
  flex: 1 0 0;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  border-radius: 30px;
  border: 2px solid ${Palette.darkGrey};
  background: ${Palette["B&W/00"]};
  ${mobileMedia} {
    padding: 20px;
    gap: 30px;
  }
`;
