import styled from "@emotion/styled";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";

type ButtonProps = MuiButtonProps & { loading?: boolean };

export const Button = ({
  children,
  variant = "first",
  loading = false,
  ...rest
}: ButtonProps) => {
  return (
    <StyledButton {...rest} variant={variant}>
      {loading ? <LoadingContent /> : children}
    </StyledButton>
  );
};

const StyledButton = styled(MuiButton)`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "initial")};
`;

const LoadingContent = styled.div`
  flex: 1 0 0;
  height: 24px;
  border-radius: 17px;
`;
