import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ButtonIcon } from "~components";
import { DateService } from "~services/Date";
import { Palette } from "~utils/Palette";
import { ReactComponent as MoreInfoIcon } from "~assets/svg/more_info.svg";
import { mobileMedia, tabletMedia } from "~constants";

type EventElementCardProps = {
  title?: string;
  date: Date;
  onClick: () => void;
  info?: string;
};
export const EventElementCard = ({
  date,
  onClick,
  title = "",
  info,
}: EventElementCardProps) => (
  <Container onClick={onClick}>
    <Content>
      <Title variant="h4">
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Title>
      <Date variant="label1">
        <span>{DateService.toFormattedString(date, "dd MMM").slice(0, 6)}</span>
        <span>{DateService.toFormattedString(date, "HH:mm")}</span>
      </Date>
      {info && <Info>{info}</Info>}
    </Content>
    <StyledButtonIcon Icon={MoreInfoIcon} />
  </Container>
);

const StyledButtonIcon = styled(ButtonIcon)`
  align-self: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 0px 15px 10px;
`;

const Date = styled(Typography)`
  margin-top: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Title = styled(Typography)`
  margin-bottom: 20px;
  ${mobileMedia} {
    margin-bottom: 16px;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  min-width: calc(32% - 20px);
  align-items: start;
  justify-content: space-between;
  background-color: ${Palette["B&W/10"]};
  border-radius: 15px;
  padding: 17px 0px 15px 10px;
  cursor: pointer;
  ${tabletMedia} {
    min-width: calc(50% - 20px);
  }
  ${mobileMedia} {
    max-width: 100%;
    min-width: 100%;
    justify-content: space-between;
    padding: 15px 0px 14px 8px;
  }
`;

const Info = styled.div`
  margin-top: 15px;
  border-radius: 10px;
  padding: 10px;
  background: ${Palette["B&W/05"]};
  color: ${Palette["B&W/50"]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;
