import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Paper, Typography } from "@mui/material";
import { forwardRef } from "react";

import { ReactComponent as AgreeIcon } from "~assets/svg/agree.svg";
import { mobileMedia } from "~constants";
import { Palette } from "~utils/Palette";

type PaperDropdownProps = {
  options: Option[];
  value: Option["value"] | Option["value"][];
  onValueChange: (value: Option["value"]) => void;
  customStyles: SerializedStyles;
};

export const PaperDropdown = forwardRef<HTMLDivElement, PaperDropdownProps>(
  ({ value: currentValue, onValueChange, options, customStyles }, ref) => {
    return (
      <PaperContainer
        elevation={0}
        tabIndex={-1}
        ref={ref}
        customStyles={customStyles}
        data-paper-container
      >
        <Menu>
          {options.map(({ value, label }) => {
            const selected = Array.isArray(currentValue)
              ? currentValue.includes(value)
              : currentValue === value;
            return (
              <MenuItem onClick={() => onValueChange(value)} key={value}>
                <Text variant="label2">{label}</Text>
                {selected && <AgreeIconStyled width={24} height={24} />}
              </MenuItem>
            );
          })}
        </Menu>
      </PaperContainer>
    );
  }
);

const PaperContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "customStyles",
})<{ customStyles: SerializedStyles }>`
  width: 89px;
  min-width: 89px;

  position: absolute;
  background-color: ${Palette["B&W/10"]};
  border-radius: 15px;
  outline: 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background: ${Palette["B&W/30"]};
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 10px;
  }

  ${(props) => props.customStyles};
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  flex: 1 0 0;

  list-style-type: none;
  padding: 15px 0 15px;
  margin: 0;
  max-height: 300px;

  ${mobileMedia} {
    padding: 13px 6px 13px 4px;
  }
`;

const MenuItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 7px;
  box-sizing: border-box;
  cursor: pointer;
`;

const Text = styled(Typography)`
  padding: 3.5px 0 3.5px 12px;
  ${mobileMedia} {
    padding-left: 0px;
  }
  cursor: pointer;
`;

const AgreeIconStyled = styled(AgreeIcon)`
  margin-left: auto;
  padding-right: 11px;
`;
