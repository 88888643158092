/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Объект запроса на восстановление пароля
 * @export
 * @interface ForgetPassRequest
 */
export interface ForgetPassRequest {
    /**
     * Адрес электронной почты для запроса восстановления пароля
     * @type {string}
     * @memberof ForgetPassRequest
     */
    USER_EMAIL?: string;
    /**
     * Ответ по captha
     * @type {string}
     * @memberof ForgetPassRequest
     */
    captcha_word?: string;
    /**
     * Идентификатор captha
     * @type {string}
     * @memberof ForgetPassRequest
     */
    captcha_sid?: string;
}

/**
 * Check if a given object implements the ForgetPassRequest interface.
 */
export function instanceOfForgetPassRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ForgetPassRequestFromJSON(json: any): ForgetPassRequest {
    return ForgetPassRequestFromJSONTyped(json, false);
}

export function ForgetPassRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForgetPassRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'USER_EMAIL': !exists(json, 'USER_EMAIL') ? undefined : json['USER_EMAIL'],
        'captcha_word': !exists(json, 'captcha_word') ? undefined : json['captcha_word'],
        'captcha_sid': !exists(json, 'captcha_sid') ? undefined : json['captcha_sid'],
    };
}

export function ForgetPassRequestToJSON(value?: ForgetPassRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'USER_EMAIL': value.USER_EMAIL,
        'captcha_word': value.captcha_word,
        'captcha_sid': value.captcha_sid,
    };
}

