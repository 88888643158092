export const openTildaModal = (popupAnchor?: string) => {
  if (!popupAnchor) return;
  const modalLink = document.querySelector<HTMLAnchorElement>(
    `[href='${popupAnchor}']`
  );
  if (modalLink) {
    modalLink.click?.();
  } else {
    const link = document.createElement("a");
    link.href = popupAnchor;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

export const closeTildaModal = () => {
  document
    .querySelector<HTMLAnchorElement>(`.t-popup__close.t-popup__block-close`)
    ?.click?.();
};
