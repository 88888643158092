import { Middleware } from "prosto-api-client";

// https://stackoverflow.com/questions/47692658/chrome-fails-to-download-response-body-if-http-status-is-an-error-code
export const fixChromeResponseIssue: Middleware = {
  post: async (context) => {
    const responseClone = context.response.clone();
    let serverResponse = {};
    try {
      serverResponse = await responseClone.json();
    } catch (e) {
      throw { statusCode: context.response.status };
    }

    // @ts-ignore
    if (serverResponse.TYPE === "ERROR") {
      throw { ...serverResponse, statusCode: context.response.status };
    }

    if (context.response.status >= 200 && context.response.status < 300) {
      return context.response;
    }

    throw { ...serverResponse, statusCode: context.response.status };
  },
};
export const addTrailingSlash: Middleware = {
  pre: async (ctx) => {
    return { ...ctx, url: ctx.url + "/" };
  },
};
