/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Объект для восстановления пароля.
 * @export
 * @interface RequestChangePassword
 */
export interface RequestChangePassword {
    /**
     * Проверочное слово из письма (приходит в query string в параметре USER_CHECKWORD)
     * @type {string}
     * @memberof RequestChangePassword
     */
    USER_CHECKWORD?: string;
    /**
     * Логин пользователя из письма (приходит в query string в параметре USER_LOGIN)
     * @type {string}
     * @memberof RequestChangePassword
     */
    USER_LOGIN?: string;
    /**
     * Новый пароль пользователя
     * @type {string}
     * @memberof RequestChangePassword
     */
    USER_PASSWORD?: string;
    /**
     * Подтверждение нового пароля пользователя
     * @type {string}
     * @memberof RequestChangePassword
     */
    USER_CONFIRM_PASSWORD?: string;
    /**
     * Используется при запросе установки пароля. Передаваемое значение должно быть CHANGE_PWD
     * @type {string}
     * @memberof RequestChangePassword
     */
    TYPE?: string;
    /**
     * Ответ по captha
     * @type {string}
     * @memberof RequestChangePassword
     */
    captcha_word?: string;
    /**
     * Идентификатор captha
     * @type {string}
     * @memberof RequestChangePassword
     */
    captcha_sid?: string;
    /**
     * Сообщение от API системы (при наличии)
     * @type {string}
     * @memberof RequestChangePassword
     */
    MESSAGE?: string;
    /**
     * Тип ошибки
     * @type {string}
     * @memberof RequestChangePassword
     */
    ERROR_TYPE?: string;
    /**
     * Список ошибок
     * @type {Array<string>}
     * @memberof RequestChangePassword
     */
    ERRORS?: Array<string>;
}

/**
 * Check if a given object implements the RequestChangePassword interface.
 */
export function instanceOfRequestChangePassword(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RequestChangePasswordFromJSON(json: any): RequestChangePassword {
    return RequestChangePasswordFromJSONTyped(json, false);
}

export function RequestChangePasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestChangePassword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'USER_CHECKWORD': !exists(json, 'USER_CHECKWORD') ? undefined : json['USER_CHECKWORD'],
        'USER_LOGIN': !exists(json, 'USER_LOGIN') ? undefined : json['USER_LOGIN'],
        'USER_PASSWORD': !exists(json, 'USER_PASSWORD') ? undefined : json['USER_PASSWORD'],
        'USER_CONFIRM_PASSWORD': !exists(json, 'USER_CONFIRM_PASSWORD') ? undefined : json['USER_CONFIRM_PASSWORD'],
        'TYPE': !exists(json, 'TYPE') ? undefined : json['TYPE'],
        'captcha_word': !exists(json, 'captcha_word') ? undefined : json['captcha_word'],
        'captcha_sid': !exists(json, 'captcha_sid') ? undefined : json['captcha_sid'],
        'MESSAGE': !exists(json, 'MESSAGE') ? undefined : json['MESSAGE'],
        'ERROR_TYPE': !exists(json, 'ERROR_TYPE') ? undefined : json['ERROR_TYPE'],
        'ERRORS': !exists(json, 'ERRORS') ? undefined : json['ERRORS'],
    };
}

export function RequestChangePasswordToJSON(value?: RequestChangePassword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'USER_CHECKWORD': value.USER_CHECKWORD,
        'USER_LOGIN': value.USER_LOGIN,
        'USER_PASSWORD': value.USER_PASSWORD,
        'USER_CONFIRM_PASSWORD': value.USER_CONFIRM_PASSWORD,
        'TYPE': value.TYPE,
        'captcha_word': value.captcha_word,
        'captcha_sid': value.captcha_sid,
        'MESSAGE': value.MESSAGE,
        'ERROR_TYPE': value.ERROR_TYPE,
        'ERRORS': value.ERRORS,
    };
}

