import { ForgetPassRequest, RequestChangePassword } from "prosto-api-client";
import { useMutation, useQuery } from "react-query";
import { recoveryPasswordApi } from "~services/api/prosto-api";

const GET_FORGET_CAPTCHA = "GET_FORGET_CAPTCHA";
const getForgetCaptcha = () => recoveryPasswordApi.authForgetGet();

const GET_CHANGE_CAPTCHA = "GET_CHANGE_CAPTCHA";
const getChangeCaptcha = () => recoveryPasswordApi.authChangepasswdGet();

const forget = (forgetPassRequest: ForgetPassRequest) =>
  recoveryPasswordApi.authForgetPost({ forgetPassRequest });
const change = (requestChangePassword: RequestChangePassword) =>
  recoveryPasswordApi.authChangepasswdPost({ requestChangePassword });

export const useForgetPassword = () => {
  const { data: settings } = useQuery(GET_FORGET_CAPTCHA, getForgetCaptcha);
  const { mutateAsync: forgetPassword } = useMutation(forget);
  return { settings, forgetPassword };
};

export const useChangePassword = () => {
  const { data: settings } = useQuery(GET_CHANGE_CAPTCHA, getChangeCaptcha);
  const { mutateAsync: changePassword } = useMutation(change);

  return { settings, changePassword };
};
