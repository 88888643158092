/* tslint:disable */
/* eslint-disable */
/**
 * Swagger простоспб - OpenAPI 3.0
 * OpenAPI 3.0 спецификачия API сайта простоспб.рф для реализации внедрения в сайт prostospb.team на платформе tilda
 *
 * The version of the OpenAPI document: 1.0.41
 * Contact: imalex@yandex.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Настройки локеров (шкафчиков временного хранения) на конкретном адресе. Настройка персональна для адреса
 * @export
 * @interface ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS
 */
export interface ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS {
    /**
     * Указывает, существует ли данная опция на текущем адресе. Настройка персональна для адреса
     * @type {boolean}
     * @memberof ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS
     */
    EXISTS?: boolean;
    /**
     * Текст предложения локера (шкафчиков временного хранения)
     * @type {string}
     * @memberof ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS
     */
    PROPOSAL?: string;
    /**
     * Текст правила использования локера (шкафчиков временного хранения)
     * @type {string}
     * @memberof ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS
     */
    RIGHTS?: string;
}

/**
 * Check if a given object implements the ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS interface.
 */
export function instanceOfResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGSFromJSON(json: any): ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS {
    return ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGSFromJSONTyped(json, false);
}

export function ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGSFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'EXISTS': !exists(json, 'EXISTS') ? undefined : json['EXISTS'],
        'PROPOSAL': !exists(json, 'PROPOSAL') ? undefined : json['PROPOSAL'],
        'RIGHTS': !exists(json, 'RIGHTS') ? undefined : json['RIGHTS'],
    };
}

export function ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGSToJSON(value?: ResponseCoworkingCalendarDetailINFOPLACELOCKERSETTINGS | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'EXISTS': value.EXISTS,
        'PROPOSAL': value.PROPOSAL,
        'RIGHTS': value.RIGHTS,
    };
}

