import { UserInfoCreate } from "prosto-api-client";
import { useMutation, useQuery } from "react-query";
import { authApi } from "~services/api/prosto-api";

const GET_REGISTER_INFO = "GET_REGISTER_INFO";

const getRegisterInfo = () => authApi.authRegistrationGet();

const startRegister = (useInfo: UserInfoCreate) =>
  authApi.authRegistrationPost({
    userInfoCreate: useInfo,
  });

export const useRegistration = () => {
  const { isLoading: isLoadingCapctha, data } = useQuery(
    GET_REGISTER_INFO,
    getRegisterInfo
  );

  const { mutateAsync, isLoading, error } = useMutation(startRegister);

  return {
    isLoadingCapctha,
    onRegister: mutateAsync,
    data: {
      ...data,
      // @ts-ignore
      CAPTCHA_CODE: error?.CAPTCHA_CODE || data?.CAPTCHA_CODE,
    },
    isLoading,
  };
};
