import styled from "@emotion/styled";
import { Button as MuiButton, Typography } from "@mui/material";
import { PickersDayProps } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { ComponentType } from "react";

import { ReactComponent as Point } from "~assets/svg/point.svg";
import { DateService } from "~services/Date";
import { Palette } from "~utils/Palette";

export const DayCell: ComponentType<
  PickersDayProps<Date> & {
    appointmentDates?: Date[];
  }
> = ({
  day,
  outsideCurrentMonth,
  disabled = true,
  today = false,
  selected = false,
  onDaySelect,
  appointmentDates,
}) => {
  const hasAppointment = !!appointmentDates?.find((d) =>
    DateService.isEqualDates(day, d)
  );

  return (
    <Container>
      <Button
        today={today}
        active={selected}
        onClick={() => onDaySelect(day)}
        disabled={disabled}
      >
        <DayTypography
          variant="label3"
          active={selected}
          isOutOfMonth={outsideCurrentMonth}
          hasAppointment={hasAppointment}
          disabled={disabled}
        >
          {format(day, "dd")}
        </DayTypography>
        {hasAppointment && (
          <PointSvg
            fill={selected ? Palette["B&W/00"] : Palette["B&W/100"]}
            height={4}
            width={4}
          />
        )}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  box-sizing: border-box;
  padding: 2px 0;
`;

const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "today",
})<{ active: boolean; today: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 36px;
  width: 36px;
  min-width: auto;
  padding: 3px 0;

  border-width: 1px;
  border-style: solid;
  border-color: ${({ today }) => (today ? Palette["B&W/100"] : "transparent")};

  background-color: ${({ active }) =>
    active ? `${Palette["B&W/100"]} !important` : "transparent"};
`;

const DayTypography = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== "active" && prop !== "isOutOfMonth" && prop != "hasAppointment",
})<{
  active: boolean;
  isOutOfMonth: boolean;
  disabled: boolean;
  hasAppointment?: boolean;
}>`
  color: ${({ active, isOutOfMonth, disabled }) =>
    active
      ? Palette["B&W/00"]
      : disabled
      ? Palette["B&W/00"]
      : isOutOfMonth
      ? Palette["B&W/30"]
      : Palette["B&W/100"]};

  padding-top: ${({ hasAppointment }) => (hasAppointment ? "7px" : "0")};
  font-size: 13px;
  line-height: 1.15;
  font-weight: 400;
`;

const PointSvg = styled(Point)`
  padding-top: 1px;
`;
